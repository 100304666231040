import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Content() {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Donate Money</h3>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      <a
                        href="https://secure.actblue.com/donate/yeswecan-fund"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        I want to help
                      </a>{" "}
                      by donating directly.
                    </li>
                    <li>
                      <a
                        href="https://secure.actblue.com/donate/clackdemsmerch"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        I want to help
                      </a>{" "}
                      by buying merch!
                    </li>
                  </ul>
                </div>
                <div className="card-header">
                  <h3>Other Ways to Donate</h3>
                </div>
                <div className="card-body">
                  <p>
                    <Link to="/other-ways-to-donate">I want to support</Link>{" "}
                    the work of the Democrat Party of Clackamas County, but I'm
                    not sure how yet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Content;
