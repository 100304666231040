import image1 from "../assets/img/blog/articles/vote-box.jpg";
import image2 from "../assets/img/blog/articles/mount-hood.jpg";
import demImage from "../assets/img/blog/articles/ca-times.brightspotcdn.com.webp";
import townHall from "../assets/img/blog/articles/psb.jpg";
import voteByMail from "../assets/img/banner/mail-ballot.jpeg";
import candidate_conversations from "../assets/img/banner/candidate-conversations.jpeg";
import dems2b3 from "../assets/img/banner/Dems2B3.jpg";
import jan_lee from "../assets/img/blog/articles/Jan-Lee.jpg";
import { slateCards } from "../assets/img/blog/articles/slate-cards";
import DemBanner from "../assets/img/banner/hero-bg.png";
import BlackCaucus from "../assets/img/banner/Dems2B3.jpg";
import volunteering from "../assets/img/blog/articles/volunteering.png";
import upcomingEventsBanner from "../assets/img/blog/articles/upcoming-events-banner.png";
import squished_banner from "../assets/img/blog/articles/squished-banner.png";
import blue_in_22 from "../assets/img/blog/articles/Blue-in-22.png";
import ClackDems_SummerOnline2022 from "../assets/img/blog/articles/ClackDems_SummerOnline2022.jpg";
import peopleTogetherSmiling from "../assets/img/blog/articles/people-together-smiling.png";
import peopleTogetherSmiling2 from "../assets/img/blog/articles/people-together-smiling-2.png";
import CatherineMcMullenForClackamasCountyDems from "../assets/img/blog/articles/catherine-mcmullen-for-clackamas-county-clerk.jpg";
import { sponsorImagesReplacer } from "../assets/img/blog/articles/sponsors";
import { auctionImagesReplacer } from "../assets/img/blog/articles/auction-pieces";
import rally from "../assets/img/blog/articles/rally.jpg";
import voteBlue1 from "../assets/img/blog/articles/vote-blue-ad.jpg";
import crisWallerDragon from "../assets/img/blog/articles/cris-waller-with-stuffed-dragon.png";
import jillThorn from "../assets/img/blog/articles/jill-thorn.png";
import vidalJillThorn from "../assets/img/blog/articles/Vidal.jpg";
import fair2023 from "../assets/img/blog/articles/2023-fair-signup-image.jpg";
import dinner_announcement from "../assets/img/blog/articles/yes-we-can-dinner-announcement.png";

const getHtmlContent = async (name, replace = null) => {
  let content = await fetch(`assets/blog-posts/${name}.html`)
    .then((res) => res.text())
    .catch((err) => console.log(err));
  if (replace?.length) {
    replace.forEach((item) => {
      content = content.replace(item.find, item.replace);
    });
  }
  return content;
};

export const getArticle = async (endpoint) => {
  const art = articles.find((art) => art.endpoint === endpoint);
  const content = await getHtmlContent(endpoint, art.replace);
  art.fulltext = art.fulltext || content;
  return await Promise.resolve(art);
};

export const getArticles = async () => {
  const arts = articles.map(async (article) => {
    const content = await getHtmlContent(article.endpoint, article.replace);
    return {
      ...article,
      fulltext: article.fulltext || content,
    };
  });
  return await Promise.all(arts);
};

export const articles = [
  {
    img: squished_banner,
    title: "Words of Kindness and Solidarity from Our Chair",
    caption:
      "I wanted to reach out to all of you in the aftermath of the election.",
    endpoint: "2024-11-06-words-of-kindness-and-solidarity-from-our-chair",
    postdate: "2024-11-06",
  },
  {
    img: squished_banner,
    title:
      "The Democratic Party of Clackamas County Resolution 2024-10: Climate Change and Public Safety",
    caption:
      "Resolution drafted by James Farley, Tom Horman, and Darby Collins",
    endpoint: "2024-10-29-resolution-2024-10-climate-change-and-public-safety",
    postdate: "2024-10-29",
  },
  {
    img: squished_banner,
    title:
      "An URGENT Ask for Your Help to Stop Threats to Voting in Clackamas County",
    caption:
      "Act to make sure Clackamas County is not taken over by a MAGA element that threatens our very freedom to vote",
    endpoint: "2024-10-25-a-threat-to-our-elections",
    postdate: "2024-10-25",
  },
  {
    img: "/assets/img/blog/love-america.png",
    title: "Time to Take Back Our Flag, Faith and Patriotism",
    caption:
      "For too long, a small, vocal group of extremists have defined what it means to be a patriot",
    endpoint: "2024-10-15-taking-back-faith-and-patriotism",
    postdate: "2024-10-15",
  },
  {
    img: squished_banner,
    title: "A Message from the Democratic Party of Oregon’s Latino Caucus",
    caption:
      "For over 50 years, Hispanic Heritage Month has been celebrated in the United States from September 15th - October 15th.",
    endpoint:
      "2024-10-01-a-message-from-the-democratic-party-of-oregons-latino-caucus",
    postdate: "2024-10-01",
  },
  {
    img: squished_banner,
    title: "Our November 2024 Endorsements",
    caption:
      "The path to protecting democracy starts at home! Together, we can win our local elections.",
    endpoint: "2024-09-16-endorsements-for-november-2024",
    postdate: "2024-09-16",
  },
  {
    img: "/assets/img/blog/yes-we-can-sponsorship-levels.png",
    title:
      "Be a Clackamas Democrats Sponsor and/or Advertise at our Biggest Event",
    caption:
      "To become a sponsor or place an ad, please visit our auction website.",
    endpoint: "2024-09-09-be-a-clackamas-sponsor",
    postdate: "2024-09-09",
  },
  {
    img: squished_banner,
    title: "Letter from the chair",
    caption:
      "I have heard from many of you regarding your concerns about the upcoming Presidential election. Many of you have asked me to take action.",
    endpoint: "2024-09-09-a-letter-from-the-chair",
    postdate: "2024-09-09",
  },
  {
    img: "/assets/img/blog/2024_YesWeCanGraphics.jpg",
    title: "2024 Yes We Can! Dinner and Auction",
    caption:
      "You're invited to the Tualatin Country Club on October 19th, 2024 for the Yes We Can! Dinner & Auction.",
    endpoint: "2024-09-09-yes-we-can-dinner-and-auction",
    postdate: "2024-09-09",
  },
  {
    img: squished_banner,
    title: "2024 Primary Election: Looking Back",
    caption:
      "We are incredibly proud of all of the Democratic candidates who were on the ballot this primary season!",
    endpoint: "2024-06-14-primary-election-looking-back",
    postdate: "2024-06-14",
  },
  {
    img: squished_banner,
    title: "Your Opinion Matters: Send a Letter to the Editor",
    caption:
      "Writing Letters to the Editor may seem kind of old-school, but they are still widely read and remain an effective way to share your concerns",
    endpoint: "2024-04-10-your-opinion-matters-send-a-letter",
    postdate: "2024-04-10",
  },
  {
    img: "/assets/img/blog/2024-april-fools.png",
    title: "April Fools for Fundamental Rights Auction",
    caption:
      "Join us starting April 1st to celebrate being a fool for ONE thing: Fundamental Rights for ALL",
    endpoint: "2024-04-01-april-fools-for-fundamental-rights-auction",
    postdate: "2024-04-01",
  },
  {
    img: squished_banner,
    title: "Clackamas County Democrats Announce Endorsements",
    caption:
      "We are pleased to list below our endorsements for the May 2024 Primary Election, as approved by our Executive Committee and elected Precinct Committee Persons",
    endpoint:
      "2024-03-21-clackamas-county-democrats-announce-endorsements-for-may-2024-primary-election",
    postdate: "2024-03-21",
  },
  {
    img: squished_banner,
    title:
      "May 21, 2024 primary election list of candidates endorsed by the DPCC",
    caption:
      "We are pleased to list below our endorsements for the May 2024 Primary",
    endpoint: "2024-may-21-2024-primary-election-vote",
    postdate: "2024-03-21",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Resolution 2024-02: A Call for Peace",
    caption: "Resolution 2024-02: A Call for Peace Approved 2.15.24",
    endpoint: "2024-03-19-a-call-for-peace",
    postdate: "2024-03-19",
    fulltext: "",
  },
  {
    title: "2023 Clackamas Dems By the Numbers",
    caption: "2023 Clackamas Dems by the numbers",
    endpoint: "2024-03-18-2023-by-the-numbers",
    postdate: "2024-03-18",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Honoring Black History Month 2024",
    caption:
      "“The vote is sacred...The vote is precious. It is the most powerful non-violent tool we have in a democratic society and we must use it.”",
    endpoint: "2024-02-02-honoring-black-history-month",
    postdate: "February 2, 2024",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Governor Kotek Visits Clackamas County on One Oregon Listening Tour",
    caption: "Stops in Estacada, Oregon City, Gladstone, and Milwaukie",
    endpoint:
      "2024-01-03-governor-kotek-visits-clackamas-county-on-one-oregon-listening-tour",
    postdate: "January 3, 2024",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Dreamy Auction Items Await Your Bidding",
    caption: `Our November 4th annual dinner features our grand prize raffle
  drawing and lively auction`,
    endpoint: "2023-10-25-dreamy-auction-items-await-your-bidding",
    author: "",
    postdate: "October 25, 2023",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Thank you to Our Generous Sponsors",
    caption: `Clackamas County Democrats’ Yes We Can! Dinner & Auction is fast approaching, and we don’t want to
  wait another minute to announce with pride the local leaders who are this year’s sponsors`,
    endpoint: "2023-10-20-thank-you-to-our-generous-sponsors",
    author: "",
    postdate: "October 20, 2023",
    fulltext: "",
  },
  {
    img: dinner_announcement,
    title: "Yes We Can! Dinner and Auction",
    caption: "A special message from our chair.",
    endpoint: "2023-10-19-yes-we-can-dinner-and-auction",
    postdate: "October 19, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "A Message From Our Chair",
    caption: "In Response to the Second Hateful Attack on our Oregon City HQ.",
    endpoint: "2023-09-18-message-from-chair",
    postdate: "September 18, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: fair2023,
    title: "volunteer at the fair with dpcc 2023",
    caption:
      "please help the dpcc at the clackamas county fair from tuesday, august 15 through the 19th.",
    endpoint: "2023-07-28-volunteer-at-the-2023-fair",
    postdate: "august 28, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Monroe Sweetland Award",
    caption:
      "Under Sweetland’s leadership, the modern progressive Oregon Democratic Party emerged, with victories in the governorship, both houses of Congress and in the Oregon State Legislature.",
    endpoint: "2023-07-13-monroe-sweetland-award",
    postdate: "July 13, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Clackamas County Sheriff Angela Brandenburg Asks for Your Support",
    caption:
      "On May 19, 2023, Clackamas County Sheriff Angela Brandenburg sent a letter to county residents her concerns regarding the County’s recent cuts to the sheriff office’s budget, which she says is a “misuse of your tax dollars.”",
    endpoint: "2023-05-22-letter-from-sheriff-angela-brandenburg",
    postdate: "May 22, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "A Call to Action",
    caption:
      "Participate in Creating the 2023 Democratic Party of Oregon Platform",
    endpoint: "2023-05-02-a-call-to-action",
    postdate: "May 2, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: vidalJillThorn,
    title: "Continuing the Legacy: Maegan Vidal Wins 2023 Jill Thorn Grant",
    caption:
      "Members of the Jill Thorn Grant committee faced a happy dilemma this year: All four applicants for the grant, which Clackamas County Dems awards to a woman running for public office for the first time, were top-notch.",
    endpoint: "2023-04-28-maegan-vidal-wins-2023-jill-thorn-grant",
    postdate: "April 28, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Special District Election is May 16",
    caption: "Here Are the Candidates We’ve Endorsed",
    endpoint: "2023-04-24-special-district-election-is-may-16",
    postdate: "April 24, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Chavez-DeRemer appears ‘moderate’ but she’s anything but…",
    caption:
      "While GOP US representative Lori Chavez-DeRemer, who, sad to say, was elected last November, is trying to give District 5 Oregonians the impression that she is a moderate, reasonable, willing-to-compromise member of the Republican party, her actions tell a much different story.",
    endpoint: "2023-04-18-chavez-deremer-is-anything-but-moderate",
    postdate: "April 18, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "The Update: March 24th, 2023",
    caption: "General updates and information.",
    endpoint: "2023-03-24-the-update",
    postdate: "Mar 24, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "The Update: March 10th, 2023",
    caption: "General updates and information.",
    endpoint: "2023-03-10-the-update",
    postdate: "Mar 10, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Unpaid Debts",
    caption:
      "The foundational wealth of our country came disproportionately from the lashed backs of black slaves.",
    endpoint: "2023-03-09-unpaid-debts",
    postdate: "Mar 9, 2023",
    author: "William Street",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "The Update: February 24th, 2023",
    caption: "General updates and information.",
    endpoint: "2023-02-24-the-update",
    postdate: "Feb 24, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "At Last: A Breath of Fresh Air in Clackamas County Clerk's Office",
    caption:
      "Newly elected Clackamas County Clerk Catherine McMullen spoke to Clackamas County Democrats at our February 16th monthly meeting, just six weeks after she took office.",
    endpoint: "2022-02-27-at-last-a-breath-of-fresh-air",
    postdate: "Feb 27, 2023",
    author: "",
    fulltext: "",
  },
  {
    title: "2022 Clackamas Dems by the Numbers",
    caption: "2022 Clackamas Dems by the Numbers",
    endpoint: "2022-clackamas-dems-by-the-numbers",
    postdate: "Feb 27, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Black History Month's True Mission",
    caption:
      "Black History Month is about recognition, appreciation and observation of the contributions and accomplishments of African Americans to our society.",
    endpoint: "2023-black-history-months-true-mission",
    postdate: "Feb 22, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: jillThorn,
    title: "Jill Thorn Grant",
    caption:
      "The Jill Thorn Grant is awarded by the Democratic Party of Clackamas County (DPCC) to a woman in Clackamas County who is a Democrat preparing to run for public office for the first time.",
    endpoint: "jill-thorn-grant",
    postdate: "Feb 22, 2022",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "The Update: January 27th, 2023",
    caption: "General updates and information.",
    endpoint: "2023-01-27-the-update",
    postdate: "Jan 27, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: crisWallerDragon,
    title: "Cris Waller, New DPCC Chair…Who Is She Really?",
    caption:
      "I'm a native Oregonian, although my family moved away when I was small, and I grew up in Warren, Michigan, and the East County area of San Diego.",
    endpoint: "cris-waller-who-is-she-really",
    postdate: "Jan 17, 2023",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "GENERAL ELECTION RECAP Dec. 7, 2022",
    caption: `Oregon will have a new Democratic governor next year but Clackamas County
    didn’t vote for her.`,
    endpoint: "2022-12-07-general-election-recap",
    postdate: "Dec 7, 2022",
    author: "Peter Toll",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "No Apologies, No Regrets",
    caption: `The election is over.  Monday morning quarterbacks have already started to engage in “what if’ism.”`,
    author: "William V Street Jr",
    postdate: "November 16, 2022",
    endpoint: "no-apologies-no-regrets-2022",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Why We Can’t Trust Phil Knight and His Gang About Complex Political and Economic Issues",
    caption: `One of the most overwhelming problems with our electoral system is the need to
    stoop to the lowest common denominator: oversimplifying complicated matters to
    the point of irresponsibility in order to fit a 30-second sound bite.`,
    postdate: "November 3, 2022",
    endpoint: "why-we-cant-trust-phil-knight-2022",
    author: "Bill Street",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "SOMETHING ROTTEN IN THE STATE OF ELECTIONS: Make it a priority to END CITIZENS UNITED",
    caption: "A Red State future for Oregon? No. Not if we can help it!",
    endpoint: "something-rotten-in-the-state-of-elections-2022",
    postdate: "Oct 27, 2022",
    author: "Hester Schell",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Democratic Party of Clackamas County Resolution on Reproductive Healthcare Rights Press Conference",
    caption:
      "On Friday, October 14, ten elected Democratic officials, candidates for office and representatives of pro-choice, nonprofit organizations spoke in support of a resolution adopted by the Democratic Party of Clackamas County",
    endpoint: "resolution-on-reproductive-health-care-rights-2022",
    postdate: "Oct 27, 2022",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Pick a Roosevelt 2022",
    caption:
      "In the words of James Carville, “It’s the economy, stupid!” Twenty years later it still is.",
    endpoint: "pick-a-roosevelt-2022",
    postdate: "Oct 27, 2022",
    author: "Bill Street",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "Count Down On Mail-in Ballots",
    caption:
      "URGENT ACTION: ATTENTION VOTERS - You may have found one of these handy cards on your door. A Neighborhood Leader volunteer stopped by.",
    endpoint: "count-down-on-mail-in-ballots-2022",
    postdate: "Oct 21, 2022",
    author: "",
    fulltext: "",
  },
  {
    img: voteBlue1,
    title: "Vote Blue All the Way Through",
    caption: "Vote Blue All the Way Through",
    endpoint: "vote-blue-all-the-way-through-2022",
    postdate: "Oct 20, 2022",
    author: "",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Clackamas County Democrats’ Endorsements for 2022 Midterm Elections",
    caption:
      "We are pleased to announce our endorsements for the upcoming, all-important, midterm elections.",
    endpoint: "clackamas-county-democrats-endorsements-2022-midterms",
    postdate: "Oct 17, 2022",
    author: "",
    fulltext: "",
  },
  {
    img: blue_in_22,
    title: "ATTENTION  -  Bloggers & Volunteers",
    caption:
      "Here’s something to spiff up your blog post, wherever your platform.",
    endpoint: "spiff-up-your-posts-vote",
    author: "Clackamas Democrats",
    postdate: "September 26, 2022",
    fulltext: "",
    replace: [
      {
        find: "{{rally}}",
        replace: `
        <img src="${rally}" alt="rally" class="img-fluid" />
        `,
      },
    ],
  },
  {
    img: blue_in_22,
    title: "Getting down to it on “Independent” Betsy Johnson",
    caption: `Time to decode Betsy.  Or as she’d say, “Cutting through the crap.”`,
    endpoint: "betsy-on-speed-dial",
    author: "",
    postdate: "September 22, 2022",
    fulltext: "",
  },
  {
    img: blue_in_22,
    title:
      "It’s Official: Senator Ron Wyden to give Keynote at Clackamas County Democrats’ fundraiser- LIVELY VIRTUAL EVENT, Saturday, October 8, 2022",
    caption: `We’re excited!  Senator Wyden has agreed to be our keynote speaker for this important night of campaign fundraising here in Clackamas County!
    `,
    endpoint: "ron-wyden-keynote",
    author: "",
    postdate: "September 22, 2022",
    fulltext: "",
  },
  {
    img: blue_in_22,
    title:
      "The Hunger for Campaign Financing: CLACKAMAS DEMS FUNDRAISER READY TO ROCK",
    caption: `Campaigns are expensive, really expensive, and we’ve got the MAGA war chest on
    our doorstep.`,
    endpoint: "the-hunger-for-campaign-financing",
    author: "",
    postdate: "September 22, 2022",
    fulltext: "",
    replace: auctionImagesReplacer,
  },
  {
    img: blue_in_22,
    title: "Meet Our Generous Sponsors",
    caption: `The Clackamas Together - Blue in '22 Lively Virtual Event, Auction & Raffle is
    full speed ahead, gathering sponsors for this important night"`,
    endpoint: "meet-our-generous-sponsors-08-26-2022",
    author: "",
    postdate: "August 26 2022",
    fulltext: "",
    replace: sponsorImagesReplacer,
  },
  {
    img: blue_in_22,
    title: "Generosity: When it's time to step it up",
    caption: "",
    endpoint: "generosity-when-its-time-to-step-it-up",
    postdate: "August 23 2022",
    author: "Hester Schell",
    fulltext: "",
    replace: [
      {
        find: "footer-hook",
        replace: `
    <br />
    <div>
      <img src=${blue_in_22} />
    </div>
  `,
      },
    ],
  },
  {
    img: CatherineMcMullenForClackamasCountyDems,
    title: "Your Vote Matters - Local Participation Really Matters",
    caption: "More errors at the election office",
    postdate: "August 21 2022",
    endpoint: "your-vote-matters-local-participation-really-matters",
    author: "Hester Schell",
    fulltext: "",
    replace: [
      {
        find: "footer-hook",
        replace: `
      <br />
      <div>
        <img src=${blue_in_22} />
      </div>
    `,
      },
    ],
  },
  {
    img: peopleTogetherSmiling,
    title: "Clackamas Dems Make Strong Showing in Gladstone Community Parade",
    caption: `U.S. House of Representatives candidate Jamie McLeod-Skinner, who’s
    running to represent Congressional District 5, excited locals with her
    appearance Saturday`,
    endpoint:
      "clackamas-dems-make-strong-showing-in-gladstone-community-parade-08-08-2022",
    postdate: "August 8, 2022",
    author: "Hester Schell",
    fulltext: `
      <p>
        <span
          >U.S. House of Representatives candidate Jamie McLeod-Skinner, who’s
          running to represent Congressional District 5, excited locals with her
          appearance Saturday, August 6, 2022, at the Gladstone Community Parade.
          Also walking the route, or riding in lively, decorated convertibles, were
          Rep. Mark Meek, who is running for Oregon State Senate to represent the
          20th district, county clerk candidate Catherine McMullen and Annessa
          Hartman, who’s running for House District 40.</span
        >
      </p>
      <br/>
      <p>
        <span
          >Smiling teens pledged to talk to their adults about voting.
          Toddlers collected tossed candy and American flags. One very friendly
          neighbor joyously cheered, “Oh, that’s the gal running against Sherry
          Hall.” It was a beautiful day, and everyone was happy to be out.</span
        >
      </p>
      <br/>
      <p><span>Where to start:</span></p>
      <p>
        <span
          >Reach out and connect with non-voting family members, colleagues and
          neighbors.</span
        >
      </p>
      <p>
        <span
          >Reach out to our new crop of high school graduates who need to register
          to vote. Help them.</span
        >
      </p>
      <p>
        <span
          >Dare we try to sway our Republican neighbors? Yes. Speak truth to power.
          Because… somehow - more of us MUST get to the polls on Election Day.
          Clackamas County talking point resources are <a
            href="/#/blog-posts/what-democrats-stand-for"
          >
          here</a>. </span
        >
      </p>
      <br />
      <p>
        <span
          style="
            overflow: hidden;
            display: inline-block;
            margin: 0px 0px;
            border: 0px solid #000000;
            transform: rotate(0rad) translateZ(0px);
            -webkit-transform: rotate(0rad) translateZ(0px);
            width: 261.38px;
            height: 160.97px;
          "
          ><img
            alt="Candidate Quartet Aug 6 2022 SCHELL.jpg"
            src=${peopleTogetherSmiling}
            style="
              width: 261.38px;
              height: 160.97px;
              margin-left: 0px;
              margin-top: 0px;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
            "
            title="" /></span
        >
      </p>
      <p>
        <span
          style="
            overflow: hidden;
            display: inline-block;
            margin: 0px 0px;
            border: 0px solid #000000;
            transform: rotate(0rad) translateZ(0px);
            -webkit-transform: rotate(0rad) translateZ(0px);
            width: 237.33px;
            height: 237.33px;
          "
          ><img
            alt="parade day McMullen and Meek Aug  6 2022 SCHELL.jpg"
            src=${peopleTogetherSmiling2}
            style="
              width: 237.33px;
              height: 237.33px;
              margin-left: 0px;
              margin-top: 0px;
              transform: rotate(0rad) translateZ(0px);
              -webkit-transform: rotate(0rad) translateZ(0px);
            "
            title=""
        /></span>
      </p>
      <p>
        <span><br />**Photo Credit: hesterschellcreative</span>
      </p>
    `,
  },
  {
    img: ClackDems_SummerOnline2022,
    title: "What Democrats Stand For",
    caption:
      "Clackamas Democrats are the party of, by and for people who work for a living.",
    postdate: "August 22, 2022",
    author: "",
    endpoint: "what-democrats-stand-for",
    fulltext: "",
  },
  {
    img: "https://images.pexels.com/photos/5108648/pexels-photo-5108648.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "The Hall Monitor (because election integrity depends on it) 2",
    caption:
      "The November election is less than three months away. So now is a good time to think about why you are a Democrat.",
    endpoint: "the-hall-monitor-2",
    author: "",
    postdate: "August 16, 2022",
    fulltext: "",
  },
  {
    img: "https://www.oregonlive.com/resizer/l-QTsaOzfEs_v8yQyYF92VRqpsk=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/7O7HDDQL3FAKPOLHEMVDN3XJ6Q.jpg",
    externalLink:
      "https://www.oregonlive.com/opinion/2022/08/readers-respond-racism-not-rural-oregon-is-issue.html",
    title: "Readers Respond: Racism, not rural Oregon, is issue",
    postdate: "August 7, 2022",
  },
  {
    img: squished_banner,
    title: "On the November Ballot: Democrats We Support",
    caption:
      "Election Day 2022 is Tuesday, November 8 -- so the time to get involved in this crucial election is right now.",
    author: "",
    endpoint: "2022-07-31-on-the-november-ballot-democrats-we-support",
    postdate: "July 31 2022",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Action On The Climate Crisis: Clackamas County Democrats' Central Committee Approves Proclamation, Echoing President Biden",
    caption:
      "On July 20, 2022 President Biden announced he would use Executive Orders to allocate $2.3 billion for action on the climate crisis. On the heels of this historical declaration, Clackamas County Democratic leadership went to work locally, drafting a proclamation to address climate goals.",
    author: "",
    endpoint: "2022-07-25-action-on-the-climate-crisis",
    postdate: "July 25 2022",
    fulltext: "",
  },
  {
    img: blue_in_22,
    title: "Once-a-Year Fundraiser Happening This Fall!",
    caption:
      "Blue in ‘22 will kick-off on September 24th with an online raffle. You could win the grand prize, three days, two nights at a three-bedroom  Manzanita Beach home!",
    author: "",
    endpoint: "2022-07-20-once-a-year-fundraiser-happening-this-fall",
    postdate: "July 20 2022",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "On the November Ballot: Democrats We Support",
    caption:
      "Election Day 2022 is Tuesday, November 8 -- so the time to get involved in this crucial election is right now.",
    author: "",
    endpoint: "2022-07-31-on-the-november-ballot-democrats-we-support",
    postdate: "July 31 2022",
    urltext: "Read More",
    fulltext: "",
  },
  {
    img: squished_banner,
    title:
      "Action On The Climate Crisis: Clackamas County Democrats' Central Committee Approves Proclamation, Echoing President Biden",
    caption:
      "On July 20, 2022 President Biden announced he would use Executive Orders to allocate $2.3 billion for action on the climate crisis. On the heels of this historical declaration, Clackamas County Democratic leadership went to work locally, drafting a proclamation to address climate goals.",
    author: "",
    endpoint: "2022-07-25-action-on-the-climate-crisis",
    postdate: "July 25 2022",
    urltext: "Read More",
    fulltext: "",
  },
  {
    img: blue_in_22,
    title: "Once-a-Year Fundraiser Happening This Fall!",
    caption:
      "Blue in ‘22 will kick-off on September 24th with an online raffle. You could win the grand prize, three days, two nights at a three-bedroom  Manzanita Beach home!",
    author: "",
    endpoint: "2022-07-20-once-a-year-fundraiser-happening-this-fall",
    postdate: "July 20 2022",
    urltext: "Read More",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "July 12th Newsletter",
    caption:
      "The election will be around the corner soon. Now is a great time to get familiar with the Democratic candidates so you can make informed decisions and get out the vote.",
    author: "",
    endpoint: "2022-07-12-newsletter",
    postdate: "July 12 2022",
    fulltext: "",
  },
  {
    img: squished_banner,
    title: "June 30th Newsletter",
    caption:
      "In a post-Roe world, it's more important than ever to support local Democrats. Find out how to get more involved in the party by reading on!",
    author: "",
    endpoint: "2022-06-30-newsletter",
    postdate: "June 30 2022",
    fulltext: "",
  },
  {
    img: upcomingEventsBanner,
    title: "June 14th Newsletter",
    caption:
      "The primary election is over, but November will be here sooner than you think. We need to keep up our efforts to make sure we elect Democrats up and down the ballot.",
    author: "",
    endpoint: "2022-06-14-newsletter",
    postdate: "June 14 2022",
    fulltext: "",
  },
  {
    img: volunteering,
    title: "Yes! There’s Something You Can Do",
    caption:
      "While Clackamas County Democrats have always depended on our volunteers, this past May’s election drove home just how important our dedicated crew is",
    postdate: "May 31, 2022",
    author: "",
    endpoint: "yes-there-is-something-you-can-do",
    fulltext: "",
  },
  {
    img: "https://images.pexels.com/photos/5108648/pexels-photo-5108648.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    title: "The Hall Monitor (because some one has to)",
    caption:
      "It’s two days  after the election and Clackamas County voters have no idea who won anything.",
    postdate: "May 17, 2022",
    author: "",
    endpoint: "the-hall-monitor",
    fulltext: "",
  },
  {
    img: "https://www.oregonlive.com/resizer/FfvwPalQm9V7l4w9nLetI2Mxvn4=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/IQH7QMNQYZE7LD6LL24SRAENQI.jpg",
    title:
      "Tight Race for Congressional District 5 Representative Gets Headlines",
    caption:
      "Across the nation, eyes are on the Congressional race in Oregon’s District 5",
    postdate: "May 16, 2022",
    author: "",
    endpoint:
      "tight-race-for-congressional-district-5-representative-gets-headlines",
    fulltext: "",
  },
  {
    img: BlackCaucus,
    title: "Black Caucus Announces Endorsements for Upcoming Election",
    caption:
      "The May 17th election is fast approaching! After careful review and consideration, members of the Clackamas County Democrats Black Caucus have made their endorsements.",
    postdate: "May 6, 2022",
    author: "",
    endpoint: "black-caucus-endorsements-for-upcoming-election",
    fulltext: "",
  },
  {
    img: "https://theintercept.imgix.net/wp-uploads/sites/1/2022/03/AP20136001820772-tone-schrader-oregon.jpg?auto=compress%2Cformat&q=90&fit=crop&w=3000&h=1500",
    title:
      "Clackamas County Democrats Make National News In Persuasive Report about our McLeod-Skinner Endorsement",
    caption:
      "The fact that Democratic leaders in Oregon have endorsed Jamie McLeod-Skinner over incumbent Kurt Schrader has gained national attention.",
    postdate: "May 15, 2022",
    author: "",
    endpoint:
      "clackamas-democrats-make-national-news-in-persuasive-report-about-our-mcleod-skinner-endorsement",
    fulltext: "",
  },
  {
    img: DemBanner,
    title: "Support our Candidates in the May 17th Election",
    caption:
      "Voters, please vote for all the races in this election. We need strong Democrats elected to represent us.",
    postdate: "April 27, 2022",
    author: "",
    endpoint: "support-our-candidates-in-the-may-17th-election",
    fulltext: `

    <hr>
    <h4>House District 18</p>
    <hr>
    <div style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);display:flex;justify-content:space-between;">
      <a title="Download House District 18 PDF" href=${slateCards.HD18.pdf} download>
        <img style="" src="${slateCards.HD18.v1}" alt="House District 18 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 26</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 26 PDF" href=${slateCards.HD26.pdf} download style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);display:inline-block;">
        <img style="" src="${slateCards.HD26.v1}" alt="House District 26 Slate Card" />
      </a>
      <a title="Download House District 26 PDF" href=${slateCards.HD26.pdf} download style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);display:inline-block;">
        <img style="" src="${slateCards.HD26.v2}" alt="House District 26 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 37</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 37 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD37.pdf} download>
        <img style="" src="${slateCards.HD37.v1}" alt="House District 37 Slate Card" />
      </a>
      <a title="Download House District 37 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD37.pdf} download>
        <img style="" src="${slateCards.HD37.v2}" alt="House District 37 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 38</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 38 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD38.pdf} download>
        <img style="" src="${slateCards.HD38.v1}" alt="House District 38 Slate Card" />
      </a>
      <a title="Download House District 38 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD38.pdf} download>
        <img style="" src="${slateCards.HD38.v2}" alt="House District 38 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 39</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 39 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD39.pdf} download>
        <img style="" src="${slateCards.HD39.v1}" alt="House District 39 Slate Card" />
      </a>
      <a title="Download House District 39 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD39.pdf} download>
        <img style="" src="${slateCards.HD39.v2}" alt="House District 39 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 40</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 40 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD40.pdf} download>
        <img style="" src="${slateCards.HD40.v1}" alt="House District 40 Slate Card" />
      </a>
      <a title="Download House District 40 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD40.pdf} download>
        <img style="" src="${slateCards.HD40.v2}" alt="House District 40 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 41</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 41 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD41.pdf} download>
        <img style="" src="${slateCards.HD41.v1}" alt="House District 41 Slate Card" />
      </a>
      <a title="Download House District 41 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD41.pdf} download>
        <img style="" src="${slateCards.HD41.v2}" alt="House District 41 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 48</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 48 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD48.pdf} download>
        <img style="" src="${slateCards.HD48.v1}" alt="House District 48 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 51</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 51 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD51.pdf} download>
        <img style="" src="${slateCards.HD51.v1}" alt="House District 51 Slate Card" />
      </a>
      <a title="Download House District 51 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD51.pdf} download>
        <img style="" src="${slateCards.HD51.v2}" alt="House District 51 Slate Card" />
      </a>
    </div>
    <br /><br />
    <hr>
    <h4>House District 52</p>
    <hr>
    <div style="display:flex;justify-content:space-between;">
      <a title="Download House District 52 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD52.pdf} download>
        <img style="" src="${slateCards.HD52.v1}" alt="House District 52 Slate Card" />
      </a>
      <a title="Download House District 52 PDF" style="width:48%;box-shadow:.2px .2px 5px 0 rgba(0,0,0,.25);" href=${slateCards.HD52.pdf} download>
        <img style="" src="${slateCards.HD52.v2}" alt="House District 52 Slate Card" />
      </a>
    </div>

    `,
  },
  {
    img: jan_lee,
    title: "How Does Our Amazing Chair Find the Time?",
    caption:
      "Jan Lee’s list of accomplishments is as long as it is impressive.",
    endpoint: "how-does-our-amazing-chair-find-the-time",
    author: "",
    postdate: "4 April 2022",
    fulltext: "",
  },
  {
    img: voteByMail,
    title: "Oregon Republicans Take Aim at Vote-By-Mail",
    caption:
      "At a recent forum of all the Republican candidates vying to be Oregon’s next Governor, none of them defended vote-by-mail",
    externalLink: "",
    endpoint: "oregon-republicans-take-aim-at-vote-by-mail",
    author: "Bill Street",
    postdate: "24 March 2022",
    tags: [{ title: "Election" }],
    fulltext: "",
  },
  {
    img: townHall,
    title: "Media Release: April 6 Town Hall",
    caption: "Where are we headed as a country, and why?",
    externalLink: "",
    endpoint: "april-6-town-hall",
    author: "",
    postdate: "15 Mar 2022",
    tags: [{ title: "Election" }],
    fulltext: "",
  },
  {
    img: demImage,
    title:
      "Candidates for Clackamas County Board of Commissioners On the Record",
    caption:
      "The candidates include two endorsed by Clackamas County Democrats: Libra Forde for position 2 and Sonya Fischer for position 5.",
    externalLink: "",
    endpoint:
      "candidates-for-clackamas-county-board-of-commissioners-on-the-record",
    author: "",
    postdate: "15 Mar 2022",
    tags: [{ title: "Election" }],
    fulltext: ``,
  },
  {
    img: "https://www.oregonlive.com/resizer/QHgEupvj28VtSpunzv8DKaPN8Eo=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/DJDPYAWQZ5AE3JNV32GA7GU4XE.JPG",
    title:
      "Opinion: Oregon mayors need state's help in sheltering the unhoused",
    caption:
      "People experiencing houselessness, who live in outdoor camps, on sidewalks and along roadways, are not unique to Portland.",
    externalLink:
      "https://www.oregonlive.com/opinion/2022/02/opinion-oregon-mayors-need-states-help-in-sheltering-the-unhoused.html",
    author: "Ted Wheeler",
    postdate: "16 Feb 2022",
    tags: [{ title: "Election" }],
    fulltext: `
      Hmm... you shouldn't be on this page. Follow this link to find the article you were looking for.
      <br><br>
      <a href='https://www.oregonlive.com/opinion/2022/02/opinion-oregon-mayors-need-states-help-in-sheltering-the-unhoused.html'>https://www.oregonlive.com/opinion/2022/02/opinion-oregon-mayors-need-states-help-in-sheltering-the-unhoused.html</a>"
      `,
  },
  {
    img: image1,
    title: "Time to Get True Democracy for All Back on Track",
    caption:
      "It is time to restore the rights and dignity of everyone. That's why we're voting Democratic again. Join us. There's too much at stake to walk away.",
    author: "Peter Nordbye",
    postdate: "6 Feb 2022",
    endpoint: "time-to-get-true-democracy-for-all-back-on-track",
    tags: [{ title: "Election" }],
    fulltext: "",
  },
  {
    img: image2,
    title: "Step Up, Clackamas! Online Auction and Raffle Coming in March!",
    caption:
      "The fundraising committee is busy spending many hours preparing for the spring fundraising event, Step Up, Clackamas!",
    author: "",
    postdate: "28 Jan 2022",
    endpoint: "step-up-clackamas-online-auction-and-raffle-coming-in-march",
    tags: [{ title: "Election" }],
    fulltext: "",
  },
  {
    img: candidate_conversations,
    title:
      "Healthcare Conversations with Candidates for Governor & Congressional Districts 4, 5, 6",
    caption:
      "In advance of Oregon's May 22 primary election, Physicians for a National Health Program is sponsoring a series of virtual conversations with candidates for governor and for Congressional Districts 4, 5 and 6. ",
    author: "",
    postdate: "25 Jan 2022",
    endpoint:
      "healthcare-conversations-with-candidates-for-governor-and-congressional-districts-4-5-6",
    tags: [{ title: "Election" }],
    fulltext: `
      In advance of Oregon's May 22 primary election, Physicians for a National Health Program is sponsoring a series of virtual conversations with candidates for governor and for Congressional Districts 4, 5 and 6.
      <br><br>
      Conversations take place every Tuesday at 8 pm through May 10. See the schedule below. A one-time, no-cost preregistration is required: https://bit.ly/ORcandidates2022
      <br><br>
      PNHP encourages you to submit specific questions well in advance. Send an email with your questions (subject line: candidate name and scheduled date) to virtualpnhpor@gmail.com.  During each live event, please use email (virtualpnhpor@gmail.com) as submissions will be forwarded in realtime to the moderator.
      <br><br>
      <u>Schedule of Conversations, subject to change. Check https://pnhp.org/chapter/oregon/ for updates.</u>
      <br><br>
      <img src="${candidate_conversations}" alt="candidate conversations"/>
      `,
  },
  {
    img: dems2b3,
    title:
      "The King Legacy of Service: Inspiring thousands to come together to serve their community",
    caption: `Observed each year on the third Monday in January as "a day on, not a day off," MLK Day is the only federal holiday designated as a national day of service to inspire all Americans`,
    author: "Rosa Colquitt, PhD",
    postdate: "17 Jan 2022",
    endpoint:
      "the-king-legacy-of-service-inspiring-thousands-to-come-together-to-serve-their-community",
    tags: [{ title: "Holiday" }],
    fulltext: "",
  },
];
