import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import profile from "../../assets/img/banner/pride-profile-logo.png";
import { useLocation } from "react-router-dom";
import pickingUpTrash from "../../assets/img/oregon-trail-dems/picking-up-trash.jpg";

function OregonTrailDemocrats() {
  const location = useLocation();

  const pageTitle = "Oregon Trail Democrats | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="The Democratic Party of Clackamas County works to elect progressive leaders at the local, state and national levels. Learn more about our mission and join us."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Oregon Trail Democrats" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-2">
              <div className="card">
                <div className="card-title p-2">
                  <h2>Oregon Trail Democrats</h2>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      The{" "}
                      <a href="http://www.oregontraildemocrats.com">
                        Oregon Trail Democrats
                      </a>{" "}
                      envision growing the number of Progressive voters and
                      bringing in new voices by connecting directly to selected
                      voters through a communication network of multimedia
                      platforms and relevant, clear messaging that expresses our
                      values and generates a sense of hope and energy. OTD
                      members live in the Sandy/Hoodland/Estacada/Boring region
                      of House Districts 18, 51, and 52.
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-2">
              <div className="card">
                <div className="card-title p-2">
                  <h2>Upcoming Events</h2>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      Hybrid (Zoom and in person) monthly meetings are held on
                      the 4th Tuesday at 7 PM.
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-2">
              <div className="card">
                <div className="card-title p-2">
                  <h2>Join OTD or renew your OTD membership ($20/person)</h2>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <img src={pickingUpTrash} alt="Picking up trash" />
                  </div>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      If you are a new member or if you need to update your
                      membership information, download the{" "}
                      <a href="http://oregontraildemocrats.com/wp-content/uploads/2020/06/OTD-Donation-Form-2020-1.pdf">
                        membership/donation
                      </a>
                      form and email it to{" "}
                      <a href="mailto:oregontraildems@gmail.com">
                        Oregontraildems@gmail.com
                      </a>
                      . You can also pay your dues online.
                    </div>
                    <div className="col-md-12 col-sm-12">
                      If you prefer to mail in your membership information and
                      dues/donation, please download the
                      <a href="http://oregontraildemocrats.com/wp-content/uploads/2020/06/OTD-Donation-Form-2020-1.pdf">
                        membership/donation
                      </a>
                      form and mail to:
                      <p>Oregon Trail Democrats</p>
                      <p>PO BOX 1724</p>
                      <p>Sandy, OR 97055</p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-2">
              <div className="card">
                <div className="card-title p-2">
                  <h2>Donate Online to Oregon Trail Democrats</h2>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      Thank you for your{" "}
                      <a href="https://secure.anedot.com/oregon-trail-democrats/donate">
                        donation
                      </a>
                      !
                    </div>
                  </div>
                </div>
                <div className="card-title p-2">
                  <h2>Contact Us</h2>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      Send us an email at{" "}
                      <a href="mailto:oregontraildems@gmail.com">
                        oregontraildems@gmail.com
                      </a>
                      , send us mail at PO Box 1724, Sandy, OR 97055 or find us
                      on{" "}
                      <a href="https://www.facebook.com/OregonTrailDemocrats">
                        Facebook
                      </a>{" "}
                      and{" "}
                      <a href="https://www.instagram.com/oregontraildemocrats/">
                        Instagram
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default OregonTrailDemocrats;
