import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import PointList from "../../ui/PointList/PointList";

function CandidateCommunicationsStrategy() {
  const location = useLocation();

  const pageTitle =
    "Candidate Communications Strategy | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get help with writing press releases for your election campaign. Learn how to write a candidate statement and read examples of candidate statements."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs
        breadcrumb={{ pagename: "Candidate Communications Strategy" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
        }}
      >
        <PointList
          points={[
            {
              text: "Writing a press release",
              scrollTarget: "writing-a-press-release",
            },
            {
              text: "Completing the voters pamphlet",
              scrollTarget: "completing-the-voters-pamphlet",
            },
            {
              text: "Sample candidate statements",
              scrollTarget: "sample-candidate-statements",
            },
            {
              text: "Candidate resources",
              scrollTarget: "candidate-resources",
            },
          ]}
        />
      </div>

      <section className="client-section w-100" name="writing-a-press-release">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Writing a press release</h3>
                </div>
                <div className="card-body p-3">
                  <h5>
                    By{" "}
                    <a href="http://hesterschellcreative.com/">Hester Schell</a>
                    , MFA
                  </h5>
                  <p>Below is a standard template for a press release.</p>
                  <br />
                  <p>
                    Remember to always double proof and check links before
                    sending. Avoid having to follow up with a corrected
                    anything. Six eyeballs (three proofreaders) are much better
                    than four eyeballs and mandatory over just two eyeballs.
                    It’s like construction: measure twice, cut once.
                  </p>
                  <br />
                  <div>
                    <p>FOR IMMEDIATE RELEASE: ALL MEDIA OUTLETS</p>
                    <p>TODAY'S DATE</p>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          boxSizing: "border-box",
                          fontSize: "12px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "24px",
                          border: "1px solid #eaeaea",
                          marginRight: "24px",
                        }}
                      >
                        <p>Graphic or LOGO of organizer. </p>
                        <p>
                          NOT the photo illustrating the press release. That
                          goes at the end.
                        </p>

                        <p>
                          When sending your final release, proofed and perfect,
                          ATTACH the photos in addition to attaching the
                          release.
                        </p>
                        <p>
                          Also copy the release into the email body of your
                          outgoing email.
                        </p>
                        <p>Double check that links are active</p>
                      </div>
                      <div
                        style={{
                          width: "60%",
                          boxSizing: "border-box",
                        }}
                      >
                        <p>
                          <u>ACTIVE FOR EXAMPLE TITLE</u>
                          (With location in parentheses under the title.)
                        </p>
                        <p>
                          <u>PRESS CONTACT</u> (do not publish): Whoever is
                          fielding inquiries, name and number. (503)123-1234,
                          email address. Always include the parenthetical do not
                          publish It reminds the rookie recipient that privacy
                          matters in the press.
                        </p>
                        <p>
                          <u>PULL DATE:</u> When info expires or ticket sales
                          close or when it becomes irrelevant.
                        </p>
                        <p>
                          <u>ATTACHMENTS:</u> List, format (only jpegs at 300
                          dpi.) List the photos with photo credit. (Note: Press
                          photo standard is resolution at 300 dpi, 5” x 7”
                          thereabouts and adhere to good photo journalism
                          standards. Vivid, clear, action, in focus and decent
                          lighting.)
                        </p>
                      </div>
                    </div>
                    <br />
                    <p>
                      ===IF YOU ARE DOING A TICKETED EVENT, INCLUDE THIS
                      SECTION:=====
                    </p>
                    <p>WHAT: Type of event</p>
                    <p>
                      WHO: Organizing the event or this announcement is about
                      whom
                    </p>
                    <p>WHY: Mission statement, purpose</p>
                    <p>WHEN: Dates, and whether it repeats)</p>
                    <p>WHERE: Online zoom or live, venue address</p>
                    <p>Active link to maps, event venue website, etc.</p>
                    <p>
                      TICKETS: Cost + LINK to ticket vendor. Include age price
                      breakdown, earlybird, student, etc. Include any deadline
                    </p>
                    <p>
                      PARKING: Encourage public transit where it makes sense.
                    </p>
                    <p>
                      (Note: THIS TOP SECTION is what you will copy/paste into
                      online bulletin boards, which are mainly self serve now.
                      If you don’t want your snappy perfect headline to get lost
                      on the front page, then put all this parsed info at the
                      end.)
                    </p>
                    <p>
                      ----put a line between the listed headers and the
                      release’s body--Begin body---------
                    </p>
                    <br />
                    <p>
                      REPEAT TITLE: Use alliteration and/or great vivid
                      adjectives
                    </p>
                    <p>
                      By Author Name (If a byline is warranted. Might be someone
                      besides the press contact. May or may not include active
                      link. Most writers prefer it, for obvious reasons,
                      especially if they are volunteers.)
                    </p>
                    <br />
                    <p>
                      The blab la of the bla blah is a snappy opening sentence.
                      The why anyone should pay attention to this announcement.
                      Your most important details go in the first paragraph. If
                      there is a star headliner, whether the date, such as July
                      4th, or a person, The CANDIDATE… always makes for a best
                      opener. Use standard “readable” fonts and never themed
                      fonts. Stick with Times New Roman. Learn about “the
                      readable fonts.” Titles may or may not use 14 font. Body
                      is in 12, or 11, if you must squeeze onto one page. Size
                      10 font is reserved for sub-liners, such as photo credits.
                      If your press release is hard to read with small type, the
                      news editors will go to the next one. Do not expect any
                      news outlet to add your event to a calendar. These are
                      usually self-service. This first paragraph also uses 1.5
                      line spacing. Notice the following paragraphs do not. It
                      just looks nicer and more professional. Most importantly,
                      it is more readable. Un-squished. Parse your words. Bring
                      your reader into your world.
                    </p>
                    <p>
                      Additional information is in the second paragraph. Cozy
                      details about the event, what to bring, why come. Repeat
                      in different words: Why this is really important.
                    </p>
                    <p>
                      Additional information that’s RELEVANT to the event here,
                      in the third paragraph. Shorter the better when it comes
                      to Press Releases; Newsrooms are UNDERSTAFFED. Write
                      enthusiastically, tell the truth, grab attention. Demand
                      attention. Make it worthwhile reading. It’s election
                      season and only the grabbing headlines will get noticed.
                      Instead of “please participate in this auction for
                      Clackamas Dems,” rather… mention the consequences of a RED
                      Oregon. Calls to action require active verbs. Stronger to
                      motivate action. “Can you imagine restricted abortion
                      access in Oregon? Neither can we.” “Tired of the
                      Filibuster? Join us. Boots on the ground.”
                    </p>
                    <br />
                    <p>Citations:</p>
                    <p>
                      ACTIVE Links to any articles cited or quoted or referenced
                      in the creation of the press release. Substantiated facts.
                      Not opinion. Back up numbers and statistics. Which poll
                      percentage said what. The standard is three agreeing
                      sources to substantiate a fact. We all know that went to
                      shit. Screw Fox News.
                    </p>
                    <p>#####</p>
                    <p>
                      Hashtags used to mean end of article. However, here’s
                      where to list social media tags.
                    </p>
                    <div
                      style={{
                        width: "40%",
                        height: "200px",
                        border: "1px solid black",
                      }}
                    >
                      example photo
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      <p>Example photo tagging: </p>
                      <p>Gladstone Community Parade, August 6, 2022</p>
                      <p>
                        L to R: Catherine McMullen, Candidate for Clackamas
                        County Clerk; Jamie McLeod-Skinner, Candidate for US
                        Congress; State Senator Mark Meeks, up for re-election;
                        Annessa Hartman, Candidate for Oregon State House 40.
                        Photo Credit: hesterschellcreative.com
                      </p>
                      <p>
                        Notice this type can be size 10 font. Be as thorough and
                        short as needed. We’ve got a photo with 4 people, so
                        therein is the challenge.
                      </p>
                    </div>
                    <br />
                    <p>
                      Try never to go to a third page. Shrink the photo to
                      create more space on the page. Remember to ATTACH the 300
                      dpi to the outgoing email to the targeted editor who WANTS
                      to know about this important perfect press release. THEY
                      need your news. Never doubt it. You can target which
                      editor through the news media staff pages at that company.
                      It only takes a few minutes to find the right news desk to
                      land on. Good luck.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="client-section w-100"
        name="completing-the-voters-pamphlet"
      >
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Completing the Voters Pamphlet</h3>
                </div>
                <div className="card-body p-3">
                  <h5>By Markley Drake</h5>
                  <br />
                  <div
                    style={{
                      height: "300px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src="/assets/img/misc/voters-guide.jpg"
                      alt="Voters guide"
                      style={{
                        height: "100%",
                      }}
                    />
                  </div>
                  <br />
                  <h4>Clackamas County Voters’ Pamphlet Instructions</h4>
                  <br />
                  <p>
                    For every election the voters pamphlet is mailed to all
                    registered voters and includes information on candidates
                    that is used by the voters to help decide whom they should
                    support. Various studies show that approximately 30 to 37
                    percent of the public reviews the voters pamphlet. So if you
                    publish a statement in the Voters’ Pamphlet, you are
                    reaching approximately one-third of the voters. It is
                    extremely important that your statement conveys the
                    information you want voters to see. You will file under
                    “Candidate Filing – Individual Electors – Nonaffiliated (SEL
                    114).
                  </p>
                  <p>
                    Clackamas County Clerk’s office is in charge of receiving
                    the material submitted by the candidates to publish in the
                    pamphlet. The Current County Clerk is Catherine McMullen.
                    The contact information is below:
                  </p>
                  <ul>
                    <li>Phone: 503-655-8510</li>
                    <li>
                      Email:{" "}
                      <a href="elections@clackamas.us">
                        elections@clackamas.us
                      </a>
                    </li>
                    <li>
                      Address: 1710 Red Soils Court, Suite 100, Oregon City, OR
                      97045
                    </li>
                  </ul>
                  <p>
                    <strong>Important note</strong> Office Hours: Monday to
                    Thursday, 8:30 am to 5 pm, CLOSED Fridays.
                  </p>
                  <br />
                  <p>
                    This office is where you as a candidate will find
                    information regarding the election, including important
                    dates and other information. The dates include deadlines for
                    submission of materials and forms. The website has a tag
                    “Election Information,” which has the Election calendar and
                    Candidate Information. A candidate should view this website
                    and print or save the information for your election.
                    <a href="https://www.clackamas.us/elections/calendar.html">
                      Elections Calendar | Clackamas County.
                    </a>
                  </p>
                  <br />
                  <p>
                    For example, for the May 2023 election, the last day to file
                    the Candidate Statement for the Voters’ Pamphlet is March
                    20, 2023. This is a Monday, however{" "}
                    <strong>Important note:</strong>
                    Candidates should file their Candidate Statement for the
                    Voters’ Pamphlet four to five days before the deadline. The
                    reason for this is that if something is missing from your
                    information or the clerk’s office can not open the
                    information on thumb drive, then they need time to correct
                    the information before the deadline.
                  </p>
                  <br />
                  <p>
                    <strong>Important Note:</strong> The Clerk’s office will not
                    receive any candidates information after the Deadline.{" "}
                    <strong>Important Note:</strong> The Clerk’s office will not
                    proofread your statement and make any grammar or spelling
                    corrections. Make sure your document is free of spelling and
                    grammatical errors. Remember, voters will judge you on your
                    statement.
                  </p>
                  <br />
                  <p>
                    The state of Oregon, within the Secretary of State’s Office,
                    includes a “State Voters’ Pamphlet Manual.” This manual
                    informs candidates about the costs to submit their
                    information and the forms they need to complete. Each
                    statement has a maximum word count of 325 words, which means
                    it is essential to choose words wisely. The cost listed is
                    $600.00, to be submitted with your candidate statement.
                  </p>
                  <br />
                  <p>
                    Your Voters’ Pamphlet photo is important and should look
                    professional. You can have a friend take your photo or have
                    the photo done professionally. All photos are black and
                    white photos.
                  </p>
                  <br />
                  <p>
                    <strong>Important note:</strong> If you list people who have
                    endorsed you, you must have the proper forms filled out with
                    the signatures of the persons endorsing you. This is from
                    SEL 400, Statement of Endorsement.
                  </p>
                  <br />
                  <br />
                  <p>
                    Also on the form submitted, you will designate the office or
                    position you are running for. The form that is printed in
                    the Voters’ Pamphlet will ask the following questions:
                  </p>
                  <br />
                  <p>
                    Occupation: Put your current employer and note if the
                    position is paid or volunteer.
                  </p>
                  <br />
                  <p>
                    Occupational Background: List only those past jobs or
                    volunteer organizations that are pertinent to the position
                    you are applying for. Do not list jobs since high school, as
                    you do not want to exceed the word limit.
                  </p>
                  <br />
                  <p>
                    Educational Background: List colleges and universities you
                    have attended and graduated from, along with the degree you
                    received. If you did not receive a degree, then list as
                    “attended” colleges or universities. If there are no
                    colleges or universities to list, then simply list your high
                    school and general studies.
                  </p>
                  <br />
                  <p>
                    Prior Governmental Experience: List any experience with the
                    local, state or federal governments, including volunteer
                    positions. You can include work or volunteer for schools and
                    other organizations. You can also include any military
                    service in this section.
                  </p>
                  <br />
                  <p>
                    The next field is open to your explanation as to why you are
                    running for this office and why you feel you are the right
                    candidate for this position. Also, in this section you will
                    list any endorsements from other persons. Be careful whom
                    you choose to list as an endorsement. Endorsements from
                    people who may be perceived negatively in your community
                    will not be helpful.
                  </p>
                  <br />
                  <p>
                    This may go without saying, but do be honest in all
                    materials submitted. Do not exaggerate. Many candidates have
                    been tripped up by claiming education or experiences,
                    including military service, that they did not actually have.
                  </p>
                  <br />
                  <p>
                    If you have any questions or need additional assistance
                    please contact me, Markley Drake, at{" "}
                    <a href="markleyerd@msn.com">markleyerd@msn.com</a>. I will
                    be happy to assist any Democrats running for office in
                    Clackamas County.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="client-section w-100"
        name="sample-candidate-statements"
      >
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Sample Candidate Statements for the Voters’ Pamphlet</h3>
                  <p>
                    Sample candidate statements from the November 8, 2022 Oregon
                    General Election Voters’ Pamphlet.
                  </p>
                </div>
                <div className="card-body p-3">
                  <h4>
                    US Representative, 3rd District Earl Blumenauer candidate
                    statement
                  </h4>
                  <p>
                    <i>
                      Representative Blumenauer used headings, bullet points and
                      friendly sign-off to summarize the main points of his
                      candidate statement:
                    </i>
                  </p>
                  <br />
                  <p>
                    The last few years have been difficult as our community
                    continues to deal with the pandemic, global inflation, a
                    hous- ing affordability crisis, worsening climate
                    disruption, and the disastrous decision to overturn Roe v.
                    Wade.
                  </p>
                  <br />
                  <p>
                    <strong>
                      Despite these challenges, we are standing strong and
                      getting things done.
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Passed the largest investment in climate action in our
                      nation’s history;
                    </li>
                    <li>
                      Allowed Medicare to negotiate the cost of prescription
                      drugs and penalized companies for outrageous drug price
                      hikes;
                    </li>
                    <li>Enacted bipartisan gun control legislation;</li>
                    <li>
                      Assured corporations pay their fair share with a 15%
                      minimum corporate tax;
                    </li>
                    <li>
                      Made polluters pay by reinstating the Superfund tax;
                    </li>
                    <li>Capped the cost of insulin at $35 a month;</li>
                    <li>
                      Accelerated the production of wind and solar projects and
                      made it cheaper and easier to purchase electric vehicles;
                    </li>
                    <li>
                      Provided more than $1 trillion in historic investments for
                      bridges, transit, water, sewer, and broadband; and
                    </li>
                    <li>
                      Eliminated tariffs on infant formula to help weather a
                      nationwide shortage.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>But there is still more to do.</strong>
                  </p>
                  <ul>
                    <li>
                      Reassert the federal government’s responsibility in
                      providing affordable housing and helping those
                      experiencing mental health and addiction issues;
                    </li>
                    <li>
                      Create an equitable and fair tax system that generates
                      opportunities for Americans and funds our future;
                    </li>
                    <li>
                      Reimagine our food and farm policies to enhance Americans’
                      health and help family farmers keep farm- land in
                      production;
                    </li>
                    <li>Fight to protect democracy;</li>
                    <li>Finally end the failed war on drugs; and</li>
                    <li>
                      Ensure that all people have the same reproductive freedom
                      that Oregonians have. We have made progress where many
                      thought impossible. It’s critical to keep up the fight. We
                      are all in this together.
                    </li>
                  </ul>
                  <br />
                  <p>With your vote, we can and we will succeed.</p>
                  <br />
                  <p>Courage,</p>
                  <br />
                  <p>Earl</p>
                  <hr />
                  <h4>
                    Candidate for US Representative, 5th District, Jamie
                    McLeod-Skinner
                  </h4>
                  <p>
                    <i>
                      McLeod-Skinner used a quote from a trusted resource,
                      headings, bullet points and linked to her website for more
                      information in her candidate statement:
                    </i>
                  </p>
                  <br />
                  <p>
                    “In Congress, Jamie will be an independent leader who can be
                    trusted to stand up for Oregon’s hard- working families,
                    seniors and communities -- not corporate special interests
                    or extremists.” - Senator Ron Wyden
                  </p>
                  <br />
                  <p>
                    <strong>A VOICE FOR ALL OF US</strong>
                  </p>
                  <br />
                  <p>
                    Jamie’s mom worked three jobs to feed their family. A small
                    business owner who worked her way through college and law
                    school, Jamie understands how hard it can be to make ends
                    meet. In Congress she will battle inflation and stand up to
                    anyone–even her own party–to deliver for Oregonians:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Reduce the outrageous cost of healthcare and all
                      prescription drugs.
                    </li>
                    <li>Increase affordable housing.</li>
                    <li>
                      Fight price gouging by billionaire corporations that
                      drives inflation.
                    </li>
                    <li>
                      Protect our water, natural resources, agriculture, and
                      rural economies.
                    </li>
                  </ul>
                  <br />
                  <p>
                    <strong>KEEPING US SAFE</strong>
                  </p>
                  <br />
                  <p>
                    As City Manager, Jamie balanced budgets and increased police
                    funding, training, and accountability. Jamie led wildfire
                    recovery efforts, helping communities prepare for and
                    respond to emergencies. She will tackle the climate crisis
                    that is fueling devastating wildfires and drought.
                  </p>
                  <br />
                  <p>
                    <strong>DEFENDING OUR FUNDAMENTAL RIGHTS</strong>
                  </p>
                  <br />
                  <p>
                    Endorsed by Planned Parenthood Action Fund, Jamie will take
                    on extremists like her opponent — who would ban access to
                    abortion, even before a woman knows she’s pregnant — and
                    keep government out of our personal medical decisions.
                  </p>
                  <br />
                  <p>
                    <strong>FIGHTING CORRUPTION</strong>
                  </p>
                  <br />
                  <p>
                    Jamie will work for us – not powerful DC insiders or party
                    bosses. That’s why, unlike her opponent, she refuses to take
                    money from corporate special interests and will vote for
                    iron-clad ethics rules, including banning Congressmembers
                    from trading stocks.
                  </p>
                  <br />
                  <p>
                    <strong>BROAD-BASED SUPPORT</strong>
                  </p>
                  <br />
                  <p>
                    Nominated by the <strong>Democratic</strong>,{" "}
                    <strong>Independent</strong> and{" "}
                    <strong>Working Families Parties</strong>
                  </p>
                  <br />
                  <p>
                    Supported by Democrats, Republicans, unions and
                    organizations throughout our communities.
                  </p>
                  <br />
                  <p>
                    See the entire list:{" "}
                    <a href="https://jamieforOregon.com">JamieforOregon.com</a>
                  </p>
                  <hr />
                  <h4>State Senator, 20th District Mark Meek</h4>
                  <p>
                    <i>
                      Meek started with a strong personal story, included
                      multiple quotes from constituents, added a long list of
                      endorsements and linked to his website in his candidate
                      statement:
                    </i>
                  </p>
                  <br />
                  <p>
                    Mark Meek understands the struggles facing Oregon fami-
                    lies. Mark was homeless and hungry for periods as a child -
                    he knows firsthand how soaring prices and homelessness are
                    hurting Oregon families. That’s why he’s fighting to reduce
                    costs: investing in affordable housing, capping prescription
                    drug prices, and opposing tolling. The issues facing Oregon
                    families are personal to Mark.
                  </p>
                  <br />
                  <p>
                    “As a mother, Mark Meek is the only person I trust to keep
                    our families safe. Mark will fight for more funding for law
                    enforcement, and as a veteran he knows how dangerous weapons
                    of war can be. That’s why Mark also supports common-sense
                    gun safety measures, such as keeping people convicted of
                    stalking or domestic abuse from owning guns”.
                  </p>
                  <p>- Heather Bonney, Mother (Milwaukie)</p>
                  <br />
                  <p>
                    “Some candidates talk. Mark Meek delivers. Mark is getting
                    things done in Salem to bring down costs and build more
                    affordable housing - and as a local home builder, I know I
                    can count on him to do the job”.
                  </p>
                  <p>- Mark Beirwagen, Home Builder (Oregon City)</p>
                  <br />
                  <p>
                    “Mark Meek will stand up for our seniors and continue
                    fighting pharmaceutical companies to stop price hikes for
                    prescription drugs. Mark is also the only candidate in this
                    race who will protect access to birth control and defend a
                    woman’s right to choose”.
                  </p>
                  <p>
                    - Honey Robbins, Family Nurse Practitioner (Oregon City)
                  </p>
                  <br />
                  <p>Endorsers:</p>
                  <p>U.S. Senator Ron Wyden</p>
                  <p>Oregon AFL-CIO</p>
                  <p>Planned Parenthood PAC of Oregon Pro-Choice Oregon PAC</p>
                  <p>Oregon Education Association - PAC</p>
                  <p>Stand for Children</p>
                  <p>
                    Oregon State Building & Construction Trades Council Basic
                    Rights Oregon Equality PAC
                  </p>
                  <p>
                    Oregon League of Conservation Voters Denyse McGriff, Oregon
                    City Mayor
                  </p>
                  <p>
                    David Emami, Happy Valley City Councilor
                    www.VoteMarkMeek.com
                  </p>
                  <hr />
                  <h4>US Representative, 6th District, Andrea Salinas</h4>
                  <p>
                    <i>
                      Salinas started with her story, then used boldface
                      headings to highlight the main points of her campaign,
                      included endorsements and linked to her website for more
                      information in her candidate statement:
                    </i>
                  </p>
                  <br />
                  <p>
                    As the daughter of a Mexican immigrant who worked in the
                    fields and served in Vietnam to become a U.S. citizen,
                    Andrea knows change can happen in a generation through hard
                    work. As a mom and State Representative, Andrea’s driven by
                    a commitment to create opportunities for all Oregonians and
                    build a stronger future for her daughter’s generation.
                  </p>
                  <br />
                  <p>Andrea’s been on the frontlines fighting for:</p>
                  <br />
                  <p>
                    <strong>AFFORDABLE HEALTH CARE</strong> – When prescription
                    drug prices were getting out-of-control, Andrea held the
                    pharmaceutical industry accountable and passed legislation
                    that lowered drug costs for Oregonians.
                  </p>
                  <br />
                  <p>
                    <strong>TACKLE HOMELESSNESS CRISIS</strong> – Andrea knows
                    we must tackle our homelessness crisis head on. She believes
                    it’s time to invest in affordable housing, expand mental
                    health services, fund addiction treatment, and grow economic
                    opportunity.
                  </p>
                  <br />
                  <p>
                    <strong>BUILDING AN ECONOMY THAT WORKS FOR ALL</strong> –
                    Andrea will fight for hard-working Oregonians, not big
                    corpora- tions. She helped increase the minimum wage and
                    passed paid family and medical leave. She’ll work for a more
                    fair tax system, so the rich pay their share and working
                    people aren’t over-burdened. She’s committed to lowering
                    costs for families and increasing opportunities for our
                    communities.
                  </p>
                  <br />
                  <p>
                    <strong>REPRODUCTIVE RIGHTS</strong> – Andrea helped pass
                    the nation’s strongest reproductive rights law so Oregon
                    women have the right to choose even after the Supreme Court
                    decision. In Congress, Andrea will fight to protect access
                    to abortion.
                  </p>
                  <br />
                  <p>
                    <strong>PROTECTING OUR LAND, AIR, WATER</strong> – Andrea
                    knows we need urgent solutions to our climate crisis. She
                    has stood up to corporate polluters to reduce pollution,
                    protected clean air and water, and put Oregon on a path to
                    100% clean energy.
                  </p>
                  <br />
                  <p>
                    <strong>Endorsed by:</strong>
                  </p>
                  <br />
                  <p>Planned Parenthood Action Fund</p>
                  <br />
                  <p>Oregon League of Conservation Voters Oregon AFL-CIO</p>
                  <br />
                  <p>SEIU Oregon</p>
                  <br />
                  <p>
                    Oregon Education Association - PAC Independent Party of
                    Oregon
                  </p>
                  <br />
                  <p>Ron Wyden</p>
                  <br />
                  <p>And more! www.AndreaSalinasForOregon.com</p>
                  <hr />
                  <h4>Governor Tina Kotek</h4>
                  <p>
                    <i>
                      Kotek started off by describing her background and
                      qualifications then described challenges the state was
                      facing, then used purposeful language and many boldface
                      statements to highlight her plan to get results, included
                      a quote from a trusted resources, endorsements and linked
                      to her website for more information in her candidate
                      statement:
                    </i>
                  </p>
                  <br />
                  <p>
                    <strong>
                      Proven Leader Ready to Tackle Oregon’s Serious Challenges
                    </strong>
                  </p>
                  <br />
                  <p>
                    I spent the last 20 years working to make things better for
                    Oregonians. At Oregon Food Bank and Children First, I fought
                    to create more opportunities for{" "}
                    <strong>working families</strong>, and then as Oregon’s
                    House Speaker – I passed the{" "}
                    <strong>
                      strongest abortion access law in the country, raised the
                      minimum wage, and guaranteed paid sick leave for workers.
                    </strong>
                  </p>
                  <br />
                  <p>
                    Oregon is facing major challenges - homelessness, crime,
                    addiction, skyrocketing costs, and wildfires. We need a
                    Governor who can fix problems and will{" "}
                    <strong>always be on the side of working people.</strong>
                  </p>
                  <br />
                  <p>
                    I’ll be a{" "}
                    <strong>
                      force for positive change and deliver results
                    </strong>
                    . As Governor, I will:
                  </p>
                  <ul>
                    <li>
                      Defend and expand access to{" "}
                      <strong>reproductive health care</strong>.
                    </li>
                    <li>
                      Work to <strong>reduce gun violence</strong> by increasing
                      the age to buy assault weapons from 18 to 21.
                    </li>
                    <li>
                      Take on our homelessness crisis by{" "}
                      <strong>
                        increasing street outreach teams, expanding managed
                        shelters, improving access to mental health services,
                        and building more housing.
                      </strong>
                    </li>
                    <li>
                      <strong>Fight back against the drug cartels</strong> in
                      Oregon and deliver on our promise to{" "}
                      <strong>
                        expand access to addiction treatment and recovery
                        services statewide.
                      </strong>
                    </li>
                    <li>
                      Make sure our schools{" "}
                      <strong>
                        fund career and technical education, improve graduation
                        rates, and support student mental
                      </strong>
                      health.
                    </li>
                    <li>
                      <strong>Fight climate change</strong> and create
                      good-paying jobs in clean energy. “Reproductive freedom is
                      under attack in every single state. Tina Kotek is{" "}
                      <strong>the only</strong>
                      candidate for Governor we trust to defend our right to
                      control our own bodies.” -Planned Parenthood PAC of Oregon
                      Executive Director An Do{" "}
                      <strong>
                        TINA IS THE ONLY CANDIDATE FOR GOVERNOR ENDORSED BY:
                      </strong>{" "}
                      Planned Parenthood PAC of Oregon Everytown for Gun Safety
                      Action Fund Oregon League of Conservation Voters Oregon
                      Nurses Association Oregon State Building & Construction
                      Trades Council U.S. Senators Ron Wyden & Jeff Merkley
                    </li>
                  </ul>
                  <p>More endorsements: TinaForOregon.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section w-100" name="candidate-resources">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Candidate resources</h3>
                </div>
                <div className="card-body p-3">
                  <p>
                    Get{" "}
                    <Link to="/candidate-portal/candidate-resources">
                      candidate resources for running for office
                    </Link>{" "}
                    including:
                  </p>
                  <ul>
                    <li>Oregon State Candidate Help</li>
                    <li>Candidate Statements</li>
                    <li>Campaign Finance Information</li>
                    <li>Election Resources</li>
                    <li>Help and Training for First-Time Candidates</li>
                    <li>Running for a School Board</li>
                    <li>Using NGP VAN</li>
                    <li>Voting trends (analyze past elections)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default CandidateCommunicationsStrategy;
