import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Client from "../layouts/Client";
import Content from "../sections/Donate/Content";

function Donate() {
  return (
    <Fragment>
      <MetaTags>
        <title>Donate | Clackamas County Democrats</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Donate" }} />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default Donate;
