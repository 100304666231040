import React, { Fragment, useEffect } from "react";

import MetaTags from "react-meta-tags";

import Header from "../../layouts/Header";

import Footer from "../../layouts/Footer";

import { Link, useLocation } from "react-router-dom";

import Breadcrumbs from "../../layouts/Breadcrumbs";

import PointList from "../../ui/PointList/PointList";

function CandidateTraining() {
  const location = useLocation();

  const pageTitle = "Candidate Training | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;

    const dataLayer = {
      event: "virtualPageView",

      pageUrl: url,

      pageTitle: pageTitle,
    };

    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>

        <meta
          name="description"
          content="Get candidate training from the DPCC. Learn more about financing a campaign, public speaking tips, event planning and mobilizing volunteers."
        />
      </MetaTags>

      <Header />

      <Breadcrumbs breadcrumb={{ pagename: "Candidate Training" }} />

      <div
        style={{
          display: "flex",

          flexDirection: "column",

          justifyContent: "center",

          alignItems: "center",

          paddingBlockStart: "36px",

          paddingBlockEnd: "36px",

          margin: "0 auto",
        }}
      >
        <PointList
          points={[
            {
              text: "Finance your Campaign - Using OreStar",

              scrollTarget: "finance",
            },

            {
              text: "Public speaking basics",

              scrollTarget: "public-speaking",
            },

            {
              text: "Event Planning",

              scrollTarget: "event-planning",
            },

            {
              text: "How to use NGP VAN",

              scrollTarget: "ngp-van",
            },

            {
              text: "Candidate Resources",

              scrollTarget: "candidate-resources",
            },
          ]}
        />

        <section className="client-section w-100" name="finance">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Financing your campaign - Introduction to Orestar</h3>
                  </div>

                  <div className="card-body p-3">
                    <h4>What is OreStar?</h4>

                    <br />

                    <p>
                      OreStar (Oregon Elections System for Tracking and
                      Reporting) is the system developed by the Oregon Secretary
                      of State to access information on campaigns-{" "}
                      <a href="https://secure.sos.state.or.us/orestar">
                        Access OreStar
                      </a>
                      . All transactions you submit to the state for your
                      campaign will be recorded and are available in OreStar.
                    </p>

                    <br />

                    <p>
                      Detailed guides as to how to submit statements of
                      organization and transactions to OreStar are linked below.
                      These will be useful if you or another person are serving
                      as the treasurer for your campaign. If you work with a
                      campaign compliance/reporting firm like{" "}
                      <a href="https://www.c-esystems.com/">C&E Systems</a>,
                      they will take care of this paperwork for you.
                    </p>

                    <br />

                    <br />

                    <h4>What information can you get from OreStar?</h4>

                    <br />

                    <p>
                      OreStar contains a wealth of information on campaigns and
                      campaign finance.
                    </p>

                    <br />

                    <br />

                    <h4>Candidate finance information</h4>

                    <br />

                    <p>
                      You can use OreStar to gather valuable information about
                      campaign finance, such as how much your opponents have
                      raised and what people who ran for this race in the past
                      spent.
                    </p>

                    <br />

                    <p>
                      Use the Committees/Filers by Name tab to find any
                      candidate who has filed with OreStar. On the candidate’s
                      page, click Campaign Finance Summary for a list of all
                      transactions (which can be exported into Excel) or{" "}
                      <strong>Account Summary</strong> to see a current snapshot
                      of candidate finances.
                    </p>

                    <br />

                    <div>
                      <img
                        src="/assets/img/misc/statement-of-organization-for-candidate-committee.png"
                        alt="statement-of-organization-for-candidate-committee"
                      />
                    </div>

                    <p>
                      <i>
                        Example of an OreStar Statement of Organization for
                        Candidate Committee.
                      </i>
                    </p>

                    <br />

                    <br />

                    <h4>Potential donor information</h4>

                    <br />

                    <p>
                      You can use OreStar to gather information on people who
                      have donated to races similar to yours, such as people who
                      ran for the same seat, people who live in a certain city
                      or zip code, or people who donated to ballot measures that
                      might be related to your candidacy. You can filter on the
                      <strong>Campaign Finance Transactions</strong> tab to find
                      these donors and export the data to an Excel file for
                      analysis.
                    </p>

                    <br />

                    <div>
                      <img
                        src="/assets/img/misc/transaction-information.png"
                        alt="transaction-information"
                      />
                    </div>

                    <p>
                      <i>
                        Example of an OreStar search for donors living in zip
                        code 97267 who donated at least $100 to Tina Kotek in
                        the past year.
                      </i>
                    </p>

                    <br />

                    <br />

                    <h4>Learn more about OreStar</h4>

                    <ul>
                      <li>
                        <a href="https://cms4files.revize.com/sutherlinor//6.%20Finance%20quickguide.pdf">
                          Campaign Finance Reporting in Oregon- Candidate "Quick
                          Guide"
                        </a>
                      </li>

                      <li>
                        <a href="https://conservationdistrict.org/wp-content/uploads/candidatequickguide.pdf">
                          Candidate ―Quick Guide on Campaign Finance Reporting
                          in Oregon
                        </a>
                      </li>

                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/orestarSOO.pdf">
                          The ORESTAR User’s Manual: Statement of Organization
                        </a>
                      </li>

                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/campaign-finance.pdf">
                          Campaign Finance Manual
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="client-section w-100" name="public-speaking">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Public speaking basics</h3>

                    <p>
                      by{" "}
                      <a href="http://hesterschellcreative.com/">
                        Hester Schell
                      </a>
                      , MFA
                    </p>
                  </div>

                  <div className="card-body p-3">
                    <p>
                      Welcome. This tutorial is about the{" "}
                      <strong>delivery</strong> of your speeches to enrich your
                      campaign handshake-by-handshake, doorbell-by-doorbell,
                      speech-by-speech at rally-by-rally.
                      <strong>Words matter</strong>. Take Roosevelt for example:
                      “We have nothing to fear but fear itself.” FDR lifted an
                      entire nation out of the Great Depression. The next bit of
                      advice is to invest in really comfy shoes. Think like a
                      soldier: take care of your feet.
                    </p>

                    <br />

                    <p>
                      You have all our support and gratitude. Thank you for
                      running for office. Reach out with questions. Let us
                      begin. You are a candidate. Be Fearless! You can do this.
                    </p>

                    <br />

                    <br />

                    <h4>Ingredients of a Great Speech:</h4>

                    <br />

                    <ul>
                      <li>Great Writing: Get Help</li>

                      <li>Connect with the Audience: Eye Contact</li>

                      <li>
                        Enough Preparation and Practice: Research and Rehearsal
                      </li>

                      <li>Know Your Audience: Customize</li>

                      <li>Clear Messaging: Authenticity</li>

                      <li>
                        Good Body Awareness: Replace fear with relaxed
                        confidence
                      </li>

                      <li>
                        Know your material: Bring notes, avoid using devices
                      </li>

                      <li>The audience is on your side.</li>

                      <li>Use humor.</li>

                      <li>
                        Have courage to grow from mistakes: Let it go, don’t
                        worry.
                      </li>
                    </ul>

                    <br />

                    <br />

                    <h4>Purpose</h4>

                    <br />

                    <p>
                      Winning elections is in the math: Great Campaigning +
                      Public Speaking + Larger # of VOTES = Successful Campaign.
                    </p>

                    <br />

                    <p>
                      We make speeches to communicate a message, inspire and
                      motivate action through chosen words. Your physicality
                      also makes an impression on your audience. Composing and
                      delivering an effective and memorable speech is a top
                      priority for a successful campaign. A great speech can do
                      more than motivate <strong>votes</strong> for you. It can
                      bring <strong>essential volunteers</strong> to help your
                      campaign.
                    </p>

                    <p>
                      <strong>Alert:</strong> There’s homework. If you really
                      want to win, there is no “calling it in,” or last minute
                      prep. Set a schedule. Keep it.
                    </p>

                    <br />

                    <br />

                    <h4>Step One: Research.</h4>

                    <br />

                    <p>
                      What kind of speech(es) do I need? Several platform
                      speeches of varying lengths. A super short highlight
                      introduction.
                    </p>

                    <p>
                      Who is your audience? Customize: Age, geography, rural,
                      urban?
                    </p>

                    <p>
                      Length? Your allotted time will vary from campaign stop to
                      stop, event to event. -- Structure is required:
                    </p>

                    <p>INTRO: Introduce yourself. Tell us why you are here.</p>

                    <p>
                      BUILD: Back story, personal history. Set up the problem.
                    </p>

                    <p>MESSAGE: How are You going to fix this problem?</p>

                    <p>RE-CAP: Who and Why</p>

                    <p>
                      ACTION ITEM: Register to Vote. Vote for Me. Meet me here.
                      Come volunteer.
                    </p>

                    <p>
                      THANK YOU: Always thank hosts, sponsors, venue managers.
                    </p>

                    <br />

                    <p>
                      -- Think like a copywriter. Create a snappy parting quote,
                      something that pops (think song titles) for a sound byte
                      the media could pick up on, such as a repeatable phrase;
                      Biden’s “let’s finish the job,” for example, or “Stop by
                      my office.”
                    </p>

                    <br />

                    <p>
                      --Learn about set-ups and punchlines, tag lines,
                      alliteration, use of humor. All of that is speech writing.
                    </p>

                    <br />

                    <p>Homework:</p>

                    <ul>
                      <li>Watch and Learn from the best.</li>

                      <li>
                        Watch famous speeches: Look for the combination of{" "}
                        <strong>great delivery and great writing</strong>. Find
                        famous speeches on YouTube. Your local library is
                        another great resource.
                      </li>
                    </ul>

                    <p>Make Notes:</p>

                    <ul>
                      <li>
                        Notice where speakers pause, look away and come back,
                        how they pause for a needed sip of water. Listen for a
                        repeated phrase (campaign slogan, opponent dig).
                      </li>

                      <li>
                        Notice where the speaker waited for the audience to come
                        back to listening, the pause for a laugh, the pause for
                        reflection or dramatic effect. The pausing is important,
                        not only to grab that sip of water, but also because it
                        is one of the tools we use to connect with the audience.
                        It says many things, including “I have your attention,
                        I’m about to proceed.”
                      </li>

                      <li>
                        Be a better listener. Listening connects the speaker to
                        the audience. While it is usually laughter, sometimes it
                        is heckling. Biden is a master with the hecklers. Learn
                        from his 2023 State of the Union address in February
                        2023. Sharp wit is part of his <strong>armor</strong>.
                      </li>

                      <li>
                        Notice what you like in these great speeches. Why? What
                        can you use?
                      </li>
                    </ul>

                    <hr />

                    <p>Three C’s of Public Speaking:</p>

                    <ul>
                      <li>Content: Messaging</li>

                      <li>Clarity: Diction as well as meaning and intention</li>

                      <li>
                        Charisma: Holding an audience, keeping the attention
                      </li>
                    </ul>

                    <br />

                    <br />

                    <h4>Step Two: Preparation = Armor</h4>

                    <br />

                    <p>What kind of armor works for you? Find out.</p>

                    <p>
                      What is armor? Protection. Your armor protects you from
                      getting off-track and losing your place in the notes, or
                      flipping your index card too early, stumbling over words.
                      Oops, now you’ve lost it. Bells go off, you start
                      sweating, now you’re losing your breath, you reach for the
                      water, and everyone can see you are shaking. Oh No!!
                    </p>

                    <br />

                    <p>
                      <u>Worse case scenario</u>: A full on panic attack.
                      Fainting. STOP: It’s only the fear response. It is only
                      ADRENALINE, a naturally occurring hormone. Stop. Fight or
                      Flight. You can learn to work with it.
                    </p>

                    <br />

                    <p>
                      <u>Best cast scenario</u>: Avoid panic attacks. How?
                      Practice, and by using courage to displace fear. The more
                      you face your fear, face the stage-fright, the more
                      courageous you become. The more courageous you become, the
                      more confident. And you get better and better with your
                      public appearances. Win. Win.
                    </p>

                    <br />

                    <ul>
                      <li>
                        Pausing can be your armor: For a sip of water, a moment
                        to gather thoughts together, get your breathing back
                        under control, listen to the heckler, so you can
                        respond. Not responding, just pausing is also a
                        response.
                      </li>

                      <li>
                        The right words are armor. Work with a speechwriter to
                        make your speech pop and inspire your voters. There are
                        many tutorials available. You need brilliant, final copy
                        ready to deliver.
                      </li>

                      <li>
                        Your armor is also your life experience, instinct and
                        intelligence. It’s why you are up there in the first
                        place.
                      </li>

                      <li>Be Courageous. Be Bold. Be yourself.</li>
                    </ul>

                    <br />

                    <p>Homework:</p>

                    <p>List your strengths:</p>

                    <ul>
                      <li>
                        Are you good with humor and can deliver a joke? Pause
                        for the laugh?
                      </li>

                      <li>Have you ever coached kids?</li>

                      <li>
                        Look to your life experiences. Use these stories. Make
                        them relatable.
                      </li>
                    </ul>

                    <br />

                    <p>
                      Keep this list handy: Why I am running for office. These
                      phrases will save you over and over.
                    </p>

                    <br />

                    <p>CONGRATULATIONS!</p>

                    <p>You’ve watched the “Greats.”</p>

                    <p>Research and writing are done.</p>

                    <p>Let’s get into DELIVERY.</p>

                    <hr />

                    <p>Four P’s of Public Speaking:</p>

                    <p>Projection: Volume</p>

                    <p>Pace: Fast or Slow</p>

                    <p>Pitch: High or Low</p>

                    <p>Pauses: Short or Long. For laughs or to make a point?</p>

                    <br />

                    <br />

                    <h4>Step Three: Rehearse</h4>

                    <p>How do you get to Carnegie Hall? Practice.</p>

                    <p>
                      Public speaking looks easy and spontaneous when speakers
                      appear relaxed, in control and “in the moment.”
                      Experienced politicians get really good at it. Re-elected
                      officials can occasionally get away with little rehearsal.
                      You can’t. Let’s get you ready to get out there with your
                      message.
                    </p>

                    <br />

                    <p>Homework:</p>

                    <br />

                    <ul>
                      <li>
                        Practice with your family and friends. Choose people you
                        trust. Ask for feedback. It will be valuable. Listen to
                        the feedback.
                      </li>

                      <li>
                        Re-Write, Rehearse, Repeat. With different people.
                      </li>
                    </ul>

                    <br />

                    <p>
                      You’ve made adjustments to the words, found better words.
                      Or perhaps a trusted friend suggested a better “set-up” or
                      “punch line,” which is NOT offensive, and doesn’t “attack”
                      anyone. Teasing is relatively okay, and we use it a lot.
                      Be careful. Test the waters. Rehearsal and feedback on the
                      writing is a must.
                    </p>

                    <br />

                    <p>
                      <strong>Alert:</strong> Understand your racial bias and
                      get help to be sure you understand how your words might be
                      offensive. Never assume you understand what’s offensive.
                    </p>

                    <br />

                    <p>
                      <strong>Alert:</strong> Never step up to the microphone
                      unprepared. You get one shot with this crowd.
                    </p>

                    <br />

                    <p>
                      <strong>Alert:</strong> Never “test” new material on an
                      audience. There could be opponent supporters waiting for
                      you to trip up, fail, stutter, in any way appear
                      unprepared. For example, notice what has been said to
                      President Biden when he trips up on anything, or the
                      criticism about his age that affects his speech delivery.
                      The world is waiting for you to fail. So is your opponent.
                      The solution? Rehearse.
                    </p>

                    <br />

                    <p>
                      It’s a good thing the president knows how to handle
                      criticism. You’ll get better at it too.
                    </p>

                    <hr />

                    <h4>Step Four: Delivery, The Podium Awaits</h4>

                    <br />

                    <p>
                      You’ve got a date, and there’s the podium. You’ve got a
                      finished written speech, and you’ve rehearsed, polished
                      and timed it. You know which one to use for a five-minute
                      intro of another candidate (you will be asked, so be
                      prepared). You know which one to use when you have ten
                      minutes, and which one to use when you get twenty minutes.
                    </p>

                    <br />

                    <p>
                      You are ready. You feel good, a bit nervous. You’ve got a
                      date, and there’s the podium. People file in. You’re
                      shaking hands, greeting… and here come the nerves, the
                      excitement… stage fright. …What if I trip on the way up to
                      the podium? What if I….what if I, what if… This is a
                      killer. Don’t let it happen. Focus on success. Focus on
                      winning.
                    </p>

                    <br />

                    <p>
                      Check your fear reaction. It’s just stage fright. More on
                      adrenaline below.
                    </p>

                    <ul>
                      <li>Trust your preparation.</li>

                      <li>
                        Trust the words to deliver the message. It’s up to you
                        how to deliver the words.
                      </li>

                      <li>Good speech needs supported breathing.</li>

                      <li>
                        Good speech has good articulation, enunciation,
                        emphasis, pitch and volume.
                      </li>
                    </ul>

                    <br />

                    <p>VOICE, BODY AND NERVES</p>

                    <p>Giving a speech can be terrifying. But you are brave.</p>

                    <p>
                      You have a message to deliver and people want to hear you.
                      The more you practice, the better it gets. The more you
                      work on your delivery, the better and the more confident
                      you become.
                    </p>

                    <br />

                    <p>KNOW THE SYMPTOMS: BODY AWARENESS</p>

                    <ul>
                      <li>Listen to your body. Check your symptoms.</li>

                      <li>
                        The symptoms include increased heart rate, dry mouth,
                        shaking, shortness of breath and shallow breathing,
                        sudden sweating, thirst, and with severe stage fright,
                      </li>

                      <li>
                        Oxygen is key to getting your breath and heartbeat back
                        under control.
                      </li>

                      <li>
                        We naturally put up defenses. It is only your body
                        chemistry. Adrenaline is a naturally occurring hormone,
                        triggered anytime you feel the unknown about to thrust
                        you forward.
                      </li>

                      <li>
                        Your thoughts can turn it around. Find your happy place.
                      </li>

                      <li>
                        Step aside, go into a closet or the restroom and release
                        the tension in your arms, torso, and shake the entire
                        body, gently, with some deep breathing. Stand still.
                        Check your symptoms. Then, balance on one leg at a time.
                        Balancing changes what you have your mind focused on.
                        Also tapping around the eyes and sternum can be helpful.
                      </li>

                      <li>
                        Add humming. This helps get the breath under control.
                        Hum while tapping and shaking.
                      </li>
                    </ul>

                    <br />

                    <p>WARM UP: PHYSICAL</p>

                    <p>
                      About fifteen minutes before “Go Time,” step away. Think
                      about your body: fluids in or out. Yes, too much
                      adrenaline makes you want to go - yet again - to the
                      restroom. Good. Flush out that hormone. Breathe. Drink
                      more water. Blow air through your lips and do your{" "}
                      <a href="https://byjus.com/english/tongue-twisters/">
                        tongue twisters
                      </a>
                      . Good speech is about being understood. Stretch out your
                      face, stretch your tongue and do a few more tongue
                      twisters.
                    </p>

                    <br />

                    <p>WARM UP: VOCAL</p>

                    <p>Articulation:</p>

                    <p>
                      We do those tongue twisters to practice getting away from
                      the popping and hissing which is exaggerated on a
                      microphone. You’ve heard it: hissing and popping. This is
                      all about consonants. C, F, S, V, Z. Say them aloud. These
                      consonants push air while making the sound. Compare to B,
                      D,T, P. These consonants pop in the microphone. The
                      solution is to ease off on the poppers and hissers. Find
                      tongue twisters that work these consonants. Practice with
                      a microphone and learn how you can back off and eliminate
                      hissing ssss and popping ppppp.
                    </p>

                    <br />

                    <p>TECHNOLOGY</p>

                    <p>
                      Just because there is a microphone on a stand, at a
                      podium, don’t assume it is functioning properly or if
                      anyone has turned up the volume. You’ve seen it… the tap
                      tap tap. Yes, it’s on.
                    </p>

                    <br />

                    <p>Find your light. Avoid the shadows.</p>

                    <br />

                    <p>MICROPHONE BASICS</p>

                    <p>Leave it alone. It will pick up everything.</p>

                    <p>
                      <strong>Alert:</strong> Watch yourself around live
                      microphones.
                    </p>

                    <br />

                    <p>
                      <strong>Microphone placement:</strong> Practice!
                    </p>

                    <p>
                      Do an open mic at a club. You must get comfortable using
                      the microphone.
                    </p>

                    <ul>
                      <li>
                        Even with a microphone, you need to understand how far
                        away it is from your mouth. There is always an element
                        of projecting your voice, speaking up. Avoid speaking
                        softly or being timid. Even eight inches away from a
                        directional mic will impact what does and does not
                        amplify to the speaker system.
                      </li>

                      <li>
                        Keep the microphone one to two inches away. Don’t eat
                        the microphone: you’ve seen singers right on it.
                      </li>

                      <li>
                        Leave the microphone on the mic-stand. Anytime you touch
                        it, bump it, adjust the height, it will sound. Staying
                        put eliminates wandering, which can be seen as
                        nervousness. It also keeps both hands free. Gain
                        confidence with using microphones before taking it off
                        the mic-stand and walking through the crowd. When you do
                        walk with a mic, keep it steady in front of you, close
                        to your mouth. Avoid changing hands too often.
                      </li>

                      <li>
                        Place your cup of water on a stool or something nearby.
                        Keep your hands free.
                      </li>

                      <li>
                        Keep your hands off the sound cables and the mic-stand.
                        Your body electricity can distort the signals.
                      </li>
                    </ul>

                    <br />

                    <p>TAKE IT WITH YOU TO THE PODIUM: In Your Head</p>

                    <p>
                      Sure, your speech is on your tablet, or bulleted index
                      cards to help you stay on track. You know the material so
                      well and you are so prepared that you don’t really need to
                      watch the screen. You can get your face up to look at
                      people. Move from face-to-face. Use the pauses to look
                      down and get your bearings, or a sip of water.
                    </p>

                    <br />

                    <p>
                      <strong>Alert:</strong> Using your phone gets tricky.
                      Inevitably you will need to scroll. Speakers frequently
                      find themselves lost in their document. It can ruin the
                      moment, disconnect you from the audience, disrupt the
                      flow. Avoid electronic devices. Use index cards.
                    </p>

                    <br />

                    <br />

                    <h4>Helpful Public Speaking Links</h4>

                    <ul>
                      <li>
                        <a href="https://www.themuse.com/advice/public-speaking-tips">
                          Muse Advice
                        </a>
                        - 17 Public Speaking Tips
                      </li>

                      <li>
                        <a href="https://professional.dce.harvard.edu/blog/10-tips-for-improving-your-public-speaking-skills/">
                          TopTen Harvard
                        </a>
                        - 10 Tips for Improving Your Public Speaking Skills
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="client-section w-100" name="event-planning">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Event planning</h3>

                    <p>
                      By{" "}
                      <a href="http://hesterschellcreative.com/">
                        Hester Schell
                      </a>
                      , MFA
                    </p>
                  </div>

                  <div className="card-body p-3">
                    <p>
                      As a candidate, take the time to review and understand all
                      aspects of your responsibilities surrounding all things
                      events and campaign funding and donations. Understand what
                      is allowed and what is not, along with what is reported on
                      your campaign statements. These pages are not a complete
                      guide, but rather are simply the basics.
                    </p>

                    <br />

                    <h4>
                      Welcome to Event Producing: Pre-Production, Production,
                      Post Production
                    </h4>

                    <br />

                    <p>Pre-Production</p>

                    <p>
                      <strong>Pre-Event: Dates and Space</strong>
                    </p>

                    <ul>
                      <li>
                        Press Kit to All Media Outlets: send six weeks to a
                        month in advance
                      </li>

                      <li>Advertising budget</li>

                      <li>Word-of-Mouth</li>

                      <li>
                        Neighborhood online and offline bulletin boards: Post
                        one month to two weeks in advance
                      </li>

                      <li>Postcard mailers: Deliver one week prior.</li>
                    </ul>

                    <br />

                    <p>Production</p>

                    <p>
                      <strong>Event: Load In</strong>
                    </p>

                    <ul>
                      <li>
                        Silent Auction? Live auction? Fashion show? Music stage?
                        Food area? Beer?
                      </li>

                      <li>Parking. Restrooms. Security.</li>
                    </ul>

                    <br />

                    <p>Post Production</p>

                    <p>
                      <strong>Post Event: Load Out</strong>
                    </p>

                    <ul>
                      <li>Thank you cards to all donors and support teams.</li>

                      <li>
                        Financial breakdown on money raised against expenses.
                      </li>

                      <li>Final report.</li>
                    </ul>

                    <br />

                    <br />

                    <h4>Failure to Plan is a Plan to Fail</h4>

                    <br />

                    <p>
                      Communications, assignment of duties, responsibilities and
                      your leadership will determine whether your event is a
                      successful event. Murphy’s Law is real. In today’s world
                      of mass communication and automation, errors and omissions
                      occur with more frequency. Therefore, consistent follow-up
                      is essential.
                    </p>

                    <br />

                    <p>
                      Never hesitate to call a venue manager with an urgent
                      question. When phone messaging fails, nothing remedies
                      faster than a quick in-person drop-in. Always have your
                      team visit the venue and take photos. If you have a
                      security detail assigned to the location, have them do a
                      tour for risk assessment, especially exits.
                    </p>

                    <br />

                    <p>
                      <i>Due diligence</i> means you try to anticipate anywhere
                      Uncle Murphy is hiding. Everyone’s safety, security and
                      liability is up to the person who signs the rental
                      agreement. Take precautions.
                    </p>

                    <br />

                    <p>
                      <strong>Get it in writing</strong>: Review the fine print
                      of all contracts, especially when it comes to liability
                      and permitting. Understand what you are signing,
                      especially the “add-ons.”
                    </p>

                    <br />

                    <br />

                    <h4>The Basics- Who What When Where Why</h4>

                    <br />

                    <p>
                      <strong>Why:</strong> A mission statement will contain the
                      parts of a marketing plan. Why would anyone want to come?
                      Be specific.
                    </p>

                    <hr />

                    <p>
                      <strong>Who:</strong>
                    </p>

                    <ul>
                      <li>The Candidate(s) and staff</li>

                      <li>The Audience: seating totals</li>

                      <li>The Event Manager(s)</li>

                      <li>Catering and Staff</li>

                      <li>Musicians, Artists?</li>

                      <li>Child Care Provided?</li>
                    </ul>

                    <br />

                    <p>
                      <strong>What TYPE of EVENT</strong>
                    </p>

                    <p>
                      Full assessment of particular needs: food prep,
                      electrical, capacity, head count.
                    </p>

                    <p>
                      How large a space is determined by your sales, sign-ups
                      and crowd expectations.
                    </p>

                    <br />

                    <p>
                      Whichever event profile applies will determine what is
                      required from the type of contract you must sign, how much
                      liability insurance, deposits against damages, type of
                      event insurance to how much backup generator power to run,
                      how many refrigeration units for how many campers at the
                      music festival.
                    </p>

                    <br />

                    <p>Private property</p>

                    <ul>
                      <li>Private property, open to the public</li>

                      <li>Private property, private guest list.</li>

                      <li>Private property FOR HIRE, open to the public.</li>

                      <li>Private property FOR HIRE, private guest list.</li>
                    </ul>

                    <br />

                    <p>Public Property</p>

                    <ul>
                      <li>
                        <p>Public Property, open to the public.</p>

                        <p>
                          Public Property, City, County, State Owned Facilities
                          and Parks
                        </p>

                        <ul>
                          <li>
                            By the hour, week. Some on site staff provided, some
                            required.
                          </li>

                          <li>
                            Permitted and insured, scheduled. Indoor and
                            Outdoor.
                          </li>

                          <li>
                            Alcohol permits, restrictions and/or limitations.
                            Insurance requirements.
                          </li>
                        </ul>
                      </li>

                      <li>
                        Public property, private guest list. Free. FCFS
                        (first-come, first-served)
                      </li>

                      <li>
                        Public property FOR HIRE, open to the public. (reserved:
                        a ballroom at a national park to the basketball court in
                        your city-run parks department.)
                      </li>

                      <li>
                        Public property FOR HIRE, private guest list. (reserved:
                        wedding in a national park, lodge or the softball field
                        every Thursday from May to end of June in your
                        neighborhood.)
                      </li>
                    </ul>

                    <br />

                    <br />

                    <h4>Staffing Events</h4>

                    <br />

                    <p>Resources: Volunteer sign-ups with specific tasks.</p>

                    <p>
                      Do a “basic skills assessment” during volunteer intake.
                    </p>

                    <p>Research: caterers and event producers.</p>

                    <p>
                      Co-Sponsor: Partner with a non-profit for a percentage of
                      any proceeds.
                    </p>

                    <br />

                    <p>
                      The places to find experienced professionals who know how
                      to do this - how to produce an event - are theater
                      workers, film and touring road crews. In the film
                      industry, they are called “location managers” and “unit
                      production managers.” Theatrical producers and stage
                      managers know just about everything about logistics for
                      live events. Anyone with experience booking bands in a bar
                      would easily pick up the necessary skills to help you plan
                      events. Be sure to check references and look for
                      verification of prior contracts. Ask Uncle Murphy if
                      you’ve missed anything. He’ll find it for you.
                    </p>

                    <br />

                    <p>
                      <strong>When</strong> Seasonal. Work with the established
                      holidays. (Halloween Fundraiser, May Day Rally for
                      Workers, International Poets Day.) Build a theme around
                      historical figures relative to your campaign platform. Wet
                      or Dry Season? Book parks with sheltered picnic areas.
                    </p>

                    <br />

                    <p>
                      <i>Morning, Noon or Night</i> - Breakfast Stumping with
                      the Rotary? Lions Club? Knights of Columbus? Elks? Brown
                      Bag Lunch with the Candidate, local park. Sit-down, 10K
                      per table black-tie in a ballroom with Pink Martini.
                    </p>

                    <br />

                    <p>
                      Book In Advance: All venues have managers. Some calendars
                      are online. Plan months ahead.
                    </p>

                    <br />

                    <p>
                      Public Parks are reserved through a community’s parks and
                      recreation departments.
                    </p>

                    <br />

                    <p>
                      <strong>Where</strong>
                    </p>

                    <p>Event Space: Indoors or Outdoors. Day or Night?</p>

                    <p>
                      Outdoors: All you need is hay bales and a field or a step
                      stool in a park. And another field for parking.
                    </p>

                    <br />

                    <p>
                      Indoors: The bigger the ballroom, the higher the price
                      tag. Hotel packages include assorted levels of staffing.
                    </p>

                    <br />

                    <p>Whatever the space, distinguish its functions:</p>

                    <ul>
                      <li>Seating, Staging.</li>

                      <li>Backstage, Onstage.</li>

                      <li>Equipment/audio gear set-up.</li>

                      <li>
                        Press/Media table: clipboards for volunteer sign-ups,
                        printed campaign materials, buttons, printed platform
                        bullets
                      </li>
                    </ul>

                    <br />

                    <p>
                      Stumping outdoors? Get a decent portable microphone
                      amplifier. Compare model specifics for reach distance.
                    </p>

                    <br />

                    <p>
                      Bars and Taverns frequently have a party space by
                      reservation for free and rental.
                    </p>

                    <ul>
                      <li>
                        You cover the wages of the bartenders. Sometimes there
                        is a set-up fee.
                      </li>

                      <li>
                        Free comes when you can guarantee a level of alcohol
                        sales. There can be a minimum spent before any benefits
                        kick in.
                      </li>
                    </ul>

                    <br />

                    <p>Must haves:</p>

                    <ul>
                      <li>Access to electricity or a generator.</li>

                      <li>Access to restrooms.</li>

                      <li>Access to drinking water.</li>

                      <li>Clear entry and exits. Parking.</li>
                    </ul>

                    <br />

                    <p>
                      Nice to have: Coffee. Snacks. Merchandise. Clipboards for
                      sign-ups.
                    </p>

                    <br />

                    <p>
                      Leave home: Alcohol. If you are permitting through a
                      city’s parks department, it is likely alcohol will be
                      prohibited.
                    </p>

                    <br />

                    <p>
                      Don’t forget: Donation bucket or basket, or a hat to pass.
                      Printed materials for distribution.
                    </p>

                    <br />

                    <br />

                    <h4>Keys to Success</h4>

                    <p>
                      Avoiding Traps- Never underestimate how easily lack of
                      planning turns into utter chaos.
                    </p>

                    <br />

                    <p>SOLUTION: PREPARATION AND PLANNING.</p>

                    <ul>
                      <li>
                        {" "}
                        <p>Yes, you most likely need:</p>
                        <ul>
                          <li>An event manager/producer/stage manager</li>

                          <li>A permit: Start at City Hall</li>

                          <li>
                            Event insurance: Start with your own auto/home
                            insurance provider. Riders on an existing policy can
                            be quite reasonable when available.
                          </li>

                          <li>
                            Check with the venue operator whether you are
                            covered via rental agreement.
                          </li>
                        </ul>
                      </li>

                      <li>Venues book many months in advance.</li>

                      <li>Persistence Matters.</li>

                      <li>
                        Don’t take it personally. Every event venue is
                        understaffed. Plan accordingly.
                      </li>

                      <li>
                        Safety and Security are top priorities. For large
                        events, alert the local firehouse and police department.
                        Depending on your head count estimate on any permits
                        issued by a municipality, the police will likely know
                        about it. It’s good to let them know directly.
                      </li>

                      <li>
                        Follow the rules of whatever event location you find
                        yourself.
                      </li>

                      <li>
                        Equipment rental generally requires proof of insurance
                        receipt and a policy number.
                      </li>

                      <li>
                        Figure out the parking and drop off/pick up for full
                        accessibility. Include it in the press release,
                        invitations and all marketing efforts.
                      </li>

                      <li>Sign language interpreters?</li>

                      <li>
                        <p>
                          Land acknowledgements are more prevalent. Find yours.
                          Respect. For Clackamas County, check with the
                          Grand-Ronde Tribal Council. There are maps and
                          overlays to see which tribe to name:
                        </p>

                        <ul>
                          <li>
                            <a href="https://www.npr.org/2022/10/10/1127837659/native-land-map-ancestral-tribal-lands-worldwide">
                              Whose Land Are We On?
                            </a>
                          </li>

                          <li>
                            <a href="https://nativegov.org/news/a-guide-to-indigenous-land-acknowledgment/">
                              Native Governance Center
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li>
                        VIP section? Increased security? Preferred Seating?
                        Donor/Candidate meet and greet?
                      </li>

                      <li>
                        Credentialed Press: Alert each editor so there is time
                        to make a reporter assignment to your event.
                      </li>

                      <li>Have an emergency plan.</li>

                      <li>
                        Have a system to report suspicious activity. Do not
                        underestimate the ability of one unhappy or angry person
                        in the crowd to make life unpleasant for everyone. Do
                        not underestimate the need for hyper vigilance with
                        checking backpacks before entry, or not allowing glass
                        bottles.
                      </li>

                      <li>
                        Use Walkie-Talkie and headsets when you have longer
                        distances to get from the holding tank to the stage.
                      </li>
                    </ul>

                    <br />

                    <p>
                      <strong>Alert:</strong> Insufficient staffing leads to
                      chaos. The event manager is responsible to avoid chaos at
                      all times by keeping things under control by anticipating
                      problems. Anyone on your team who impedes the schedule and
                      tasks assigned will naturally contribute to chaos.
                    </p>

                    <br />

                    <p>
                      <strong>Alert:</strong> Have a fast-action exit plan. In
                      the event of compromised security or any need for a quick
                      exit out the back, plan accordingly with where you park
                      VIP vehicles.
                    </p>

                    <br />

                    <br />

                    <h4>Clackamas County Resources</h4>

                    <ul>
                      <li>
                        <a href="https://www.clackamas.edu/campus-life/campuses-buildings-hours/events-and-conference-services">
                          Clackamas Community College: Events and Conference
                          Center
                        </a>
                      </li>

                      <li>
                        <a href="https://dochub.clackamas.us/documents/drupal/56e13951-2d99-4c9d-8460-a481a7b0a1ed">
                          Clackamas County Parks Department,
                        </a>{" "}
                        Special Use Events
                      </li>

                      <li>
                        <a href="https://www.yelp.com/search?cflt=eventservices&find_loc=Clackamas+County%2C+OR">
                          Ten Best Event Centers on YELP
                        </a>
                      </li>
                    </ul>

                    <br />

                    <h4>Ideas for Affordable Event Space</h4>

                    <ul>
                      <li>Local Schools</li>

                      <li>Local Churches, Temples</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="client-section w-100" name="ngp-van">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>How to use NGP VAN</h3>
                  </div>

                  <div className="card-body p-3">
                    <h4>What is NGP VAN?</h4>

                    <p>
                      VAN (short for Voter Action Network) is a database of
                      voter information. In Oregon, it’s maintained by the
                      Democratic Party of Oregon (DPO.) All voters in the state
                      have a record in VAN. The information in VAN is crucial to
                      campaigns.
                    </p>

                    <br />

                    <br />

                    <h4>How to get access to NGP VAN</h4>

                    <p>
                      To get access to VAN, send an email to the{" "}
                      <a href="chair@clackamasdems.org">
                        chair@clackamasdems.org
                      </a>{" "}
                      and fill out the{" "}
                      <a href="https://dpo.org/candidate/">DPO VAN Agreement</a>
                      . The fee for VAN access for candidates is $50. The DPO
                      provides VAN access only to candidates, not for ballot
                      measure campaigns.
                    </p>

                    <br />

                    <br />

                    <h4>What you can do with the information in NGP VAN</h4>

                    <p>VAN has many functions. Using VAN you can:</p>

                    <ul>
                      <li>
                        Generate lists of voters that meet your criteria (such
                        as frequent voters, voters of a certain age, voters in a
                        particular area, etc.) for use in GOTV campaigns
                      </li>

                      <li>Create walk lists</li>

                      <li>Track information on voter contacts</li>

                      <li>Create email and phone bank campaigns</li>

                      <li>Create and manage events</li>

                      <li>Record results of canvasses</li>

                      <li>Record answers to surveys</li>
                    </ul>

                    <br />

                    <br />

                    <h4>Learn more about using NGP VAN</h4>

                    <p>
                      The DPO maintains{" "}
                      <a href="https://wp.dpo.org/training-materials/">
                        a training site for VAN
                      </a>
                      . (The password is “democrats”.) The Texas Democrats have
                      a{" "}
                      <a href="https://docs.google.com/document/d/1gZgTLRvtlxrO-9w1sc1Tc-ED2eFwvkvO1SB_5g5BS1s/edit">
                        useful VAN manual
                      </a>
                      . NGP VAN, the company behind VAN, also offers a{" "}
                      <a href="https://go.ngpvan.com/van-training-manual">
                        training manual
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="client-section w-100" name="candidate-resources">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Candidate resources</h3>
                  </div>

                  <div className="card-body p-3">
                    <p>
                      Get{" "}
                      <Link to="/candidate-portal/candidate-resources">
                        candidate resources for running for office
                      </Link>{" "}
                      including:
                    </p>

                    <ul>
                      <li>Candidate Statements</li>

                      <li>Campaign Finance Information</li>

                      <li>Election Resources</li>

                      <li>Help and Training for First-Time Candidates</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
}

export default CandidateTraining;
