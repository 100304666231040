import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Blogsidebar";

import { getArticles } from "../../../data/articles";
class Content extends Component {
  state = {
    articles: [],
  };

  componentDidMount() {
    (async () => {
      const arts = await getArticles();
      this.setState({
        ...this.state,
        articles: arts,
      });
    })();
  }

  render() {
    return (
      <section className="blog-section section-gap-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {/* Blog loop(Standard) */}
              <div className="blog-loop standard-blog row">
                {/* Single Post */}
                {this.state.articles.map((item, i) => {
                  return (
                    <div
                      key={i + item?.title?.split(" ").join("-")}
                      className="col-12"
                      style={{
                        // boxShadow: "0px 0px 10px #ccc",
                        border: "2px solid #eaeaea",
                        padding: "36px 14px",
                        marginBottom: "24px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="single-post-box" style={{ margin: "0" }}>
                        {item.img?.length > 0 && (
                          <div className="post-thumb">
                            <img
                              src={item.img}
                              alt={item.imgName ? item.imgName : ""}
                            />
                          </div>
                        )}
                        <div className="post-meta">
                          <ul style={{ margin: "0" }}>
                            <li>
                              <i className="far fa-calendar-alt" />
                              {item?.externalLink?.length > 0 ? (
                                <a href={item.externalLink}>{item.postdate}</a>
                              ) : (
                                <Link to={"/blog-posts/" + item.endpoint}>
                                  {item.postdate}
                                </Link>
                              )}
                            </li>
                            <li>
                              {item.author?.length > 0 && (
                                <>
                                  <i className="far fa-user" />
                                  <span>{item.author}</span>
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="post-content">
                          <h3 className="title">
                            {item.externalLink?.length > 0 ? (
                              <a href={item.externalLink}>{item.title}</a>
                            ) : (
                              <Link to={"/blog-posts/" + item.endpoint}>
                                {item.title}
                              </Link>
                            )}
                          </h3>
                          <p>{item.caption}</p>
                          {item.externalLink?.length > 0 ? (
                            <a
                              href={"/blog-posts/" + item.externalLink}
                              className="btn btn-primary mt-3"
                            >
                              {item.urltext || "Go to Article"}
                            </a>
                          ) : (
                            <Link
                              to={"/blog-posts/" + item.endpoint}
                              className="btn btn-primary mt-3"
                            >
                              {item.urltext || "Read More"}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Pagination
              <Pagination /> */}
            </div>
            <div className="col-lg-4 col-md-8">
              {/* sidebar */}
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Content;
