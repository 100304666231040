import React, { Component, Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { useLocation, Link } from "react-router-dom";

function OtherWaysToDonate() {
  const location = useLocation();

  const pageTitle = "Get Involved | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Learn more about Democratic Party of Clackamas County volunteer roles. Support local candidates and further party goals by volunteering with us."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Other Ways to Donate" }} />
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>Donate Time</h3>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        I want to help
                      </a>{" "}
                      and I'm not sure what fits my time and interest
                    </li>
                    <li>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        I want to increase election turnout
                      </a>{" "}
                      of Democrat voters in my neighborhood
                    </li>
                    <li>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        I want to represent my Precinct
                      </a>{" "}
                      as a Precinct Committee Person (PCP)
                    </li>
                    <li>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        I want to tell you something;
                      </a>{" "}
                      I have feedback for you
                    </li>
                  </ul>
                </div>
                <div className="card-header">
                  <h3>Donate Money</h3>
                </div>
                <div className="card-body">
                  <p>
                    <Link to="/donate">I want to support</Link> the work of the
                    Democrat Party of Clackamas County by giving money or items.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OtherWaysToDonate;
