import React from "react";

import illustration from "../../../../assets/img/illustration/pexels-cottonbro-4669141.jpg";
import { Link } from "react-router-dom";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        {window.innerWidth > 991 && (
          <div style={{ maxWidth: window.innerWidth < 1050 ? "450px" : "500px" }} className="illustration-img">
            <img src={illustration} alt="" />
          </div>
        )}
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">About Us</span>
                <h2 className="title">
                  Clackamas County <br /> Oregon <br /> Democrats{" "}
                </h2>
              </div>
              <p className="mb-25">
                The Clackamas County Democratic Party carries out the goals of
                the national, state and county Democratic parties while
                developing, maintaining and electing Democrats to enact laws
                reflecting those values for the greater good.
              </p>
              <p style={{ marginBottom: "24px" }}>
                Get involved with the Clackamas County Democratic Party today.
              </p>
              {/* <ul className="about-list">
                <li>
                  {" "}
                  <i className="far fa-check" /> Lorem ipsum dolor sit amet.
                  &amp; Lorem ipsum dolor sit amet consectetur.
                </li>
                <li>
                  {" "}
                  <i className="far fa-check" /> Lorem ipsum, dolor sit amet
                  consectetur adipisicing.
                </li>
              </ul> */}
              <Link to="/our-mission" className="main-btn">
                Our Mission
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
