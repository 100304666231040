import React from "react";
import { Link } from "react-router-dom";

import img1 from "../../../../assets/img/feature/event.jpg";
import img2 from "../../../../assets/img/feature/resources.jpg";
import img3 from "../../../../assets/img/feature/contacts.jpg";

const featuresblock = [
  {
    img: img1,
    title: "Events",
    text: "Upcoming Events Information",
    link: "/events-calendar",
  },
  {
    img: img2,
    title: "Resources",
    text: "DPCC Resources",
    link: "/contacts-and-organization",
  },
  {
    img: img3,
    title: "Contacts",
    text: "Drop us a Line ",
    link: "/contacts-and-organization",
  },
];

function Features(props) {
  return (
    <section className="feature-section section-gap">
      <div className="container">
        <div className="section-title text-center both-border mb-50">
          <span className="title-tag"> Our Goals </span>
          <h2 className="title">
            Together We Can Lead Democrats <br />
            To Victory in Clackamas County!
          </h2>
        </div>
        {/* Feature boxes */}
        <div className="feature-boxes row justify-content-center">
          {featuresblock.map((item, i) => (
            <div key={i} className="col-lg-4 col-md-6 col-10 col-tiny-12">
              <div className="feature-box">
                <div
                  className="feature-bg bg-img-c"
                  style={{ backgroundImage: "url(" + item.img + ")" }}
                ></div>
                <div className="feature-desc">
                  <Link to={item.link} className="feature-link">
                    <i className="fal fa-long-arrow-right" />
                  </Link>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
