import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import { Link, useLocation } from "react-router-dom";

function Documentation() {
  const location = useLocation();

  const pageTitle = "Important Documents | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County platform, PCP application and more. Get to know us by reading the DPCC bylaws, standing rules and the PCP handbook."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Documentation" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">Documentation and Resources</div>
                <div className="card-body">
                  <p> Here’s documentation that might be helpful.</p>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.google.com/url?q=https://drive.google.com/file/d/11amTEtBeTxcsNX9e-k1Pha8hc5jUuKzP/view?usp%3Dsharing&sa=D&ust=1600280479115000&usg=AFQjCNHSU_JTrXqvC2vFsDpvzdukIG3yaw"
                      >
                        Precinct Committee Person Application
                      </a>
                    </li>
                    <li>
                      <a href="/#/platform">Platform</a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://drive.google.com/file/d/1TtniXT5-iGesKyiVnwJjqo1ywUl16sfV/view?usp=sharing"
                      >
                        PCP Handbook (PDF)
                      </a>
                    </li>
                    <li>
                      In-Kind Donation Documentation for items such as postage,
                      envelopes used for party reasons
                    </li>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLScCzOspsFCnVFgpz3v9j_A2f2UwitH9oMr1mPx0f0iQBv8c9w/viewform"
                        >
                          Online Reporting Link
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.google.com/url?q=https://drive.google.com/file/d/1QrBi6-wyHYj62PMvH9yUnoJQKSnVpj96/view?usp%3Dsharing&sa=D&source=editors&ust=1615335428168000&usg=AFQjCNGBgyGqn5U35LRDdUzIus32zkbtbw"
                        >
                          In-Kind Contribution Form
                        </a>
                      </li>
                    </ul>
                  </ul>
                  <br />
                  <p>
                    Additionally, you can find posts that have been tagged
                    specifically about documentation in the list below:
                  </p>
                  <ul>
                    <li>
                      <p>Party Documentation</p>
                    </li>
                    <ul>
                      <li>
                        <Link target="_blank" to="/dpcc-by-laws">
                          Bylaws
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          target="_blank"
                          to="/documentation/central-cmte-minutes"
                        >
                          Central Committee Minutes
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="/documentation/other-cmte-minutes"
                        >
                          Other Committee Minutes
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" to="/documentation/resolutions">
                          Resolutions
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                          target="_blank"
                          to="/special-rules-of-order"
                        >
                          Special Rules of Order
                        </Link>
                      </li> */}
                      <li>
                        <Link target="_blank" to="/standing-rules">
                          Standing Rules
                        </Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Documentation;
