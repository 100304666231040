import React from "react";
import * as Scroll from "react-scroll";

function PointList({ points }) {
  return (
    <ul>
      {points.map((point, index) => (
        <li key={index} style={{ cursor: "pointer" }}>
          {
            // might have an href, might have a scroll target, if href, use a, if scroll target, use Link
            point.href ? (
              <a href={point.href}>{point.text}</a>
            ) : point.scrollTarget ? (
              <Scroll.Link
                to={point.scrollTarget}
                spy={true}
                smooth={true}
                duration={500}
              >
                {point.text}
              </Scroll.Link>
            ) : (
              point.text
            )
          }
        </li>
      ))}
    </ul>
  );
}

export default PointList;
