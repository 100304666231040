import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import PointList from "../../ui/PointList/PointList";

function GettingReadyToRun() {
  const location = useLocation();

  const pageTitle = "Getting Ready to Run | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="First-time candidates running for office in Clackamas County, start here. Getting ready to run? Find forms, important dates, training, resources and more."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Getting Ready to Run" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
        }}
      >
        <PointList
          points={[
            {
              text: "Qualifications for Running for Office in Clackamas County",
              scrollTarget: "qualifications",
            },
            {
              text: "Stepping Stones to Elected Positions",
              scrollTarget: "stepping-stones",
            },
            {
              text: "How to File Locally",
              scrollTarget: "how-to-file",
            },
            {
              text: "Should You Run for Office?",
              scrollTarget: "should-you-run",
            },
            {
              text: "Thinking of Running for Office in Clackamas County? Ways We Can Help.",
              scrollTarget: "ways-we-can-help",
            },
            {
              text: "Candidate Resources",
              scrollTarget: "candidate-resources",
            },
          ]}
        />
        <section className="client-section w-100" name="qualifications">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      Qualifications for Running for Office in Clackamas County
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>To run for office in Clackamas County, you must be:</p>
                    <ul>
                      <li>An Oregon resident</li>
                      <li>A U.S. citizen</li>
                      <li>A registered voter</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="stepping-stones">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      Stepping Stones to Elected Positions - Volunteer for a
                      Committee
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      If you’re considering running for an elected position with
                      an organization, consider first learning about the
                      organization by volunteering for a working committee and
                      be appointed by the city council, school board or the
                      county to one of those committees.
                    </p>
                    <br />
                    <p>
                      You will learn more about the elected’s role in the local
                      government entity, whether it’s a city government or a
                      local special district. You also will have an opportunity
                      to get to know people within that entity and learn
                      background information on the workings of the entity.
                    </p>
                    <br />
                    <p>
                      Cities have a number of working committees that the city
                      council can appoint. These are some of the committees—they
                      will vary from city to city:
                    </p>
                    <ul>
                      <li>
                        Planning Commission – learning the ordinances, approval
                        of land use transactions, etc.
                      </li>
                      <li>
                        Budget Committee – working with city finances within the
                        budget structure
                      </li>
                      <li>
                        Economic Development Advisory Committee – learn about
                        local businesses and opportunities
                      </li>
                      <li>Library Board or Advisory Committee</li>
                      <li>Transit Advisory Committee</li>
                    </ul>
                    <br />
                    <p>School districts have a limited number of committees:</p>
                    <ul>
                      <li>
                        Budget Committee is the primary school board committee
                        that can give you a step up to learn about the
                        district’s programs
                      </li>
                      <li>Education Foundation</li>
                      <li>Parent Leadership Advisory Committee</li>
                      <li>School Site Committees (by location)</li>
                    </ul>
                    <br />
                    <p>
                      Other Special Districts also have committees. You would
                      need to contact the district or seek information on their
                      website.
                    </p>
                    <br />
                    <p>
                      Clackamas County has a number of committees for which
                      people can volunteer, and then the county commissioners
                      will appoint people to those committees when openings
                      occur. Openings are posted on the website and also
                      noticed. You can ask to be on the notice list.
                    </p>
                    <br />
                    <h4>Clackamas County Committees, Councils and Boards</h4>
                    <p>
                      Learn more about the{" "}
                      <a href="https://www.clackamas.us/community/abc">
                        advisory boards, committees and councils
                      </a>{" "}
                      listed below.
                    </p>
                    <ul>
                      <li>Aging Services Advisory Council</li>
                      <li>Audit Committee</li>
                      <li>Board of Property Tax Appeals</li>
                      <li>Budget Committee</li>
                      <li>CCSD#5 Budget Committee - Street Lighting</li>
                      <li>Clackamas County Library Advisory Board</li>
                      <li>Clackamas Workforce Partnership</li>
                      <li>Committee for Community Involvement</li>
                      <li>Community Action Board</li>
                      <li>Community Health Council</li>
                      <li>Community Road Fund Advisory Committee</li>
                      <li>Compensation Board for Elected Officials</li>
                      <li>Design Review Committee</li>
                      <li>Development Agency Budget Committee</li>
                      <li>Developmental Disabilities Council</li>
                      <li>Emergency Medical Services Council</li>
                    </ul>
                    <br />
                    <p>
                      Each of the entities with committees will have application
                      forms and some description of the committee’s work and
                      usually a list of committee members.
                    </p>
                    <br />
                    <h4>Descriptions of Boards and What They Do</h4>
                    <ul>
                      <li>
                        <a href="https://docs.google.com/document/d/18kC3cE_o74Y1CHs4OvWhoIYJHmG3niWs/edit?usp=sharing&ouid=111728020164396234910&rtpof=true&sd=true">
                          Duties and Responsibilities of the Conservation
                          District Board
                        </a>
                      </li>
                      <li>
                        <a href="https://docs.google.com/document/d/1fH2dIiM-RK9MPeNrS0YlzUb_Lez3tK1i/edit?usp=sharing&ouid=111728020164396234910&rtpof=true&sd=true">
                          Fire District Board of Directors
                        </a>
                      </li>
                      <li>
                        <a href="https://drive.google.com/file/d/1NIHygDHh6T0WnLYxIm9_K-IwcNzNJ8Aa/view?usp=share_link">
                          Overview of Boards, Commissions, & Small Entities
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="how-to-file">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>How to File Locally</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      For U.S. and state-wide positions: the{" "}
                      <a href="https://sos.oregon.gov/elections/Documents/statecandidates.pdf">
                        State Candidate Manual{" "}
                      </a>{" "}
                      says: "The qualifications for public offices differ
                      depending on the office. Before filing, the candidate
                      should review the constitutional and statutory
                      requirements for the office sought."
                    </p>
                    <ul>
                      <li>
                        You can{" "}
                        <a href="https://sos.oregon.gov/elections/Documents/SEL101.pdf">
                          file to run
                        </a>{" "}
                        for office using the linked form.
                      </li>
                      <li>
                        Check out current and upcoming{" "}
                        <a href="https://www.clackamas.us/elections">
                          elections in Clackamas County
                        </a>
                        .
                      </li>
                      <li>
                        Qualifications for most state offices on pages 8 and 18
                        in the{" "}
                        <a href="https://sos.oregon.gov/elections/Documents/statecandidates.pdf">
                          state candidate manual
                        </a>
                        .
                      </li>
                      <li>
                        Qualifications to hold office in{" "}
                        <a href="https://dochub.clackamas.us/documents/drupal/291a6e47-9afe-452f-91cf-7681284fc9bd">
                          special districts
                        </a>
                      </li>
                      <li>
                        For more information about how to file locally, check
                        out the{" "}
                        <Link to="/candidate-portal/candidate-resources">
                          candidate resources
                        </Link>
                        .
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="should-you-run">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Should you Run for Office?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      Read interviews from elected officials and past candidates
                      and{" "}
                      <Link to="/candidate-portal/should-you-run">
                        learn more
                      </Link>{" "}
                      about what running for office—and serving—is really like.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="ways-we-can-help">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      Thinking of Running for Office in Clackamas County? Ways
                      We Can Help.
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      The upcoming special elections are extremely important to
                      all Oregonians. Candidates for school boards, fire
                      district boards, water district boards, and other
                      community boards will be elected on May 16, and winners
                      will shape the everyday lives of all of us who reside
                      and/or work in Clackamas County.
                    </p>
                    <br />
                    <p>
                      We need committed, civic-minded Democrats in Clackamas
                      County to fill these open positions. Is that you? If so,
                      we can help!
                    </p>
                    <br />
                    <p>
                      Important deadlines and other dates, candidate filing
                      forms and additional information can be found on the
                      <a href="https://www.clackamas.us/elections/candidate-filing-information">
                        Candidate Filing Information
                      </a>
                      . The webpage also lists which board positions are open
                      and in which towns or areas.
                    </p>
                    <br />
                    <p>
                      Once you file, please contact us to let us know. If you
                      decide it would be helpful, Clackamas County Democrats
                      can:
                    </p>
                    <ol>
                      <li>
                        Endorse your candidacy through our House District
                        Leaders
                      </li>
                      <li>
                        Promote your candidacy on our website and social media
                        channels
                      </li>
                      <li>
                        Feature your candidacy in our Monthly Update, sent to
                        DPCC members
                      </li>
                      <li>
                        Include your candidacy on DPCC slate cards distributed
                        by our Neighborhood Leaders
                      </li>
                      <li>
                        Invite you to speak at one of our monthly DPCC meetings
                        (third Thursday of each month) and/or a House District
                        meeting - this is a great way to recruit volunteers for
                        your campaign!
                      </li>
                      <li>
                        Invite you to apply for our{" "}
                        <a href="https://www.clackamasdems.org/#/blog-posts/jill-thorn-grant">
                          Jill Thorn grant
                        </a>
                        , offered annually and awarded to first-time, female
                        candidates
                      </li>
                      <li>
                        Post blogs on our website that emphasize the importance
                        of the board position you’re hoping to fill and explain
                        why you would be an excellent board member
                      </li>
                      <li>
                        Important links and training on our clackamasdems.org
                        Candidate Portal
                      </li>
                      <li>Access to NGP VAN, the voter database</li>
                      <li>
                        Work/meeting space at our downtown Oregon City office
                      </li>
                      <li>
                        Research and statistics like this{" "}
                        <a href="https://drive.google.com/file/d/19XLeA567BU0Y8k8ghanQ8dxqeVU32kO_/view?usp=share_link">
                          school board races analysis
                        </a>
                        .
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="candidate-resources">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Candidate resources</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      Get{" "}
                      <Link to="/candidate-portal/candidate-resources">
                        candidate resources for running for office
                      </Link>{" "}
                      including:
                    </p>
                    <ul>
                      <li>Oregon State Candidate Help</li>
                      <li>Candidate Statements</li>
                      <li>Campaign Finance Information</li>
                      <li>Election Resources</li>
                      <li>Help and Training for First-Time Candidates</li>
                      <li>Running for a School Board</li>
                      <li>Using NGP VAN</li>
                      <li>Voting trends (analyze past elections)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}

export default GettingReadyToRun;
