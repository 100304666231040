import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";

import ParliamentaryProcedurePDF from "../../assets/files/Parliamentary-Procedure.pdf";
import { useLocation } from "react-router-dom";

function StandingRules() {
  const location = useLocation();

  const pageTitle = "Parliamentary Procedure | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="The Democratic Party of Clackamas County follows parliamentary procedure. Read about the time-tested process for running orderly meetings."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Parliamentary Procedure" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-center">
                    Download DPCC Parliamentary Procedures
                  </h4>
                </div>
                <div className="card-body p-3">
                  <a href={ParliamentaryProcedurePDF} download>
                    Download PDF file
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3 className="text-center">
                    Democratic Party of Clackamas County
                  </h3>
                  <h4 className="text-center">Parliamentary Procedure</h4>
                </div>
                <div className="card-body p-3">
                  <h4 className="text-center mb-4">Priority Motions</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>You Want To:</th>
                        <th>You Say:</th>
                        <th>Interrupt?</th>
                        <th>Second?</th>
                        <th>Debate?</th>
                        <th>Amend?</th>
                        <th>Vote</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Close the Meeting</td>
                        <td>
                          I move to <b>adjourn</b>
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>Majority</td>
                      </tr>
                      <tr>
                        <td>Take a break</td>
                        <td>
                          I move to <b>recess for</b>...
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>Majority</td>
                      </tr>
                      <tr>
                        <td>Register a Complaint</td>
                        <td>
                          I raise to a <b>question of personal complaint</b>
                        </td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                        <td>None</td>
                      </tr>
                      <tr>
                        <td>Close Debate</td>
                        <td>
                          I move the <b>previous question</b>
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>2/3</td>
                      </tr>
                      <tr>
                        <td>Limit or Extend Debate</td>
                        <td>
                          I move that <b>debate be limited or extended to</b>...
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>2/3</td>
                      </tr>
                      <tr>
                        <td>Postpone to a Certain Time</td>
                        <td>
                          I move to <b>postpone the motion to</b>...
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Majority</td>
                      </tr>
                      <tr>
                        <td>Modify Wording of Motion</td>
                        <td>
                          I move to <b>amend</b> the motion by...
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Majority</td>
                      </tr>
                      <tr>
                        <td>Bring Business Before Assembly (a Main Motion)</td>
                        <td>I move that(or to)...</td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Majority</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="text-center mb-4 mt-4">Priority Motions</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>You Want To:</th>
                        <th>You Say:</th>
                        <th>Interrupt?</th>
                        <th>Second?</th>
                        <th>Debate?</th>
                        <th>Amend?</th>
                        <th>Vote</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Enfore Rules</td>
                        <td>
                          <b>Point of Order</b>
                        </td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                        <td>None</td>
                      </tr>
                      <tr>
                        <td>Appeal a Ruling</td>
                        <td>
                          I <b>appeal</b> the decision of the chair
                        </td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Varies</td>
                        <td>No</td>
                        <td>None</td>
                      </tr>
                      <tr>
                        <td>Suspend the Rules</td>
                        <td>
                          I move to <b>suspend the rules</b> for the purpose
                          of...
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>2/3</td>
                      </tr>
                      <tr>
                        <td>Split the Motion into two or More Parts</td>
                        <td>
                          I move to <b>divide the motion</b>
                        </td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>Majority</td>
                      </tr>
                      <tr>
                        <td>Demand a Counted Vote</td>
                        <td>
                          <b>Division</b>
                        </td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                        <td>None</td>
                      </tr>
                      <tr>
                        <td>Question Parliamentary Procedure</td>
                        <td>
                          <b>Parliamentary inquiry</b>
                        </td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                        <td>None</td>
                      </tr>
                      <tr>
                        <td>Request Information</td>
                        <td>
                          <b>Point of information</b>
                        </td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                        <td>Majority</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr></hr>
                  <br></br>
                  <h4
                    className="text-center mb-4 mt-4"
                    style={{ marginTop: "56px" }}
                  >
                    Motions Classified According to Purpose
                  </h4>
                  <table className="table">
                    <tr>
                      <td>
                        <b>Bring Before the Body</b>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Move an action or resolution for Consideration
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Modify an Action</b>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Amend: add and/or delete words in the main motion.
                          </li>
                          <span style={{ color: "grey" }}>
                            An amendment may be amended once.
                          </span>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Suppress Debate or Hasten Action</b>
                      </td>
                      <td>
                        <ul>
                          <li>Previous question (Call the Question).</li>
                          <span style={{ color: "grey" }}>
                            A motion to close debate immediately. Requires 2/3
                            vote.
                          </span>
                          <li>Limit Debate</li>
                          <span style={{ color: "grey" }}>
                            Move to limit to a specific time or number of
                            speakers. Requires 2/3 vote.
                          </span>
                          <li>Suspend Rules for Purpose of ___</li>
                          <span style={{ color: "grey" }}>
                            Change established agenda or rules. Requires 2/3
                            vote.
                          </span>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Delay Action</b>
                      </td>
                      <td>
                        <ul>
                          <li>Postpone to a definite time.</li>
                          <li>
                            Refer to committee.{" "}
                            <span style={{ color: "grey" }}>
                              Refer for study and report to the body at a later
                              time.
                            </span>{" "}
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Prevent Action</b>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Postpone indefinitely.{" "}
                            <span style={{ color: "grey" }}>
                              Kills the motion.
                            </span>
                          </li>
                          <li>
                            Object to consideration.{" "}
                            <span style={{ color: "grey" }}>
                              Requires 2/3 vote.
                            </span>
                          </li>
                          <li>Withdraw the motion.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Consider More Carefully</b>
                      </td>
                      <td>
                        <ul>
                          <li>Extend Debate</li>
                          <li>
                            Divide question.{" "}
                            <span style={{ color: "grey" }}>
                              Divides motion into distinct parts, each voted on
                              separately.
                            </span>
                          </li>
                          <li>
                            Demand a counted vote.{" "}
                            <span style={{ color: "grey" }}>
                              Used when the results of a voice vote are in
                              doubt.
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Change a decision</b>
                        <span>
                          (Bring a Question again before the assembly)
                        </span>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Reconsider.{" "}
                            <span style={{ color: "grey" }}>
                              Maker of motion must have voted on the prevailing
                              side. Requires 2/3 vote.
                            </span>
                          </li>
                          <li>
                            Rescind or Amend Something Previously Adopted.
                          </li>
                          <span style={{ color: "grey" }}>
                            Maker of motion must have voted on the prevailing
                            side. Requires 2/3 vote.
                          </span>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Maintain rules and order</b>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Question of privilege.{" "}
                            <span color={{ color: "grey" }}>
                              May not be used to continue debate.
                            </span>
                          </li>
                          <li>
                            Point of order.{" "}
                            <span style={{ color: "grey" }}>
                              May not be used to continue debate.
                            </span>
                          </li>
                          <li>
                            Appeal from decision of Chair.{" "}
                            <span style={{ color: "grey" }}>
                              Question a ruling of the Chair.
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Parliamentary inquiry</b>
                      </td>
                      <td>
                        <ul>
                          <li>Question parliamentary procedure on an issue.</li>
                          <li>Request for information.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Close a Meeting</b>
                      </td>
                      <td>
                        <ul>
                          <li>
                            Adjourn.{" "}
                            <span style={{ color: "grey" }}>
                              Ends all business for this meeting.
                            </span>
                          </li>
                          <li>
                            Recess.{" "}
                            <span style={{ color: "grey" }}>
                              Meeting will continue its business at a later
                              time.
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                  <hr></hr>
                  <br></br>
                  <p>
                    Note: This is a brief presentation of the most commonly used
                    actions in a meeting and is not intended to be definitive.
                    Refer to Robert’s Rules of Order Newly Revised, 11th ed. or
                    www.robertsrules.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default StandingRules;
