import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Content from "../sections/CommitteeMeetings/Content";
import { useLocation } from "react-router-dom";

function CommitteeMeetings() {
  const location = useLocation();

  const pageTitle = "Central Committee Meetings | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <div>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Learn more about Democratic Party of Clackamas County Central Committee Meetings. Get involved and help support local candidates."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Committee Meetings" }} />
      <Content />
      <Footer />
    </div>
  );
}

export default CommitteeMeetings;
