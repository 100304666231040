import raffle_tickets from "./image6.png";
import crooked_river from "./image1.jpg";
import wine from "./image2.png";
import pumpkins from "./image5.jpg";
import gift_cards from "./image3.jpg";

const mappings = {
  raffle_tickets,
  crooked_river,
  wine,
  pumpkins,
  gift_cards,
};

export const auctionImagesReplacer = Object.keys(mappings).map((key) => {
  return {
    find: `{{${key}}}`,
    replace: `
              <img src="${mappings[key]}" alt="${key}" />
          `,
  };
});
