import React, { Fragment } from "react";
import Banner from "./comps/Banner";
import Breadcrumbs from "../../layouts/Breadcrumbs";

function Content() {
  const userBrowser = navigator.userAgent;

  const hideInBrowser = () => {
    if (userBrowser.includes("Safari")) {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <Breadcrumbs breadcrumb={{ pagename: "Event Calendar" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        {hideInBrowser() ? (
          <p
            style={{
              textAlign: "center",
            }}
          >
            This page is not supported in your browser. Please use a different
            browser, or follow this link to view the calendar in a new tab.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://calendar.google.com/calendar/u/0/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America/Los_Angeles&src=Zm1kY2wyMWs0OWJjMTBxYTdnbG5qdTk0amNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23743500&showTabs=1&showCalendars=0&mode=AGENDA&showDate=0"
            >
              View Calendar.
            </a>
          </p>
        ) : (
          <iframe
            title="Google Calendar"
            frameborder="0"
            height="800"
            scrolling="yes"
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=Zm1kY2wyMWs0OWJjMTBxYTdnbG5qdTk0amNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23743500&amp;showTabs=1&amp;showCalendars=0&amp;mode=AGENDA&amp;showDate=0"
            style={{ border: "solid 1px #777;" }}
            width="100%"
          ></iframe>
        )}
      </div>
    </Fragment>
  );
}

export default Content;
