import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import profile from "../../assets/img/banner/pride-profile-logo.png";
import { useLocation } from "react-router-dom";

function OurMission() {
  const location = useLocation();

  const pageTitle = "Our Mission | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="The Democratic Party of Clackamas County works to elect progressive leaders at the local, state and national levels. Learn more about our mission and join us."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Our Mission" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-3">
              <div className="card">
                <div className="card-title p-2">
                  <h4>Our Mission</h4>
                </div>
                <div className="card-text p-3">
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <img
                        src={profile}
                        alt="Democrat Profile Two"
                        className="w-100"
                      />
                    </div>
                    <div className="col-md-8 col-sm-12">
                      {/* <p>
                        The Clackamas County Democratic Party carries out the
                        goals of the national, state and county Democratic
                        parties while developing, maintaining and electing
                        Democrats to enact laws reflecting those values for the
                        greater good.
                      </p> */}
                      <p>
                        The Democratic Party of Clackamas County works to elect
                        progressive leaders at the local, state, and national
                        levels. We do this through education, fundraising, and
                        voter drives. We support candidates who are committed to
                        universal voting rights, access to quality education and
                        health care, a healthy and sustainable environment, and
                        economic opportunity for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default OurMission;
