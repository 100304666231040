import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const clientslide = [];

const settings = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
class Client extends Component {
  render() {
    return (
      <Slider
        className="row align-items-center justify-content-between"
        id="clientSlider"
        {...settings}
      >
        {clientslide.map((item, i) => (
          <div key={i} className="col">
            <Link to="/" className="client-img d-block text-center">
              <img src={item.img} alt="" />
            </Link>
          </div>
        ))}
      </Slider>
    );
  }
}

export default Client;
