import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";

import standingRulesPDF from "../../assets/files/Standing-Rules.pdf";
import { useLocation } from "react-router-dom";

// * COPY FROM THIS AND FORMAT IT CORRECTLY

// So far https://wordtohtml.net/ is the best converter I've found

function StandingRules() {
  const location = useLocation();

  const pageTitle = "Our Standing Rules | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County standing rules. Our standing rules explain how we operate. Learn more about who we are and what we do."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Standing Rules" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-center">Download Standing Rules</h4>
                </div>
                <div className="card-body p-3">
                  <a href={standingRulesPDF} download>
                    Download PDf file
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3 className="text-center">
                    Democratic Party of Clackamas County
                  </h3>
                  <h4 className="text-center">Standing Rules</h4>
                </div>
                <div className="card-body p-3">
                  <p>
                    Democratic Party of Clackamas County
                    <br />
                    <br />
                    STANDING RULES
                    <br />
                    Standing Rule 1: FINANCE (May 16, 2013)
                    <br />
                    ARTICLE I<br />
                    <br />
                    FINANCE-RELATED PROCEDURES
                    <br />
                    <br />
                    A. Each year, the Finance Committee shall prepare an annual
                    budget for discussion and
                    <br />
                    recommendation by the Executive Committee, followed by
                    consideration for approval by the Central
                    <br />
                    Committee.
                    <br />
                    B. Monthly budget reports to the Executive Committee and
                    Central Committee shall apprise the
                    <br />
                    membership of the financial situation per the budget.
                    <br />
                    C. Payment for authorized expenditures shall be made by the
                    program or activity chair by one of the
                    <br />
                    following procedures:
                    <br />
                    1. Submitting a vendor&rsquo;s invoice (preferred)
                    <br />
                    2. Sending an email to the Treasurer with the name of the
                    responsible person and the details
                    <br />
                    needed to make the payment
                    <br />
                    D. The Treasurer shall forward payment information for all
                    authorized, budgeted expenditures to C &amp; E<br />
                    Systems for State reporting and for payment processing.
                    <br />
                    E. Reimbursement for authorized, budgeted expenditures shall
                    be requested by submitting the original
                    <br />
                    receipt and record of payment along with the REIMBURSEMENT
                    REQUEST FORM. Note: An
                    <br />
                    individual who incurs an expense for the Central Committee
                    and does not seek reimbursement shall
                    <br />
                    follow the procedure for in-kind donations to facilitate the
                    required State reporting. Such
                    <br />
                    non-reimbursed expenditures constitute political donations.
                    <br />
                    F. Adjustments to the budget shall be considered by the
                    Finance Committee. The Treasurer shall
                    <br />
                    incorporate any resulting changes to the adopted budget into
                    a proposed revised budget, to be
                    <br />
                    reviewed by the Executive Committee. The proposed revised
                    budget shall then be forwarded to the
                    <br />
                    Central Committee for consideration and adoption.
                    <br />
                    G. The Executive Committee may approve a non-budgeted
                    expenditure that does not exceed $200
                    <br />
                    without Central Committee approval, but must notify the
                    Central Committee as soon as possible.
                    <br />
                    H. The Executive Committee shall allocate funds budgeted for
                    candidates endorsed by the Democratic
                    <br />
                    Party according to the following criteria:
                    <br />
                    1. Likelihood of election to office
                    <br />
                    2. Relative need for funds
                    <br />
                    3. Estimated effectiveness of the contributed funds
                    <br />
                    4. Importance to the furtherance of Clackamas County
                    Democratic Party values and goals
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 2: ELECTIONS
                    <br />
                    (July 18, 2013; rev. September 18, 2018 and Sept. 2023)
                    <br />
                    ARTICLE I<br />
                    <br />
                    ELECTIONS OF CENTRAL COMMITTEE OFFICERS
                    <br />
                    <br />
                    A. Vacancies in Central Committee offices (Chair, First
                    Vice-Chair, Second Vice-Chair, Secretary,
                    <br />
                    Electronic Secretary and Treasurer) shall be filled by
                    majority vote of the Central Committee at
                    <br />
                    elections--including the biennial organizational
                    election--organized and supervised by the Rules
                    <br />
                    Committee.
                    <br />
                    B. The following positions shall be filled at the
                    organizational meeting: Chair, First Vice-Chair, Second
                    <br />
                    Vice-Chair, Secretary, Electronic Secretary and Treasurer of
                    the Central Committee. The First
                    <br />
                    Vice-Chair must be of a different gender from the Chair.
                    Positions shall be open to any registered
                    <br />
                    Democrat in Clackamas County with all newly elected PCPs
                    eligible to vote. (ORS 248.035.b)
                    <br />
                    C. A Nominations Sub-Committee of the Rules Committee
                    consisting of five members representing at
                    <br />
                    least three House Districts shall be elected at least six
                    weeks prior to the biennial reorganization
                    <br />
                    meeting with the following membership:
                    <br />
                    1. One member of the Executive Committee elected by the
                    Executive Committee.
                    <br />
                    2. Four members elected by the Central Committee, at least
                    two of whom shall not be members
                    <br />
                    of the Executive Committee.
                    <br />
                    D. Membership on the Nominations Sub-Committee does not
                    disqualify a [candidate] sub-committee
                    <br />
                    member from being a candidate for office.
                    <br />
                    E. Notice of any election for an officer of the Central
                    Committee shall be announced to the full voting
                    <br />
                    membership of the Central Committee by the day following the
                    Executive Committee meeting in the
                    <br />
                    month in which the election will be held. The notice shall
                    include the office or offices to be filled and
                    <br />
                    the name and email address of the chair of the Nominations
                    Committee and the deadline for receipt
                    <br />
                    of nominations by the committee.
                    <br />
                    F. Any voting member of the Central Committee may submit a
                    nomination for any open office to be
                    <br />
                    included on the list of nominees submitted by the
                    Nominations Sub-Committee. The Nominations
                    <br />
                    Sub-Committee shall compile a list of names received,
                    including self-nominations, and any willing
                    <br />
                    and suitable candidates selected by a majority of the
                    Nominations Sub-Committee.
                    <br />
                    G. At least ten days before any election, the Nominations
                    Sub-Committee shall provide to the chairs of
                    <br />
                    the Central Committee and the Rules Committee the name(s) of
                    candidates for the position(s). This
                    <br />
                    list shall be communicated to the membership in the meeting
                    announcement sent to all voting
                    <br />
                    members at least six days before the election.
                    <br />
                    H. At the time of the election, additional nominations may
                    be made and write-in votes shall also be
                    <br />
                    permitted.
                    <br />
                    I. Balloting will proceed one position at a time in the
                    following order: Chair, [Vice-Chair] First
                    <br />
                    Vice-Chair, Second Vice-Chair, Secretary, Electronic
                    Secretary, Treasurer.
                    <br />
                    J. Ballots shall be counted by tellers appointed by the
                    Rules Committee, with results reported to the
                    <br />
                    Chair.
                    <br />
                    K. If no candidate receives a majority on the first ballot,
                    a run-off vote between the top two candidates
                    <br />
                    shall determine the winner.
                    <br />
                    L. Voting by acclamation is acceptable for any uncontested
                    race.
                    <br />
                    M. Voting conducted electronically shall be governed by the
                    provisions in Standing Rule 11- Conducting
                    <br />
                    Electronic or Hybrid Electronic/in Person Meetings that
                    Comply with Robert&rsquo;s Rules of Order, 12th
                    <br />
                    Ed.
                    <br />
                    [September 2018: PCP Attendance 118; Yes &hellip; 117, No
                    &hellip; 0, Abstain &hellip; 1]
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 3: JILL THORN GRANT
                    <br />
                    (September 18, 2014)
                    <br />
                    ARTICLE 1<br />
                    GRANT DESCRIPTION
                    <br />
                    The Jill Thorn Grant is an award of $500 or more depending
                    on contributions provided by the Clackamas
                    <br />
                    County Democratic Party and individual donors. It is
                    available to help a woman from Clackamas County run
                    <br />
                    for public office for the first time. Candidates may be
                    nominated by any Clackamas County Democratic PCP
                    <br />
                    at any time during the year. In no case will more than one
                    award be made per calendar year; however, if no
                    <br />
                    candidate is selected, no award will be made.
                    <br />
                    ARTICLE 2<br />
                    CRITERIA
                    <br />
                    Candidates must be female registered Democrats who have not
                    previously run for public office and who are
                    <br />
                    either residents of Clackamas County or are preparing to run
                    for public office in a district that includes at
                    <br />
                    least part of Clackamas County. Candidates will be evaluated
                    according to the following criteria:
                    <br />
                    1. Demonstrated commitment to public service
                    <br />
                    2. Likelihood of success as a candidate for office
                    <br />
                    3. Need for support in pursuing candidacy for office
                    <br />
                    ARTICLE 3<br />
                    PROCEDURES
                    <br />
                    1. The availability of the Jill Thorn Grant will be posted
                    on the DPCC web site. It will also be announced
                    <br />
                    in the January meeting packet of the Clackamas County
                    Democratic Central Committee each
                    <br />
                    calendar year and at other times as may seem appropriate.
                    <br />
                    2. Award of the grant may be made as early as the February
                    meeting of the Central Committee each
                    <br />
                    year, but may be made at any point following that as long as
                    the grant has not been awarded that
                    <br />
                    calendar year.
                    <br />
                    3. Nominations may be initiated by any Clackamas County
                    Democratic PCP at the initiative of the PCP
                    <br />
                    or at the request of a candidate.
                    <br />
                    4. Preliminary screening of candidates will be done by a
                    three-member committee appointed by the
                    <br />
                    Chair of the county party with the consent of the Executive
                    Committee. The committee will normally
                    <br />
                    be appointed by the December meeting of the Executive
                    Committee each year and will review all
                    <br />
                    applicants for the following year. The selection committee
                    will determine eligibility and
                    <br />
                    appropriateness of all candidates and, in the case of
                    multiple candidates, forward a ranked list to the
                    <br />
                    Executive Committee. Review will consider an application
                    form and an in-person interview with all
                    <br />
                    candidates.
                    <br />
                    5. The recommendation of the Executive Committee will be
                    subject to approval of the Central
                    <br />
                    Committee.
                    <br />
                    6. Funds will be provided to the candidate as a donation
                    from the county party following approval by
                    <br />
                    the Central Committee.
                    <br />
                    7. The Jill Thorn Grant is given to help defray the costs of
                    a woman running for public office the first
                    <br />
                    time and may be used at the discretion of the recipient for
                    training, preparation for an actual political
                    <br />
                    campaign, or other campaign-related expenses.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 4: MONROE SWEETLAND AWARD
                    <br />
                    (October 20, 2017, revised Sept. 2023)
                    <br />
                    Monroe Sweetland (1910-2006) played a vital role in building
                    a powerful, progressive Democratic Party in
                    <br />
                    Oregon after World War II. He was a key architect of
                    Democratic victories in both state and national
                    <br />
                    campaigns and served in both the Oregon House and Senate.
                    Long a resident of Milwaukie, Monroe
                    <br />
                    Sweetland lived a life dedicated to supporting human rights,
                    social justice, and the public good, especially in
                    <br />
                    the field of education.
                    <br />
                    Each year the Central Committee of the Clackamas County
                    Democratic Party presents the Monroe
                    <br />
                    Sweetland award to a Clackamas County Democratic PCP whose
                    outstanding commitment and active
                    <br />
                    engagement have contributed to the development of the
                    Clackamas County Democratic Party. The recipient
                    <br />
                    may not have received the award during the previous ten
                    years.
                    <br />
                    The Chair of the Clackamas County Democratic selects the
                    recipient of the award each fall in consultation
                    <br />
                    with the other party officers. Presentation is normally made
                    at the annual Roosevelt Dinner or other meeting
                    <br />
                    or event in the fall, with the recipient receiving a
                    commemorative plaque.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 5: CLACKAMAS COUNTY DELEGATION TO THE
                    <br />
                    STATE PLATFORM CONVENTION
                    <br />
                    (February 15, 2018, rev. Sept. 2023)
                    <br />
                    1. Eligibility and Selection of Delegates and Alternates
                    <br />
                    a. All Democrats registered to vote in Clackamas County are
                    eligible to serve as delegates or
                    <br />
                    alternates to the state platform convention. Delegates and
                    alternates are elected by majority vote
                    <br />
                    at a regular meeting of the DPCC held at least one month
                    prior to the opening date of the state
                    <br />
                    platform convention. If there are no objections, delegates
                    may be elected by acclamation.
                    <br />
                    b. The chair of the DPCC shall notify the DPO by mail or
                    email within 7 days of the county election
                    <br />
                    of the names and contact information of those selected to
                    attend the state platform convention.
                    <br />
                    c. Representatives of the county should reflect the
                    diversity of Clackamas County voters.
                    <br />
                    Whenever possible, no more than 50% of delegates should be
                    of one gender.
                    <br />
                    2. Delegation Chair
                    <br />
                    a. The chair of the DPCC shall chair the delegation to the
                    DPO platform
                    <br />
                    convention. If the chair is unable to attend the platform
                    convention, the vice chair or another
                    <br />
                    officer of the county party designated by the chair shall
                    serve as delegation chair.
                    <br />
                    b. After the county election of delegates and alternates,
                    the chair of the delegation may add
                    <br />
                    delegates and/or alternates to fill vacancies in the
                    delegation up to the opening of the
                    <br />
                    convention, notifying the DPO as soon as possible of the
                    names and contact information of any
                    <br />
                    additional delegates. Delegates added after the county
                    delegate election may not be able to
                    <br />
                    receive convention materials in advance, depending on the
                    distribution procedures being
                    <br />
                    followed.
                    <br />
                    3. Fees and materials
                    <br />
                    a. Delegates and alternates selected to represent Clackamas
                    County shall be responsible to pay
                    <br />
                    the fee charged for participation.
                    <br />
                    b. The fee, along with the time and place of the convention,
                    will be announced by the DPO in
                    <br />
                    materials circulated to delegates and alternates at least 20
                    days prior to the convention.
                    <br />
                    4. Delegates and alternates
                    <br />
                    a. Delegates to the DPO platform convention are expected to
                    attend all sessions and to vote by
                    <br />
                    electronic ballot following the convention in the formal
                    adoption of the platform. No county &ldquo;may
                    <br />
                    impose a binding unit rule on any vote cast by the members
                    of any delegation&rdquo; (DPO Bylaws,
                    <br />
                    Art. VII).
                    <br />
                    b. If all delegate slots are filled, up to one-half the
                    number of delegates may be elected (or
                    <br />
                    appointed by the chair) as alternates. All alternates should
                    register for the convention and
                    <br />
                    participate in all sessions. However, only alternates
                    accredited as replacement delegates may
                    <br />
                    vote.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    c. Delegates who cannot attend the Platform Convention
                    should notify the county chair at least one
                    <br />
                    week in advance or as soon as possible.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 6: ORGANIZATIONAL MEETING OF THE CENTRAL
                    COMMITTEE:
                    <br />
                    VOTING PROCEDURES
                    <br />
                    (September 20, 2018, rev. Sept. 2023)
                    <br />
                    These instructions supplement those in Article VI of the
                    Bylaws.
                    <br />
                    A. Nominations
                    <br />
                    The Nominations Sub-Committee of the Rules Committee ( See
                    Bylaws Art. VI.C.2.c. and d. and Standing
                    <br />
                    Rule on Elections) shall present a slate of one or more
                    qualified candidates for every county office.
                    <br />
                    1. Nominations and self-nominations for any position may be
                    made by any member of the Central
                    <br />
                    Committee or relevant House District until nominations are
                    closed just prior to balloting. (See
                    <br />
                    Standing Rule on Elections)
                    <br />
                    2. Prior to balloting, nominees for Central Committee
                    offices, State Central Committee
                    <br />
                    delegates/alternates, and Congressional District
                    delegates/alternates will indicate on the Nominee
                    <br />
                    Acceptance Form their name, gender, and willingness to
                    serve. (If the nominee is not present, the
                    <br />
                    nominee may provide the information by phone or confirmed
                    email to the Chair of the Central
                    <br />
                    Committee, the Chair of the Nominations Committee, or the
                    Chair of the Rules Committee.) (See
                    <br />
                    Appendix A for sample form.)
                    <br />
                    3. Voters will add to their ballots the names of any
                    nominees added at the meeting.
                    <br />
                    B. Voting Eligibility
                    <br />
                    1. Only PCPs elected in the most recent primary election may
                    vote for Officers of the Central
                    <br />
                    Committee: Chair, First Vice-Chair, Second Vice-Chair,
                    Secretary, Treasurer.
                    <br />
                    2. Delegates to the State Central Committee, Congressional
                    District 3 (CD 3), and Congressional
                    <br />
                    District 5 (CD 5) and Congressional District 6 are elected
                    by Clackamas County PCPs, both elected
                    <br />
                    and appointed.
                    <br />
                    3. House District Leaders are elected by PCPs resident in
                    the House District.
                    <br />
                    C. Ballot election
                    <br />
                    1. Voting by acclamation is acceptable for any uncontested
                    race.
                    <br />
                    2. Voting conducted electronically shall be governed by the
                    provisions in Standing Rule 11- Conducting
                    <br />
                    Electronic or Hybrid Electronic/in Person Meetings that
                    Comply with Robert&rsquo;s Rules of Order, 12th
                    <br />
                    Ed.
                    <br />
                    3. Except when there is only one nominee, all non electronic
                    voting shall be by ballots which shall be
                    <br />
                    placed in a ballot box and counted by tellers under
                    supervision of the Rules Committee. Totals shall
                    <br />
                    be reported to the Chair, who will announce the results.
                    [Revised January 16, 2020 by unanimous
                    <br />
                    voice vote, with 62 PCPs voting]
                    <br />
                    4. Ballots shall be counted by tellers appointed by the
                    Rules Committee in an area separated from the
                    <br />
                    assembly. Results will be reported by the Head Teller to the
                    Chair, who will announce results.
                    <br />
                    5. Up to four observers may be allowed in the counting area
                    as ballots are counted. They must stay at
                    <br />
                    least five feet from any counting process and may not speak,
                    interact, or interfere with those
                    <br />
                    counting ballots, except to ask questions of the head
                    teller. Any observer asked to leave the counting
                    <br />
                    area by the head teller must do so at once. Any questions or
                    problems noted by an observer should
                    <br />
                    be referred at once to the Chair or to a member of the Rules
                    Committee.
                    <br />
                    6. Raising a point of order or reporting a violation
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Except for a violation of procedure of a continuing nature,
                    such as the election of someone not
                    <br />
                    eligible to serve in the office for which they are elected,
                    all challenges to the results or procedures
                    <br />
                    related to an election must be made no later than
                    immediately after the chair&rsquo;s announcement of the
                    <br />
                    results, &ldquo;before any debate or business has
                    intervened.&rdquo; (RONR, 11th ed., p. 409.) If the main
                    <br />
                    meeting is in recess during vote counting, problems or
                    questions may be promptly reported to the
                    <br />
                    Chair or a member of the Rules Committee.
                    <br />
                    7. Election of officers
                    <br />
                    1. Officers will be elected in the following order: Chair,
                    First Vice-Chair, Second Vice-Chair,
                    <br />
                    Secretary, Electronic Secretary, Treasurer. (See Bylaws
                    VI.C.)
                    <br />
                    2. The election of the Chair and First Vice-Chair will be
                    conducted by a temporary chair as
                    <br />
                    specified in Article VI.C.2.d of the Bylaws. The newly
                    elected Chair will preside for the
                    <br />
                    remainder of the organizational meeting.
                    <br />
                    3. The First Vice-Chair shall be of a different gender from
                    the Chair. Once the result of the
                    <br />
                    election of the Chair is announced, nominations are open for
                    the next position. Any
                    <br />
                    candidates for Chair who are not elected may, if of a
                    different gender, run for First Vice- Chair
                    <br />
                    or, regardless of gender, run for Second Vice-Chair.
                    <br />
                    8. Election of delegates to the State Central Committee
                    (SCC), Congressional District Committee 3<br />
                    (CD 3) Congressional District 5 (CD 5) and Congressional
                    District 6 (CD6.) (Bylaws Art. VI.C.2.g)
                    <br />
                    1.
                    <br />
                    2.
                    <br />
                    <br />
                    Nominees for delegate/alternate positions for CD 3 CD 5 and
                    CD 6 shall fill out a Nominee
                    <br />
                    Acceptance Form (Appendix A).
                    <br />
                    All nominees for a congressional district committee shall be
                    listed on a single ballot. (Sample
                    <br />
                    Ballot, Appendix B)
                    <br />
                    <br />
                    3.
                    <br />
                    <br />
                    Votes cast shall equal the total number of delegate slots as
                    specified by the Democratic Party
                    <br />
                    of Oregon (DPO) on a single list.
                    <br />
                    <br />
                    4.
                    <br />
                    <br />
                    No more than half of the allotted votes (or in the case of
                    an uneven number of slots, fifty
                    <br />
                    percent plus one) shall be cast for any one gender. Ballots
                    on which more than the allotted
                    <br />
                    slots are selected will be invalid. (Appendix A)
                    <br />
                    <br />
                    5.
                    <br />
                    <br />
                    Positions as Delegates and Alternates will be awarded in the
                    order of votes received, with no
                    <br />
                    more than fifty percent or, in the case of an odd number of
                    delegates, fifty-one percent of
                    <br />
                    Delegate/Alternate slots going to candidates of any one
                    gender. If multiple non-binary
                    <br />
                    candidates are elected (place among the winning
                    vote-getters), they will reduce male and
                    <br />
                    female positions as equitably as possible. (Art.VI.C.2.j(i))
                    (See Appendix C, &ldquo;Counting
                    <br />
                    Procedure&rdquo;)
                    <br />
                    <br />
                    6.
                    <br />
                    <br />
                    Should the chair resign or be unable to continue their
                    duties, an election will be held by all
                    <br />
                    members of the SCC or Congressional delegation to elect a
                    new chair.
                    <br />
                    <br />
                    V.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Appendix A: Nominee Acceptance Form
                    <br />
                    Nominee Acceptance Form
                    <br />
                    November, 2018
                    <br />
                    Name________________________________
                    <br />
                    Position______________________________
                    <br />
                    Gender: M_____ F_____ N_____
                    <br />
                    If elected, I agree to serve:
                    <br />
                    ________________________________(signat
                    <br />
                    ure)
                    <br />
                    VI.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Appendix B: Sample Ballot
                    <br />
                    Congressional District 3 (Vote for 3&mdash;
                    <br />
                    No more than two of any gender. )<br />
                    Men
                    <br />
                    Scott XXXXXX
                    <br />
                    Joe YYYYYYY
                    <br />
                    Sam CCCCCCCC
                    <br />
                    Don SSSSSSSS
                    <br />
                    <br />
                    Vote Totals
                    <br />
                    <br />
                    Votes -Result FranN 59 (Delegate1--Chair)
                    <br />
                    LindaF 57 (Delegate2)
                    <br />
                    DonM 43 (Delegate3)
                    <br />
                    10
                    <br />
                    SamM 32 (Alternate1)
                    <br />
                    21 (M3-A23) JudyF 28 (Alternate2)
                    <br />
                    JoeM 21 (Alternate3)
                    <br />
                    32 (M2-A1) ScottM 20
                    <br />
                    JodyN 19
                    <br />
                    43 (M1-D3) EllenF 16
                    <br />
                    MollyF 10
                    <br />
                    <br />
                    __________________________ ____
                    <br />
                    <br />
                    Linda MMMM
                    <br />
                    <br />
                    Note: If the top three vote-getters were
                    <br />
                    female, only two could become
                    <br />
                    Votes- Result
                    <br />
                    Delegates. The third would become an
                    <br />
                    Alternate and the top male or non-binary
                    <br />
                    20
                    <br />
                    vote-getter would become a Delegate.
                    <br />
                    (VI.C.2.g.)
                    <br />
                    57
                    <br />
                    (F1-D2)
                    <br />
                    <br />
                    Judy TTTTTT
                    <br />
                    <br />
                    28
                    <br />
                    <br />
                    Ellen FFFFFF
                    <br />
                    <br />
                    16
                    <br />
                    <br />
                    Women
                    <br />
                    Molly XXXXX
                    <br />
                    <br />
                    __________________________
                    <br />
                    <br />
                    (F2-A2)
                    <br />
                    <br />
                    ____
                    <br />
                    <br />
                    Non-Binary
                    <br />
                    Result
                    <br />
                    <br />
                    Votes -<br />
                    <br />
                    Fran
                    <br />
                    <br />
                    59
                    <br />
                    <br />
                    Jody
                    <br />
                    <br />
                    19
                    <br />
                    <br />
                    (N1-D1)
                    <br />
                    <br />
                    _______________________ ____
                    <br />
                    _______________________ ____
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Note: If a Delegate resigns, the
                    <br />
                    replacement must come from the list of
                    <br />
                    Alternates, with gender as the first
                    <br />
                    criterion and number of votes as the
                    <br />
                    second. (VI.C.2.g). When an Alternate
                    <br />
                    becomes a Delegate, an election is held
                    <br />
                    to replace the Alternate.
                    <br />
                    <br />
                    Appendix C: Counting Procedure
                    <br />
                    1. Tally all the votes, producing a vote total for each
                    nominee.
                    <br />
                    2. Designate the top vote-getters to fill the Delegate slots
                    in order of votes received, with no more than
                    <br />
                    half of the Delegates of any one gender.
                    <br />
                    3. Repeat the process to fill the Alternate slots, with the
                    number of males and females as balanced as
                    <br />
                    possible, and no more than half of the total delegation
                    identifying as any one gender.
                    <br />
                    4. In the event of a tie for the last Delegate or Alternate
                    slot, hold a run-off election, unless half of the
                    <br />
                    slots in that category are already held by someone of the
                    same gender as one of the nominees
                    <br />
                    involved in the tie. In that case, the nominee who is not
                    from the same gender as the majority of
                    <br />
                    Delegates or Alternates already elected receives the
                    position. In all other cases, hold a run-off
                    <br />
                    election, with the highest vote-getter of that election
                    declared the winner.
                    <br />
                    5. List Alternates according to the number of votes
                    received, highest first. When a Delegate is absent
                    <br />
                    (from a meeting) or resigns (from the position), the
                    highest-ranked Alternate of the same gender
                    <br />
                    becomes a Delegate.
                    <br />
                    6. After the organizational meeting, any vacancies in the
                    delegation are filled by election at the next
                    <br />
                    regular Central Committee meeting, keeping in mind that
                    &ldquo;replacement preference for alternate
                    <br />
                    delegates will utilize gender category first.&rdquo; (DPO
                    Bylaws, Article IX, Section 1.)
                    <br />
                    7. The top vote-getter willing to serve shall chair the
                    delegation.
                    <br />
                    [September 2018 PCP Attendance 118 Adopted by Unanimous
                    Voice Vote]
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 7: GUIDELINES FOR DRAFTING AND SUBMITTING A
                    RESOLUTION FOR
                    <br />
                    CONSIDERATION BY THE CENTRAL COMMITTEE
                    <br />
                    (October 18, 2018, rev. Sept. 2023)
                    <br />
                    I. Definition and Scope of Resolutions.
                    <br />A resolution is a written motion, adopted by majority
                    or super-majority vote of the Central Committee of
                    <br />
                    the Democratic Party of Clackamas County (DPCC) that
                    expresses the formal position of the DPCC on a<br />
                    specific topic external to it. The DPCC may consider two
                    kinds of resolutions: (a) resolutions by the
                    <br />
                    DPCC regarding a matter appropriately of county concern, and
                    (b) resolutions endorsed by the DPCC
                    <br />
                    for submission to the Democratic Party of Oregon (DPO) on a
                    topic of statewide or national concern.
                    <br />
                    II. Submission of Resolutions
                    <br />
                    A. A resolution may be proposed by the chair of any standing
                    committee or any member of the DPCC.
                    <br />
                    B. Ordinarily the proposal must be submitted to the DPCC
                    chair at least 10 days before a scheduled
                    <br />
                    meeting of the Executive Committee (ExCom).
                    <br />
                    C. At the chair&rsquo;s discretion, the proposed resolution
                    may be referred to the Platform and Resolutions
                    <br />
                    Committee (PRC) or other appropriate standing committee for
                    review and recommendation to the
                    <br />
                    ExCom on whether to endorse. Alternatively, it may be placed
                    on the agenda of the next meeting of
                    <br />
                    the ExCom if it is of an urgent nature and is in the form
                    specified in this rule.
                    <br />
                    D. If referred to committee, the reviewing committee will
                    report to the ExCom at its next meeting with a<br />
                    recommendation to endorse, reject, or extend review.
                    <br />
                    E. If the matter is urgent and the reviewing committee is
                    unable to make a recommendation in time for
                    <br />
                    inclusion on the ExCom agenda, but is able to do so in time
                    for inclusion on the agenda of the
                    <br />
                    Central Committee (CentCom) meeting, the DPCC chair may at
                    his or her discretion include it. In
                    <br />
                    that case,
                    <br />
                    F. Timely received and reviewed resolutions require a
                    majority vote to be adopted.
                    <br />
                    G. In extraordinary circumstances, resolutions referred to
                    in this Standing Rule may be brought to the
                    <br />
                    floor of a Central Committee meeting without prior notice,
                    as stated in Art. VI.A.45.c of the Bylaws.
                    <br />
                    The motion to consider such a resolution is debatable and
                    requires a two-thirds majority vote in
                    <br />
                    support of consideration. Approval of a motion taken up in
                    this manner shall require a two-thirds
                    <br />
                    affirmative vote.
                    <br />
                    H. The DPCC may endorse without change a resolution that has
                    been submitted to the DPO by another
                    <br />
                    county party, subject to the review and time requirements in
                    this rule and conforming to all DPO
                    <br />
                    specifications.
                    <br />
                    III. Resolutions to the DPO.
                    <br />
                    A. Submission of resolutions intended for consideration and
                    action by the DPO are controlled by Article
                    <br />
                    XVI of the DPO Bylaws. These requirements may be found at
                    <a
                      data-fr-linked="true"
                      href="http://dpo.org/how-submit-resolution"
                    >
                      http://dpo.org/how-submit-resolution
                    </a>
                    .<br />
                    (Note that the DPO provisions are different from the
                    provisions for DPCC-only consideration with
                    <br />
                    regard to process, timing, and form.)
                    <br />
                    B. Resolutions intended for submission to the DPO may
                    originate in any standing committee or any
                    <br />
                    member of the DPCC. The DPCC Chair may send the draft
                    resolution to the DPCC Platform and
                    <br />
                    Resolutions Committee for final editing. In unusual
                    circumstances, such editing may occur between
                    <br />
                    consideration by the ExCom and forwarding to the CentCom.
                    <br />
                    IV. Disposition of Resolutions. The DPCC Chair is
                    responsible for conveying adopted resolutions to parties
                    <br />
                    named in them and to publicize them as appropriate in
                    electronic and print media, deferring to the Media
                    <br />
                    Policy Guidelines for the DPO Platform and Resolutions
                    Committee with regard to Proposals submitted to
                    <br />
                    the DPO.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    V. Form of DPCC Resolutions. (Please refer to example below
                    when reading this section.) A resolution has
                    <br />
                    three parts:
                    <br />
                    A. Title. The title should concisely state the substance of
                    the resolution. The language used will vary with
                    <br />
                    the subject but should be built with a verbal such as
                    &ldquo;to support,&rdquo; &ldquo;to create,&rdquo;
                    &ldquo;opposing,&rdquo; or &ldquo;calling
                    <br />
                    for.&rdquo; These are more effective than the weaker
                    &ldquo;regarding&rdquo; or &ldquo;concerning.&rdquo; A
                    number will be assigned
                    <br />
                    by the secretary when the resolution is adopted.
                    <br />
                    B. WHEREAS part. One or more clauses beginning with the
                    conjunction &ldquo;WHEREAS&rdquo; state the basis for
                    <br />
                    the resolution. (Note that, contrary to mistaken common
                    practice, there should not be a comma after
                    <br />
                    the word &ldquo;WHEREAS.&rdquo;)
                    <br />
                    1. The point made in a WHEREAS clause may be one of the
                    following kinds:
                    <br />
                    a. Point of fact. If not general knowledge, facts asserted
                    should be documented with a reference in
                    <br />
                    the clause itself to a reputable and verifiable source of
                    the information, backed up by a footnote to
                    <br />
                    the exact source.
                    <br />
                    b. Principle or value acknowledged by the party that
                    cogently entails the position taken in the
                    <br />
                    resolution. Care should be taken to avoid making WHEREAS
                    clauses an unfocussed litany of the
                    <br />
                    Party&rsquo;s values. A clause citing an article in the
                    Party&rsquo;s Platform or a priority legislative action item
                    <br />
                    satisfies this criterion and particularly strengthens the
                    resolution.
                    <br />
                    c. Law or constitutional provision. If the resolution
                    relates to a pending bill or an existing law or court
                    <br />
                    decision, that should be referenced in a WHEREAS clause.
                    <br />
                    d. Other circumstance that directly justifies or gives
                    currency or urgency to the resolution.
                    <br />
                    2. If there is only a single WHEREAS clause, it should be
                    punctuated with a comma at the end,
                    <br />
                    followed immediately with the RESOLVED clause or sections.
                    <br />
                    3. If there are multiple WHEREAS clauses, each ends with a
                    semi-colon, followed by the clause on a<br />
                    new line. Only the next-to-last clause gets the conjunction
                    &ldquo;and&rdquo; after the semi-colon (without a<br />
                    comma after &ldquo;and&rdquo;). The final WHEREAS clause
                    ends with a semicolon and is followed immediately
                    <br />
                    (on a new line) with the THEREFORE part.
                    <br />
                    C. THEREFORE part. The second major part of the resolution
                    is made up of one or more conclusionary
                    <br />
                    sentences introduced by the words &ldquo;NOW THEREFORE THE
                    DEMOCRATIC PARTY OF
                    <br />
                    CLACKAMAS COUNTY RESOLVES AS FOLLOWS:&rdquo;
                    <br />
                    1.That introduction is followed by one or more sentences,
                    each beginning a new line.
                    <br />
                    2. If there are more than one, the sentences following
                    THEREFORE should be numbered Section 1,
                    <br />
                    Section 2, etc.
                    <br />
                    3. Each section is one or more complete sentences expressing
                    a single action or conclusion, each
                    <br />
                    capitalized and ended with a period.
                    <br />
                    4. If sections are not closely related and entailed by the
                    same set of WHEREAS clauses, then a<br />
                    separate resolution should be devised, with its own set of
                    relevant WHEREAS clauses.
                    <br />
                    5. Lines for signature, date, and other submission
                    information will be added by the secretary to the
                    <br />
                    adopted resolution and are not the responsibility of the
                    drafter.
                    <br />
                    (Note on the grammatical form of a resolution: A well-formed
                    resolution structurally comprises a single
                    <br />
                    complex sentence beginning with the first THEREFORE clause
                    and concluding with the period at the
                    <br />
                    end of the first THEREFORE clause. Recognizing this overall
                    structure of a resolution will help to
                    <br />
                    understand correct punctuation usage.
                    <br />
                    However, an exception is ordinarily made when there are
                    multiple sections in the THEREFORE part.
                    <br />
                    While it would be grammatically proper to integrate
                    additional THEREFORE clauses in a resolution&rsquo;s
                    <br />
                    single complex sentence, with appropriate conjunctions and
                    punctuation, it is less awkward to treat
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    such additional clauses simply as successive sentences
                    without compromising the unity and cohesion
                    <br />
                    of the resolution, which remains a paramount consideration.)
                    <br />
                    D. External resolutions. From time to time, the DPCC is
                    invited to endorse or duplicate a resolution
                    <br />
                    adopted by another county party. The DPCC may endorse
                    without change such a resolution, subject
                    <br />
                    to the following requirements:
                    <br />
                    1. The process for endorsement is consistent with that for
                    adoption of resolutions originating in the
                    <br />
                    DPCC under this rule: and
                    <br />
                    2. Communication of the endorsement and execution of actions
                    pursuant to it acknowledge the source
                    <br />
                    of the original resolution;
                    <br />
                    E. In some cases, a resolution offered to the DPCC for
                    endorsement may be worthy of endorsement, but
                    <br />
                    fail to meet the standards for resolutions specified by the
                    DPO. In such a case, the DPCC may prefer
                    <br />
                    to write its own resolution, referring to its support for
                    the point made in the other resolution.
                    <br />
                    F. Sometimes it is felt that the substance of a proposed
                    resolution is of such an urgent nature that it
                    <br />
                    requires suspension of the normal process of adoption. A
                    matter should be considered urgent only if
                    <br />
                    the delay caused by adhering to normal requirements would
                    seriously diminish the effect of the
                    <br />
                    resolution, result in permanently lost opportunity, or
                    otherwise do significant harm.
                    <br />
                    SAMPLE RESOLUTION:
                    <br />A RESOLUTION ON COUNTY NONCOOPERATION IN UNJUST
                    TREATMENT
                    <br />
                    OF IMMIGRANTS
                    <br />
                    WHEREAS in these uncertain times all Clackamas County
                    families need to know they can use County
                    <br />
                    programs and facilities, including public education, our
                    public highways and byways, without fear of
                    <br />
                    confronting County personnel acting as agents of U.S.
                    Immigration and Custom Enforcement (ICE);
                    <br />
                    WHEREAS it is a violation of Oregon law (ORS 181A.820) to
                    use public resources to detect or apprehend
                    <br />
                    people in violation of federal immigration rules, for
                    example, an ICE &lsquo;detainer&rsquo; request;
                    <br />
                    WHEREAS in the 2014 case Miranda-Olivares v. Clackamas
                    County, U.S. District Court of Oregon, the
                    <br />
                    court ruled that Clackamas County would be legally liable
                    for wrongful detention if it held anyone at ICE&rsquo;s
                    <br />
                    requests without independent probable cause;
                    <br />
                    WHEREAS it would be a waste of county resources voluntarily
                    to cooperate with ICE in
                    <br />
                    ideologically-driven and legally challengeable behaviors
                    resulting in social injustice and costly litigation;
                    <br />
                    and
                    <br />
                    WHEREAS adopting a county-wide policy of inclusivity will
                    demonstrate basic respect and fairness for all of
                    <br />
                    our residents and clarifies and guides all County agencies
                    and personnel regarding interaction with
                    <br />
                    immigration enforcement agencies;
                    <br />
                    NOW, THEREFORE, THE DEMOCRATIC PARTY OF CLACKAMAS COUNTY
                    RESOLVES AS
                    <br />
                    FOLLOWS:
                    <br />
                    Section 1. Clackamas County agencies and personnel, within
                    their powers and in conformity with Oregon
                    <br />
                    statutes and the finding of the court in Miranda-Olivares
                    v.Clackamas County 2014 are hereby urged to
                    <br />
                    prohibit the use of county facilities and operations by U.S.
                    Immigration and Custom Enforcement, their
                    <br />
                    cohorts, or other related entities.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Section 2. All Clackamas County agencies, facilities, and
                    personnel are requested to take any and all
                    <br />
                    actions required to protect all residents of Clackamas
                    County from any detention without just cause that
                    <br />
                    might result from cooperation with ICE requests.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 8: REIMBURSABLE ACTIVITIES
                    <br />
                    OF CHAIR AND VICE CHAIR
                    <br />
                    (March 21, 2019)
                    <br />
                    Due to the expansion of the scope of activities expected of
                    the leadership of the
                    <br />
                    Clackamas County Democratic Party, the allocation of $500 in
                    the current budget for attendance
                    <br />
                    at conferences, conventions, and meetings defrays only a
                    small percentage of the costs of
                    <br />
                    events they need to attend in order to represent the
                    Clackamas County Democratic Party in
                    <br />
                    meetings, conferences, and conventions of the following: the
                    DPO, Congressional District
                    <br />
                    Organizations, elected officials of relevance to Clackamas
                    County, components of the CCDP and
                    <br />
                    neighboring county parties.
                    <br />
                    This Standing Rule increases the annual amount budgeted to
                    such expenses and clarifies
                    <br />
                    the scope and nature of expenditures, including the
                    following basic limitations on the use of funds:
                    <br />
                    1.
                    <br />
                    2.
                    <br />
                    <br />
                    Funding is normally provided for one representative of the
                    CCDP. Except in
                    <br />
                    cases where the Vice Chair is explicitly obligated to attend
                    an event or is
                    <br />
                    substituting for the Chair, that representative shall be the
                    Chair.
                    <br />
                    Funding is not provided for purely social activities and may
                    cover only
                    <br />
                    transportation, food not provided as part of the event,
                    lodging where necessary,
                    <br />
                    and admission fees.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 9: CANDIDATE ENDORSEMENT POLICY
                    <br />
                    (September, 2023)
                    <br />
                    <br />
                    Rationale
                    <br />
                    The primary goal of the DPCC is to elect or appoint a
                    Democrat to every elected and appointed
                    <br />
                    office, board, or commission, locally, state-wide and
                    nationally. While it is critical to elect
                    <br />
                    Democrats, it is essential to elect Democrats who will
                    champion the values of the Party as
                    <br />
                    expressed in the Party Platform and Resolutions. Our elected
                    and appointed officials must have
                    <br />
                    the freedom to make decisions based upon what they deem
                    appropriate to serve the greater good
                    <br />
                    while maintaining accountability to our Party&rsquo;s stated
                    values.
                    <br />
                    <br />
                    Definitions
                    <br />
                    Federal race
                    <br />
                    <br />A race for President, Vice President, Federal
                    Representative or
                    <br />
                    Senator.
                    <br />
                    <br />
                    State race
                    <br />
                    <br />A race for a statewide office including, but not
                    limited to,
                    <br />
                    Governor, Secretary of State, Treasurer, State
                    Representative or
                    <br />
                    State Senator.
                    <br />
                    <br />
                    Local race
                    <br />
                    <br />A race in even-numbered years, with a primary, for
                    local
                    <br />
                    governmental offices including, but not limited to, County
                    <br />
                    Commissioner, Mayor, and City Council.
                    <br />
                    <br />
                    Special district race
                    <br />
                    <br />A race in odd-numbered years, without a primary, for
                    district
                    <br />
                    offices such as school, fire and water boards.
                    <br />
                    <br />
                    Special election
                    <br />
                    <br />
                    An election held at a time other than the normal timeframe
                    for
                    <br />
                    elections.
                    <br />
                    <br />
                    Primary race
                    <br />
                    <br />A race in which voters can indicate their preference
                    for their
                    <br />
                    party&apos;s candidate, or a candidate in general, in an
                    upcoming
                    <br />
                    general or local election.
                    <br />
                    <br />
                    Endorse
                    <br />
                    <br />
                    Provide a candidate with the approval and official support
                    of the
                    <br />
                    Democratic Party of Clackamas County, including, but not
                    limited
                    <br />
                    to, inclusion on slate cards.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    A. Candidate Notification Procedures
                    <br />
                    As the DPCC recognizes the importance of all races, it is
                    the policy of the DPCC to reach out to
                    <br />
                    all Democratic candidates in primaries and special district
                    elections regarding endorsement.
                    <br />
                    1. Notification
                    <br />
                    a. The Chair of the DPCC is responsible for ensuring that
                    all Democratic candidates
                    <br />
                    in primaries and special district races are contacted
                    regarding the possibility of
                    <br />
                    endorsement, as well as advising them of other services that
                    the DPCC can offer
                    <br />
                    to candidates, such as VAN access. For special district
                    elections, the Chair may
                    <br />
                    delegate initial contacts to a committee or group.
                    <br />
                    b. An effort will be made to contact all candidates via
                    their contact method of record
                    <br />
                    (email or phone) in their filing form SEL-190. Those who do
                    not respond to the
                    <br />
                    first contact shall receive at least one more contact.
                    <br />
                    c. An electronic record such as a spreadsheet will be used
                    to track candidate
                    <br />
                    contacts, whether a candidate is seeking endorsement, and
                    whether or not
                    <br />
                    endorsement is recommended.
                    <br />
                    d. All candidates seeking endorsement shall complete a
                    questionnaire, attached as
                    <br />
                    Appendix 1. This questionnaire will be provided as an online
                    fillable form.
                    <br />
                    Exceptions may be made for previously endorsed incumbents
                    with a<br />
                    questionnaire on file.
                    <br />
                    e. Candidates will be given a date by which the completed
                    questionnaire must be
                    <br />
                    received in order to be considered for endorsement. This
                    date must allow
                    <br />
                    sufficient time for processing and vetting before the
                    endorsement meeting. While
                    <br />
                    the endorsement meeting will be held as close to the ballot
                    statement filing date as
                    <br />
                    possible, new candidates may not be able to wait until the
                    last minute to file and
                    <br />
                    still receive endorsement.
                    <br />
                    f. All returned questionnaires must be retained. These shall
                    be compiled into a<br />
                    document available to all members of the Central Committee.
                    <br />
                    g. Candidates who specifically inform the DPCC that they do
                    not want to be
                    <br />
                    endorsed will not be endorsed.
                    <br />
                    <br />
                    B. General Policies
                    <br />
                    Except where noted, general policies apply to all elections-
                    federal, state, local, and special district.
                    <br />
                    1.
                    <br />
                    <br />
                    Timing:
                    <br />
                    a. In general, in all primary and special district races,
                    the DPCC may:
                    <br />
                    &bull;
                    <br />
                    <br />
                    In exceptional cases, endorse a candidate who has previously
                    been endorsed by the
                    <br />
                    DPCC prior to the filing deadline upon request and with a
                    two-thirds recommendation
                    <br />
                    of the Executive Committee to send the early endorsement to
                    the Central Committee.
                    <br />
                    <br />
                    &bull;
                    <br />
                    <br />
                    In all races, attempt to schedule special meetings of both
                    the Executive and Central
                    <br />
                    Committees so that candidates receive endorsements before
                    the voters&rsquo; pamphlet
                    <br />
                    statement deadline.
                    <br />
                    <br />
                    b. Final candidate endorsements and ballot measure
                    endorsements, based on the
                    <br />
                    Executive Committee&rsquo;s recommendations, are the sole
                    responsibility of the DPCC
                    <br />
                    Central Committee and are voted on at a scheduled Central
                    Committee meeting.
                    <br />
                    2.
                    <br />
                    <br />
                    Reasons for non-endorsement:
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    a. Compelling reasons for non-endorsement of any candidate
                    include, but are not limited
                    <br />
                    to:
                    <br />
                    &bull;
                    <br />
                    &bull;
                    <br />
                    &bull;
                    <br />
                    &bull;
                    <br />
                    <br />
                    Morally unacceptable behavior
                    <br />
                    Substantial non-alignment with Party values and positions
                    <br />
                    Dereliction of duty
                    <br />
                    Continuous refusal to answer Party queries.
                    <br />
                    <br />
                    b. In exceptional cases, such as when a race may be won
                    outright in a primary (like County
                    <br />
                    Commissioner races) and when endorsing more than one
                    candidate would jeopardize the
                    <br />
                    likelihood of a Democrat winning the race, the DPCC may
                    endorse a single candidate to
                    <br />
                    increase the likelihood of a Democrat being elected to the
                    position. This endorsement
                    <br />
                    requires a 2/3 vote.
                    <br />
                    c. Grounds for endorsing or withholding endorsement must be
                    stated and substantiated
                    <br />
                    by examination of voting record, support for core values and
                    objectives of the
                    <br />
                    Democratic Party of Oregon as well as answers to questions
                    during the interview
                    <br />
                    process. This explanation must be presented at the meetings
                    of the Executive and
                    <br />
                    Central Committee before the endorsements are voted on.
                    <br />
                    3.
                    <br />
                    <br />
                    Endorsement of one Democrat over another Democrat, when both
                    have requested
                    <br />
                    endorsement shall occur only for compelling reasons as
                    described in B.2 and requires a<br />
                    two-thirds majority of both the Executive Committee and the
                    Central Committee.
                    <br />
                    <br />
                    4.
                    <br />
                    <br />
                    Endorsement of incumbents running for reelection may be
                    forwarded from the Endorsement
                    <br />
                    Committee to be considered by the Executive Committee and
                    the Central Committee once the
                    <br />
                    incumbent has filed to run for the same office.
                    <br />
                    <br />
                    5.
                    <br />
                    <br />
                    Non-endorsement of any incumbent who has requested
                    endorsement will occur only for
                    <br />
                    compelling reasons as described in B.2 and requires a
                    two-thirds majority of both the
                    <br />
                    Executive Committee and the Central Committee.
                    <br />
                    <br />
                    6.
                    <br />
                    <br />
                    Endorsement of a candidate in a primary election will
                    automatically follow any successful
                    <br />
                    candidate to the related general election unless new
                    information which requires reconsideration
                    <br />
                    is brought to the Central Committee&rsquo;s attention.
                    <br />
                    <br />
                    7. Ballot measures affecting the entire county may be
                    endorsed by majority vote of the
                    <br />
                    Executive Committee and forwarded for approval by the
                    majority of the Central
                    <br />
                    Committee.
                    <br />
                    8.
                    <br />
                    <br />
                    Local Measures may be included on printed slate cards upon
                    the request of the local House
                    <br />
                    District Leader(s) and approval of the Executive Committee.
                    Where lack of space is an issue,
                    <br />
                    digital references may be used.
                    <br />
                    <br />
                    C. Endorsement Policies
                    <br />
                    1. Proposing Endorsement Procedures for endorsement differ
                    depending on the type of race.
                    <br />
                    a. In all primaries and special district races, the DPCC
                    will follow the endorsement
                    <br />
                    process for all Democratic candidates, as described in A.1
                    and B.1, subject to B.2
                    <br />
                    above.
                    <br />
                    b. In state and federal and general election races, the DPCC
                    will follow the endorsement
                    <br />
                    process for the Democratic candidate for each office,
                    subject to B.4 above, except
                    <br />
                    under conditions stated in General Policies B.2 and B.5.
                    <br />
                    2. Vetting Candidates
                    <br />
                    a. The DPCC Chair will designate a candidate vetting
                    committee (Endorsement
                    <br />
                    Committee) to include the Chair as well as a member of the
                    Campaign, Platform and
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Resolutions, Neighborhood Leader and Rules Committees and at
                    least two other
                    <br />
                    members of the Executive Committee and any other Executive
                    Committee members who
                    <br />
                    ask to participate. For local and special district
                    elections, any House District Leader that
                    <br />
                    wishes to participate will be included as well. If a House
                    District does not have a leader,
                    <br />
                    up to two PCPs from that district can be appointed to
                    represent the House District.
                    <br />
                    b. The Endorsement Committee will convene to review and
                    discuss the questionnaires
                    <br />
                    submitted by candidates. They will be responsible for
                    follow-up questions and oral
                    <br />
                    interviews with candidates as needed or as time permits.
                    Whenever possible, candidates
                    <br />
                    who have not previously been interviewed by the DPCC should
                    be interviewed.
                    <br />
                    c. The Endorsement Committee shall make a recommendation for
                    each candidate of
                    <br />
                    Endorse or Do Not Endorse. Any decisions to not endorse must
                    be substantiated as
                    <br />
                    described in B.2.
                    <br />
                    3. Attaining Executive Committee Approval
                    <br />
                    a. The list of candidates reviewed in the vetting process
                    plus the recommendation of
                    <br />
                    Endorse or Do Not Endorse and the substantiations for each
                    recommendation shall be
                    <br />
                    submitted to the Executive Committee at its next meeting.
                    Notice shall be given on the
                    <br />
                    agenda that is circulated to the membership at least six (6)
                    days in advance before the
                    <br />
                    meeting.
                    <br />
                    b. Any recommendation of non-endorsement made by the
                    Endorsement Committee
                    <br />
                    requires a two-thirds majority vote of the Executive
                    Committee to confirm. If it is not
                    <br />
                    confirmed, the candidate will be forwarded with a
                    recommendation of Endorse.
                    <br />
                    c. Any member of the Executive Committee may recommend
                    non-endorsement of any
                    <br />
                    candidate. This must be substantiated as outlined in B.2,
                    and non-endorsement requires
                    <br />a two-thirds majority vote of the Executive Committee.
                    <br />
                    d. All remaining candidates who received a recommendation of
                    Endorse shall be
                    <br />
                    forwarded for consideration by the Central Committee at its
                    next regularly-scheduled
                    <br />
                    meeting.
                    <br />
                    4. Attaining Central Committee Approval
                    <br />
                    a. The list of candidates forwarded to the Central Committee
                    and the substantiations for
                    <br />
                    each recommendation will be included on the agenda
                    circulated to the membership at
                    <br />
                    least six (6) days in advance of the next regularly
                    scheduled meeting or any meeting
                    <br />
                    scheduled to endorse candidates.
                    <br />
                    b. Any member of the Central Committee may recommend
                    non-endorsement of any
                    <br />
                    candidate. This must be substantiated as outlined in B.2,
                    and non-endorsement requires
                    <br />a two-thirds majority vote of the Central Committee.
                    <br />
                    c. All remaining candidates who received a recommendation of
                    Endorse shall be
                    <br />
                    endorsed.
                    <br />
                    5.
                    <br />
                    <br />
                    Endorsement in local races shall be included on slate cards,
                    if they are printed or made available,
                    <br />
                    space permitting. Where lack of space is an issue, digital
                    references may be used.
                    <br />
                    <br />
                    D. Voting Procedures:
                    <br />
                    Votes to approve or withhold approval on endorsement or
                    non-endorsement of candidates will be
                    <br />
                    conducted by show of hands or standing count, or (at the
                    request of any member) by ballot.
                    <br />
                    &ldquo;Majority vote&rdquo; indicates more than half of
                    &ldquo;yes&rdquo; and &ldquo;no&rdquo; votes cast.
                    &ldquo;Two-thirds&rdquo; indicates at
                    <br />
                    least two-thirds of the total number &ldquo;yes and
                    no&rdquo; votes cast. For example, if 19 &ldquo;yes&rdquo;
                    or &ldquo;no&rdquo; votes
                    <br />
                    are cast, a majority of votes is 10 regardless of any
                    abstentions. If 90 people cast &ldquo;yes&rdquo; or
                    &ldquo;no&rdquo;
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    votes, a two-thirds vote is 60. (Robert&rsquo;s Rules of
                    Order, Newly Revised, 12th ed., 2020, p. 380, See
                    <br />
                    also Question 5 here:
                    <a
                      data-fr-linked="true"
                      href="https://robertsrules.com/frequently-asked-questions/"
                    >
                      https://robertsrules.com/frequently-asked-questions/
                    </a>
                    )<br />
                    Voting conducted in an electronic meeting will be subject to
                    the rules outlined in Standing Rule 11.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 10: PCP APPOINTMENT PROCEDURES
                    <br />
                    (January 16, 2020)
                    <br />
                    1. Applicants must:
                    <br />
                    a. Have been a registered Democrat for at least 180 days
                    prior to appointment
                    <br />
                    b. Be a registered voter in Clackamas County
                    <br />
                    2. Interested Democrats acquire applications:
                    <br />
                    a. On the DPCC web site:
                    <a
                      data-fr-linked="true"
                      href="http://www.clackamasdems.org/volunteer/"
                    >
                      http://www.clackamasdems.org/volunteer/
                    </a>
                    (preferred)
                    <br />
                    b. From any Clackamas County PCP
                    <br />
                    c. At the DPCC office: 609 Main Street, Oregon City, Oregon
                    97045
                    <br />
                    3. Interested Democrats submit completed applications:
                    <br />
                    a. By clicking &ldquo;SUBMIT&rdquo; on the DPCC web site
                    (preferred)
                    <br />
                    b. By mailing to PO Box 44, Oregon City, OR 97045
                    <br />
                    c. By handing to any PCP
                    <br />
                    d. By submitting to the Chair at any monthly Central
                    Committee meeting
                    <br />
                    4. DPCC Chair, with assistance of DPCC Secretary, reviews
                    submitted applications:
                    <br />
                    a. Checks name, address, party affiliation and length of
                    membership, district/precinct
                    <br />
                    information, Voter ID, Campaign ID, follows up on missing
                    information or other problems with
                    <br />
                    assistance of county clerk&rsquo;s office
                    <br />
                    b. Assigns applicant to the precinct in which the applicant
                    is registered; a precinct adjoining that
                    <br />
                    precinct within Clackamas county; or a precinct within the
                    county and same state
                    <br />
                    Representative district as the precinct in which the
                    applicant is registered
                    <br />
                    c. Sends copy of PCP applicant information to appropriate
                    HDL
                    <br />
                    d. Prepares list of PCP applicants monthly for review by
                    Executive Committee and approval by
                    <br />
                    Central Committee
                    <br />
                    (Note: Applications received fewer than seven days before
                    the Executive Committee meeting will
                    <br />
                    be processed the following month.)
                    <br />
                    5. HDL contacts prospective PCPs and
                    <br />
                    a. Provides basic information about being a PCP, including
                    mission statement of DPCC
                    <br />
                    b. Inquires about prospective PCP&rsquo;s interests and
                    discusses opportunities to participate in party
                    <br />
                    activities
                    <br />
                    c. Answers questions and confirms that the applicant is
                    interested in being a PCP
                    <br />
                    d. Notifies Chair that contact has been made
                    <br />
                    6. Executive Committee reviews monthly list of PCP
                    applicants
                    <br />
                    a. Considers information gathered by Chair and by HDL as
                    basis for recommendation
                    <br />
                    b. Forwards names of qualified Democrats interested in
                    becoming PCPs to the Central
                    <br />
                    Committee
                    <br />
                    7. Central Committee acts on recommendations of Executive
                    Committee
                    <br />
                    8. Secretary submits list of appointees to county
                    clerk&rsquo;s office for final verification
                    <br />
                    9. Approved applicant&rsquo;s term of office begins at next
                    Central Committee meeting (where new PCPs
                    <br />
                    receive lanyard with name card) and continues until 24 days
                    after next primary election
                    <br />
                    3/8/17 version, revised (1/16/2020) 62 PCPs voted on the
                    previous motion; this motion was passed on a<br />
                    voice vote with one &quot;no&quot; vote cast
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    Standing Rule 11: Conducting Electronic
                    <br />
                    <br />
                    or Hybrid Electronic/in Person Meetings that Comply with
                    <br />
                    Robert&rsquo;s Rules of Order, 12th Ed.
                    <br />
                    (November 18, 2021, rev. Sept. 2023)
                    <br />
                    Democratic Party of Clackamas County Bylaws Art.VI.A.7:
                    <br />
                    a. The Central Committee, Executive Committee, and other
                    committees and caucuses of
                    <br />
                    the DPCC may choose to meet in person, electronically, or in
                    hybrid format (in-person
                    <br />
                    with an option for members to access the meeting
                    electronically) if equipment and
                    <br />
                    personnel are available to support the mode of operation in
                    a way that &ldquo;provides, at a<br />
                    minimum, conditions of opportunity for simultaneous aural
                    communication among all
                    <br />
                    participating members equivalent to those of meetings held
                    in one room or area.&rdquo;
                    <br />
                    (RONR, 12th ed., 9:31) Normal notice and quorum requirements
                    will apply and voting is
                    <br />
                    restricted to DPCC PCPs members attending the meeting in
                    person or electronically.
                    <br />
                    b. Notification of the options available for attendance must
                    accompany notice of the
                    <br />
                    meeting and be sent at least six (6) days before the
                    meeting. If electronic connection is
                    <br />
                    available, instructions for connection must be circulated to
                    all members by email no later
                    <br />
                    than two (2) days before the meeting.
                    <br />
                    Standing Rules 11: Electronic Meetings*
                    <br />
                    1. All members attending a meeting must have access to hear
                    and speak in order to
                    <br />
                    participate in the business of the meeting. Members
                    participating in person shall use
                    <br />
                    traditional means of participation, while those connected
                    electronically shall have equal
                    <br />
                    access using the following procedures.
                    <br />
                    2. Members who have not been recognized by the chair shall
                    remain on Mute.
                    <br />
                    3. The meeting shall be open to attendees for check-in at
                    least fifteen (15) minutes before the
                    <br />
                    announced meeting time.
                    <br />
                    4. DPCC PCPs attending electronically shall register with
                    their full name and identify
                    <br />
                    themselves orally, when requested, to establish a quorum.
                    After a quorum is established
                    <br />
                    members may use the participant list to monitor the ongoing
                    presence of a quorum.
                    <br />
                    5. Guests (non-DPCC PCPs) shall identify themselves as such.
                    <br />
                    6. Members shall sign out when leaving the meeting before
                    adjournment.
                    <br />
                    7. Those participating electronically are responsible for
                    their own connections to the meeting.
                    <br />A member&rsquo;s loss of connection or inability to
                    hear shall not interfere with the ongoing
                    <br />
                    business of the meeting.
                    <br />
                    8. Members connected by electronic devices may cast votes
                    only on their own unique device.
                    <br />
                    For example, two members living in the same household must
                    register separately for
                    <br />
                    meetings and each use their own device (such as a computer
                    or a telephone) to sign in and
                    <br />
                    vote.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    9. The chair may disconnect or mute any device that is
                    interfering with a meeting, subject to
                    <br />
                    non debatable appeal by any member. The disconnection shall
                    be announced in the
                    <br />
                    meeting and noted in the minutes.
                    <br />
                    10. Video display shall include the chair and current
                    speaker as well as other meeting
                    <br />
                    participants to the extent feasible.
                    <br />
                    11. Members connected to a meeting using Zoom may:
                    <br />
                    a. Use the &ldquo;Raise Hand&rdquo; Zoom function to seek
                    the chair&rsquo;s recognition to speak. and
                    <br />
                    indicate their purpose as follows:
                    <br />
                    i. Make a motion (MM)
                    <br />
                    ii. Amend a motion (AM)
                    <br />
                    iii. Speak in favor of a motion (Pro)
                    <br />
                    iv. Speak against a motion (Con)
                    <br />
                    v. Raise a procedural question (Q)
                    <br />
                    vi. Make a point of order (PO)
                    <br />
                    vii. Make a point of personal privilege (PP)
                    <br />
                    b. Use the &ldquo;Chat&rdquo; Zoom function to submit
                    written text of motions or amendments.
                    <br />
                    Format: name, text of motion, and number of motions
                    previously submitted during the
                    <br />
                    meeting if more than one.
                    <br />
                    c. Not use the Chat function for personal conversation.
                    <br />
                    d. Directly address the chair if, while another member has
                    the floor, there is no timely
                    <br />
                    response by the chair to a request to raise a point of order
                    or point of personal
                    <br />
                    privilege.
                    <br />
                    12. The Recording Secretary shall display motions on the
                    screen.
                    <br />
                    13. Permitted voting methods shall include:
                    <br />
                    a. Unanimous consent, or acclamation in elections with only
                    one nominee for a<br />
                    position--if there is no objection.
                    <br />
                    b. Non-anonymous or anonymous poll.
                    <br />
                    c. Audible roll-call.
                    <br />
                    14. Procedural objection, including a quorum call, to any
                    vote is in order only prior to the
                    <br />
                    introduction of the next order of business unless the
                    objection involves an ongoing violation
                    <br />
                    (such as the ineligibility of a candidate for the office to
                    which they were elected).
                    <br />
                    15. Non-members present during a poll will be moved to a
                    breakout room or otherwise be
                    <br />
                    removed from access to the vote.
                    <br />
                    16. Meetings are recorded and the recording kept at least
                    until the minutes are approved at the
                    <br />
                    next meeting. The Chair and/or Electronic Secretary is
                    responsible for meeting file
                    <br />
                    management.
                    <br />
                    17. In the case of &ldquo;hybrid&rdquo; in-person and
                    electronic meetings, all members shall have equal
                    <br />
                    access to participation, to the extent possible.
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    *Note: These rules are specified in or implement
                    requirements in Robert&rsquo;s Rules of Order, 12th ed.,
                    <br />
                    especially pp. 638-641, and 2:16-2:22. As rules of order
                    adopted in the DPCC Bylaws, they require
                    <br />
                    advance notice and a two-thirds (2/3) majority of those
                    voting or majority of the entire membership
                    <br />
                    for amendment. They are included here to make them easily
                    accessible to all members as the
                    <br />
                    DPCC adapts to new meeting technologies. They have been
                    organized to facilitate ease of use.
                    <br />
                    Approved 11/18/21: 59 PCPs in attendance and voting, there
                    were no objections and Standing
                    <br />
                    Rules 11 passed.
                    <br />
                    <br />
                    Version 3- 11/30/2023
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default StandingRules;
