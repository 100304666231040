import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import PointList from "../../ui/PointList/PointList";

function CandidateResources() {
  const location = useLocation();

  const pageTitle = "Candidate Resources | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get helpful candidate resources to run an election campaign. Want to run for office in Clackamas County, get forms and instructions here."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Candidate Resources" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <PointList
          points={[
            {
              text: "Candidate Statements",
              scrollTarget: "candidate-statements",
            },
            {
              text: "Campaign Finance Information",
              scrollTarget: "campaign-finance",
            },
            {
              text: "Campaign Launch Resources",
              scrollTarget: "campaign-launch-resources",
            },
            {
              text: "Election Resources",
              scrollTarget: "election-resources",
            },
            {
              text: "Help and Training for First-Time Candidates",
              scrollTarget: "help-and-training",
            },
            {
              text: "Newly Elected Officials",
              scrollTarget: "newly-elected-officials",
            },
            {
              text: "Oregon State Candidate Help",
              scrollTarget: "oregon-state-candidate-help",
            },
          ]}
        />
        <section className="client-section w-100" name="candidate-statements">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Candidate Statements</h3>
                    <p>
                      Candidate statement filing fees, deadlines, completing the
                      voters pamphlet and sample candidate statements.
                    </p>
                  </div>
                  <div className="card-body p-3">
                    <ul>
                      <li>
                        <a href="https://www.clackamas.us/elections/candidate-filing-information">
                          Candidate Statement Filing Fees and Deadlines
                        </a>
                      </li>
                      <li>
                        Completing the{" "}
                        <Link to="/candidate-portal/candidate-communications-strategy">
                          voters pamphlet
                        </Link>
                      </li>
                      <li>
                        Info and dates for{" "}
                        <a href="https://dochub.clackamas.us/documents/drupal/d989ab18-2778-4976-aeaf-359145eab2ae">
                          candidate statements
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/Voters-Pamphlet-Translations-Quick-Guide.pdf">
                          Voters’ Pamphlet Translations Quick Guide
                        </a>
                      </li>
                      <li>
                        Sample{" "}
                        <Link to="/candidate-portal/candidate-communications-strategy">
                          candidate statements
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="campaign-finance">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Campaign Finance Information</h3>
                    <p>
                      Campaign finance manual, reporting and introduction to
                      ORESTAR.
                    </p>
                  </div>
                  <div className="card-body p-3">
                    <ul>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/campaign-finance.pdf">
                          Campaign Finance Manual
                        </a>
                      </li>
                      <li>
                        <a href="https://cms4files.revize.com/sutherlinor//6.%20Finance%20quickguide.pdf">
                          Campaign Finance Reporting
                        </a>
                      </li>
                      <li>
                        <Link to="/candidate-portal/candidate-training">
                          Introduction to ORESTAR
                        </Link>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/orestarTransFiling.pdf">
                          ORESTAR User's Manual: Transaction Filing
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/orestarSOO.pdf">
                          ORESTAR User's Manual: Statement of Organization​
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="campaign-launch-resources"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Campaign Launch Resources</h3>
                    <p>
                      Build a website, accept online donations, create and print
                      handouts, literature and signs to launch and expand your
                      campaign with these budget-friendly resources.
                    </p>
                  </div>
                  <div className="card-body p-3">
                    <ul>
                      <li>
                        <p>Campaign Software</p>
                        <ul>
                          <li>
                            <a href="https://www.campaignpartner.com/">
                              Campaign Partner
                            </a>
                          </li>
                          <li>
                            <a href="https://www.onlinecandidate.com/">
                              Online Candidate
                            </a>
                          </li>
                          <li>
                            <a href="https://www.trailblz.com/political-campaign-software">
                              Trail Blazer Campaign Services
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Graphic Design</p>
                        <ul>
                          <li>
                            <a href="http://jmbell.org/">Jeff Bell</a>
                          </li>
                          <li>
                            <a href="http://cheevers.com/">Tom Cheevers</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Online Donations</p>
                        <ul>
                          <li>
                            <a href="https://secure.actblue.com/">Act Blue</a>
                          </li>
                          <li>
                            <a href="https://www.c-esystems.com/">
                              C&E Systems
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Union Printers</p>
                        <ul>
                          <li>
                            <a href="https://www.himpress.com/">
                              Hollywood Impress Printing
                            </a>
                          </li>
                          <li>
                            <a href="https://www.stlshirtco.com/union-print-shop/portland-or/">
                              STL Shirt Co.
                            </a>
                          </li>
                          <li>
                            <a href="https://morelink.com/">Union Printers</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Website Design</p>
                        <ul>
                          <li>
                            <a href="http://jmbell.org/">Jeff Bell</a>
                          </li>
                          <li>
                            <a href="https://www.campaignpartner.com/">
                              Campaign Partner
                            </a>
                          </li>
                          <li>
                            <a href="https://www.onlinecandidate.com/">
                              Online Candidate
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="election-resources">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Election Resources</h3>
                    <p>Forms and general resources for elections.</p>
                  </div>
                  <div className="card-body p-3">
                    <ul>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/electionforms.aspx">
                          Election forms
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/voting-elections/Pages/default.aspx">
                          General resources for elections ​​
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/open-offices.pdf">
                          2024 Primary​ Election Open Offices
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-section w-100" name="help-and-training">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Help and Training for First-Time Candidates</h3>
                  </div>
                  <div className="card-body p-3">
                    <h4>Candidate Training Resources</h4>
                    <p>Training resources for first-time candidates.</p>
                    <ul>
                      <li>
                        <a href="https://traindemocrats.org/candidates/">
                          DNC candidate training
                        </a>
                        - National Democratic Training Committee
                      </li>
                      <li>
                        <a href="https://wp.dpo.org/training-materials/candidate-trainings/?emci=aad8825e-28bd-ed11-a8e0-00224832e811&emdi=ff879a31-44bd-ed11-a8e0-00224832e811&ceid=1770428">
                          DPO candidate training
                        </a>
                        - Democratic Party of Oregon Candidate Trainings
                      </li>
                      <li>
                        <a href="https://www.oregonlaborcandidateschool.org/">
                          Oregon Labor Candidate School
                        </a>
                        - Info on how to run for office, resources and support.
                      </li>
                      <li>
                        <a href="https://repower.org/">Repower</a>- Paid
                        training for progressive candidates.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>Democratic Women Candidate Training</h4>
                    <p>
                      Training for democratic women candidates. Before you pay
                      for training, note, you may get free training from
                      supporters or donors.
                    </p>
                    <ul>
                      <li>
                        <a href="https://emergeamerica.org/">Emerge America</a>-
                        Paid training for Democratic women.
                      </li>
                      <li>
                        <a href="https://www.emilyslist.org/">EMILY’S List</a>-
                        Free candidate training for pro-choice Democratic women.
                      </li>
                      <li>
                        <a href="https://www.runningstart.org/">
                          Running Start
                        </a>
                        - For young women interested in running; includes some
                        free training and some paid training.
                      </li>
                      <li>
                        <a href="https://sheshouldrun.org/">She Should Run</a>-
                        Free and paid training for women who want to run.
                      </li>
                      <li>
                        <a href="https://www.voterunlead.org/">VoteRunLead</a>-
                        Some free training and some paid training for women who
                        want to run for office.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>NGP Van</h4>
                    <p>Information about using NGP VAN and VAN training.</p>
                    <ul>
                      <li>
                        <a href="https://vimeo.com/799719522/28ab819503?mkt_tok=NzI5LUhURC05ODIAAAGKFDrz-GrY45kcQtY6wcmo29fefhwAU-P5sUmVxpGiy4Bx6o0NoruPSrk1Jx99ppWU4h9S000sj6sgZpw4fsaBxf2jNdAk5WZ9NzByjfzyiko">
                          Become an expert digital narrator: the steps to great
                          storytelling
                        </a>
                      </li>
                      <li>
                        <a href="https://dpo.org/candidate/">
                          DPO Candidate VAN Portal
                        </a>
                      </li>
                      <li>
                        <a href="https://youtu.be/H8iGfhJxuzg">
                          Introduction to VAN
                        </a>
                      </li>
                      <li>
                        <a href="https://wp.dpo.org/training-materials/">
                          VAN Training Portal
                        </a>{" "}
                        (password is “democrats”)
                      </li>
                      <li>
                        <Link to="/candidate-portal/candidate-training">
                          Using NGP VAN
                        </Link>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>Running for a School Board</h4>
                    <p>Learn more about running for a school board.</p>
                    <ul>
                      <li>
                        <a href="https://www.osba.org/Programs/BoardDevelopment.aspx">
                          Board development services
                        </a>{" "}
                        from the Oregon School Board Association.
                      </li>
                      <li>
                        <a href="https://osba.org/board-training-workshops/">
                          Board training - workshops
                        </a>{" "}
                        from the Oregon School Board Association.
                      </li>
                      <li>
                        <a href="https://getonboardoregon.org/">Get on Board</a>
                        - Become a voice for Oregon students.
                      </li>
                      <li>
                        <a href="https://www.doj.state.or.us/oregon-department-of-justice/public-records/public-records-and-meetings-law/">
                          Public Records and Meetings Law - Oregon Department of
                          Justice
                        </a>
                        - Public meeting laws set by the Oregon Department of
                        Justice.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>Running for Office in Oregon</h4>
                    <p>
                      Helpful guidelines and information about running for
                      office in Oregon.
                    </p>
                    <ul>
                      <li>
                        <a href="https://www.clackamas.us/elections/forms.html">
                          Filing for Candidacy in Clackamas County
                        </a>
                      </li>
                      <li>
                        <a href="https://ballotpedia.org/How_to_run_for_office_in_Oregon">
                          How to run for office in Oregon- Ballotpedia
                        </a>
                      </li>
                      <li>
                        <a href="https://oregoncapitol.com/engage-run-for-office/">
                          Oregon State Legislature Run for Office Information
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/manuals-tutorials.aspx">
                          Oregon Voting and Elections Manuals and Quick Guides
                        </a>
                      </li>
                      <li>
                        <a href="https://www.portland.gov/elections/city-office-candidacy">
                          Portland.gov Run for City Office
                        </a>
                      </li>
                      <li>
                        <a href="https://www.eugene-or.gov/DocumentCenter/View/28908/So-You-Want-to-Run-For-Local-Office-League-of-Oregon-Cities?bidId=">
                          So You Want to Run for Local Office- League of Oregon
                          Cities manual
                        </a>
                      </li>
                      <li>
                        <a href="https://www.sdao.com/board-member-recruitment-toolkit">
                          Special Districts of Oregon board member recruitment
                          toolkit
                        </a>{" "}
                        includes documentation about what a board member does
                        and how to be an effective board member.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>Voting Trends</h4>
                    <p>
                      <a href="https://drive.google.com/file/d/19XLeA567BU0Y8k8ghanQ8dxqeVU32kO_/view?usp=share_link">
                        Clackamas County Special Election 2021 Results Analysis
                      </a>
                      - Review past election results to understand more about
                      voting trends in Clackamas County.
                    </p>
                    <br />
                    <br />
                    <h4>Young Democrats Candidate Training</h4>
                    <p>
                      <a href="https://runforsomething.net/">
                        Run For Something
                      </a>
                      - For age 35 and under and a first-time progressive
                      candidate- help with networking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="newly-elected-officials"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Newly Elected Officials</h3>
                  </div>
                  <div className="card-body p-3">
                    <h4>Oregon Government Ethics Commission</h4>
                    <p>
                      Guidelines from the Oregon Government Ethics Commission.
                    </p>
                    <ul>
                      <li>
                        <a href="https://www.oregon.gov/ogec/Pages/public-officials.aspx">
                          Public Officials
                        </a>{" "}
                        Ethical Code of Conduct- There are some requirements
                        from the Oregon Government Ethics Commission, such as
                        filing the economic interest statement once elected and
                        following guidelines as public officials.
                      </li>
                      <li>
                        View the{" "}
                        <a href="https://www.oregon.gov/ogec/Documents/2021%20PO%20Guide%20Final%20Adopted.pdf">
                          Guide for Public Officials
                        </a>
                        , updated April 30, 2021.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <h4>Statement of Economic Interest</h4>
                    <p>
                      Be aware that once elected, you may need to file a
                      Statement of Economic Interest
                    </p>
                    <ul>
                      <li>
                        <a href="https://www.oregon.gov/ogec/public-records/Pages/SEIS.aspx?utm_medium=email&utm_source=govdelivery">
                          Here
                        </a>{" "}
                        are details on SEI requirements.
                      </li>
                      <li>
                        <a href="https://www.oregon.gov/ogec/training/Pages/SEI-Filer-Training.aspx">
                          SEI Filer Training
                        </a>{" "}
                        - Oregon Government Ethics Commission has online
                        training, a video tutorial and an instructional guide.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="oregon-state-candidate-help"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Oregon State Candidate Help</h3>
                    <p>Information for Oregon state candidates.</p>
                  </div>
                  <div className="card-body p-3">
                    <ul>
                      <li>
                        <a href="https://secure.sos.state.or.us/orestar/CFSearchPage.do">
                          Candidate filing search
                        </a>{" "}
                        -See who filed for an Oregon state, federal, judicial or
                        district attorney position.
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/county-city-district-candidates.pdf">
                          County, City and District Candidate Manual​​​
                        </a>
                        - Getting started
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/filevoterspamphlet.aspx">
                          File a Statement/Argument in the Voters' Pamphlet
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/campaigncommittee.aspx">
                          Form a Committee
                        </a>
                        - Set up a political committee
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/form-political-party.aspx">
                          Form a Party
                        </a>
                        - Form a political party
                      </li>
                      <li>
                        <a href="https://dochub.clackamas.us/documents/drupal/d989ab18-2778-4976-aeaf-359145eab2ae">
                          Joint County Voters’ Pamphlet Candidate Statement
                        </a>
                        - Use this form when filing a ‘JCVP-01 Joint County
                        Voters’ Pamphlet Candidate Statement’ with your County
                        Elections office.
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/open-offices.pdf">
                          List of open offices in Oregon
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/pages/runforoffice.aspx">
                          Oregon Run for Public Office website
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Documents/statecandidates.pdf">
                          Oregon state candidate manual
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/recall.aspx">
                          Recall an Elected Official
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/election-law-violation.aspx">
                          Report An Election Law Violation
                        </a>
                      </li>
                      <li>
                        <a href="https://sos.oregon.gov/elections/Pages/runforoffice.aspx">
                          Run for Public Office
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}

export default CandidateResources;
