import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Client from "../layouts/Client";
import Content from "../sections/blogdetail/Content";

import { useParams } from "react-router-dom";

function Blogdetail() {
  const params = useParams();
  return (
    <Fragment>
      <MetaTags>
        <title>DPCC | {params.title}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      {/* <Breadcrumbs breadcrumb={{ pagename: "Article" }} /> */}
      <Content title={params.title} />
      {/* <section className="client-section">
        <div className="container">
          <div className="client-slider section-gap">
            <Client />
          </div>
        </div>
      </section> */}
      <Footer />
    </Fragment>
  );
}

export default Blogdetail;
