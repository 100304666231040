import React from "react";
import { Link } from "react-router-dom";

function Content() {
  return (
    <section className="client-section p-5">
      <div className="card">
        <div className="card-header">
          <h3 className="text-center">Join our Central Committee Meetings</h3>
        </div>
        <div className="card-body p-3 text-center">
          <p>
            Get info about meetings <Link to={"/events-calendar"}>here</Link>
          </p>
        </div>
        {/* <div className="card-body p-3 text-center">
          <h4>Next Meeting</h4>
          <p>May 19, 7:00 PM</p>
          <a href="https://us02web.zoom.us/j/88050556454" target="_blank">
            Join on Zoom
          </a>
        </div> */}
      </div>
    </section>
  );
}

export default Content;
