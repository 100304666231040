import HD18v1 from "./HD18.jpg";
import HD18pdf from "./HD18.pdf";
import HD26v1 from "./HD26-images/0001.jpg";
import HD26v2 from "./HD26-images/0002.jpg";
import HD26pdf from "./HD26.pdf";
import HD37v1 from "./HD37-images/0001.jpg";
import HD37v2 from "./HD37-images/0002.jpg";
import HD37pdf from "./HD37.pdf";
import HD38v1 from "./HD38-images/0001.jpg";
import HD38v2 from "./HD38-images/0002.jpg";
import HD38pdf from "./HD38.pdf";
import HD39v1 from "./HD39-images/0001.jpg";
import HD39v2 from "./HD39-images/0002.jpg";
import HD39pdf from "./HD39.pdf";
import HD40v1 from "./HD40-images/0001.jpg";
import HD40v2 from "./HD40-images/0002.jpg";
import HD40pdf from "./HD40.pdf";
import HD41v1 from "./HD41-images/0001.jpg";
import HD41v2 from "./HD41-images/0002.jpg";
import HD41pdf from "./HD41.pdf";
import HD48v1 from "./HD48.jpg";
import HD48pdf from "./HD48.pdf";
import HD51v1 from "./HD51-images/0001.jpg";
import HD51v2 from "./HD51-images/0002.jpg";
import HD51pdf from "./HD51.pdf";
import HD52v1 from "./HD52-images/0001.jpg";
import HD52v2 from "./HD52-images/0002.jpg";
import HD52pdf from "./HD52.pdf";

export const slateCards = {
    HD18: {
        v1: HD18v1,
        pdf: HD18pdf,
    },
    HD26: {
        v1: HD26v1,
        v2: HD26v2,
        pdf: HD26pdf,
    },
    HD37: {
        v1: HD37v1,
        v2: HD37v2,
        pdf: HD37pdf,
    },
    HD38: {
        v1: HD38v1,
        v2: HD38v2,
        pdf: HD38pdf,
    },
    HD39: {
        v1: HD39v1,
        v2: HD39v2,
        pdf: HD39pdf,
    },
    HD40: {
        v1: HD40v1,
        v2: HD40v2,
        pdf: HD40pdf,
    },
    HD41: {
        v1: HD41v1,
        v2: HD41v2,
        pdf: HD41pdf,
    },
    HD48: {
        v1: HD48v1,
        pdf: HD48pdf,
    },
    HD51: {
        v1: HD51v1,
        v2: HD51v2,
        pdf: HD51pdf,
    },
    HD52: {
        v1: HD52v1,
        v2: HD52v2,
        pdf: HD52pdf,
    },
}