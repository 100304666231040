import React from "react";
import { Link } from "react-router-dom";

function Merch(props) {

  const isMobile = window.innerWidth < 768;

  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div style={{
          width: isMobile ? "100%" : "50%",
        }}>
          <ul style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            listStyleType: "none",
            padding: 0,
          }} class="illustration-img">
            <li>
              <img src="/assets/shop/shirt-1.png" alt="shirt 1" />
            </li>
            <li>
              <img src="/assets/shop/shirt-10.png" alt="shirt 10" />
            </li>
            <li>
              <img src="/assets/shop/shirt-3.png" alt="shirt 3" />
            </li>
            <li>
              <img src="/assets/shop/shirt-4.png" alt="shirt 4" />
            </li>
            <li>
              <img src="/assets/shop/shirt-5.png" alt="shirt 5" />
            </li>
            <li>
              <img src="/assets/shop/shirt-6.png" alt="shirt 6" />
            </li>
            <li>
              <img src="/assets/shop/shirt-7.png" alt="shirt 7" />
            </li>
            <li>
              <img src="/assets/shop/shirt-8.png" alt="shirt 8" />
            </li>
            <li>
              <img src="/assets/shop/shirt-9.png" alt="shirt 9" />
            </li>
          </ul>
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Merch</span>
                <h2 className="title">
                  Check out our Merch!
                </h2>
              </div>
              <p className="mb-25">
                Every purchase helps us promote Democratic goals in Clackamas County.
              </p>
              <a href="https://secure.actblue.com/donate/clackdemsmerch" className="main-btn">
                Check it out!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Merch;
