import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import PointList from "../ui/PointList/PointList";

function Volunteer() {
  return (
    <Fragment>
      <MetaTags>
        <title>DPCC | Volunteer With Us</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Volunteer With Us" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-body">
                  <PointList
                    points={[
                      {
                        text: "Caucuses",
                        scrollTarget: "caucuses",
                      },
                      {
                        text: "Committees",
                        scrollTarget: "committees",
                      },
                      {
                        text: "DPCC Office Staff",
                        scrollTarget: "dpcc-office-staff",
                      },
                      {
                        text: "House District Leaders",
                        scrollTarget: "house-district-leaders",
                      },
                      {
                        text: "Neighborhood Leaders",
                        scrollTarget: "neighborhood-leaders",
                      },
                      {
                        text: "Precinct Committee Persons",
                        scrollTarget: "precinct-committee-persons",
                      },
                      {
                        text: "Subcommittees",
                        scrollTarget: "subcommittees",
                      },
                      {
                        text: "Organizational Leaders and Contact Links",
                        scrollTarget:
                          "organizational-leaders-and-contact-links",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="caucuses p-3 px-md-5" name="caucuses">
            <h2>Caucuses</h2>
            <p>
              We are currently looking for volunteers to help with the following
              caucuses. Please{" "}
              <a href="mailto:volunteer-team@clackamasdems.org">
                email the Volunteer Team
              </a>{" "}
              to apply for any of these committees or fill out our{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                Website Contact Form
              </a>
              :
            </p>
            <ul>
              <li>
                <strong>Black and Latinx Caucuses</strong> Train leaders in how
                to conduct effective outreach to Black and Latinx residents;
                develop strategies to involve more Black and Latinx residents
                involved in Clackamas County Democrats, and help ensure Black
                and Latinx voices are represented and their concerns are
                sufficiently addressed by our policies and goals.
              </li>
              <li>
                <strong>Education Caucus</strong> Monitor and share information
                about policies and trends that have an impact on school
                curricula and programs in Clackamas County schools; draft
                resolutions and strategies to take action when needed.
              </li>
              <li>
                <strong>LGBTQ Subcommittee</strong> Are you LGBTQIA+ or an ally
                and interested in joining a LGBTQ caucus in Clackamas County?
                This group will be working toward educational exchange,
                networking and advocate for equal rights. If you are interested
                in helping with bylaw creation, and volunteering{" "}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                  please contact us
                </a>
                .
              </li>
              <li>
                <strong>Veterans Subcommittee</strong> Advocating for Veterans
                in Clackamas County. We are always looking for volunteers and
                anyone interested in Oregon legislations that affect Veterans.
              </li>
              <li>
                <strong>Young Democrats Caucus</strong> Reaching out the youth
                of Clackamas County in order to give voice to the next
                generation and generations after us. Providing agency over the
                future they will inherit. Anyone that is committed to helping
                increase youth involvement in the Democratic Party is welcome.
              </li>
            </ul>
            <a href="mailto:volunteer-team@clackamasdems.org">
              Email the Volunteer Team
            </a>{" "}
            to apply for any of these committies or fill our our{" "}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
              Website Contact Form
            </a>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="committees p-3 px-md-5" name="committees">
            <h2>Committees</h2>
            <p>
              We are currently looking for volunteers to help with the following
              committees. Please{" "}
              <a href="mailto:volunteer-team@clackamasdems.org">
                email the Volunteer Team
              </a>{" "}
              to apply for any of these committees or fill out our{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                Website Contact Form
              </a>
              :
            </p>
            <ul>
              <li>
                <strong>Campaign Committee</strong> Do you want to help
                Democrats get elected? Do you like researching? Or are you a
                people person who likes to talk with others? Then maybe the
                Campaign Committee is right for you. This committee determines
                and executes strategies for getting Democrats elected in
                Clackamas County. This committee meets monthly and may have
                extra assignments near elections.
              </li>
              <li>
                <strong>Communications</strong> Graphic design, social media.
                This committee meets monthly on the last Wednesday evening.
              </li>
              <li>
                <strong>Finance/Fundraising Committee</strong> Do you like to
                plan events? Do you enjoy working with donors and speakers? Or
                do you have an aptitude for all things budgetary? Then this
                committee could use you. The Finance/Fundraising Committee
                proposes and reviews budgets and conducts fundraising events.
                This committee meets monthly.
              </li>
              <li>
                <strong>Justice</strong> This evolving Committee addresses
                issues at the intersection of social justice, racial justice,
                environmental justice and economic justice. The Justice
                Committee identifies priority issues that impact Clackamas
                County, and may include topics that extend beyond our borders
                with broader implications. Citizens of Clackamas County who are
                interested in analyzing, advocating, advising, and networking to
                work toward solutions are invited to join the Justice Committee.
                The Justice Committee will meet every second Wednesday from 5:30
                PM -7PM in a hybrid format. Join us-{" "}
                <a href="mailto:volunteer-team@clackamasdems.org">
                  email the Volunteer Team
                </a>
                for more info.
              </li>
              <li>
                <strong>Neighborhood Leader Committee</strong> Have you been a
                Neighborhood Leader and enjoyed it? Would you like to spread the
                word to others? Or are you a data expert who wants to learn more
                about the intricacies of VAN and turf cutting? The Neighborhood
                Leader Committee promotes and trains Neighborhood Leaders and
                tracks the effectiveness of the program with data. This
                committee meets monthly.
              </li>
              <li>
                <strong>Platform and Resolutions</strong> Help researching and
                drafting proposed party resolutions in coordination with other
                committees.
              </li>
              <li>
                <strong>Technical Operations</strong>Light computer skills
                required. There are a variety of technology-related tasks that
                we need help with, including helping administer surveys and
                organizing Google Drive files.
              </li>
              <li>
                <strong>Volunteer and Recruitment</strong> Composing and
                publishing this newsletter, leading efforts to recruit and
                retain volunteers, Neighborhood Leaders and PCPs. The Volunteer
                and Recruitment Committee has started a Facebook group in which
                we plan on sharing volunteer opportunities within the DPCC. We
                would like to provide a community where we can make friends and
                share outside resources and events that hold our shared values.{" "}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                  Contact us to volunteer.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div
            className="dpcc-office-staff p-3 px-md-5"
            name="dpcc-office-staff"
          >
            <h2>DPCC Office Staff</h2>
            <p>
              Consider joining the office support team by volunteering for a
              shift (or more) per month. Stop by when we’re open and we’ll show
              you all the magic that happens there. We’re scheduling at least
              two volunteers per shift, so we can use your help. Find our office
              hours{" "}
              <a href="https://docs.google.com/document/d/1M4vIl_HYhcMdiXKBn3qFYTb4NxTbQas59zcVyoIuR_w/edit">
                here
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div
            className="neighborhood-leaders p-3 px-md-5"
            name="neighborhood-leaders"
          >
            <h2>Neighborhood Leader Program</h2>
            <p>
              You can help grow the grassroots of the Democratic Party by
              joining our Neighborhood Leader Program. We will give you a list
              of 35 or so of your neighbors who are registered Democratic
              voters. You’ll also receive training, slate cards, candidate
              literature and similar materials to pass out to your list of
              voters.
            </p>
            <p>
              Won’t you join us and help promote our Democratic values and
              candidates in your neighborhood? Apply using our{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                Website Contact Form
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div
            className="precinct-committee-persons p-3 px-md-5"
            name="precinct-committee-persons"
          >
            <h2>Precinct Committee Persons (PCP)</h2>
            <p>
              A Precinct Committee Person is a voting member of the County
              Democratic Central Committee and represents their precinct in the
              Democratic Party. An elected PCP is eligible to participate in
              electing county officers and selecting county representatives to
              the congressional district and state level.
            </p>
            <p>
              Apply to be appointed using our{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfd9UxADWE5xGxReb_48nSzbx8p0KFOYWQMVz3sLEFDrLSfgA/viewform">
                PCP Application
              </a>
              .
            </p>
            <p>
              Are you already a Precinct Committee Person?{" "}
              <a href="https://www.clackamas.us/elections/may-21-2024-primary-election#:~:text=Information%20for%20Precinctcommittee%20Person%20(PCP)%20Positions">
                File for the 2024 election (locate your precinct for filing)
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="subcommittees p-3 px-md-5" name="subcommittees">
            <h2>Subcommittees</h2>
            <p>The DPCC is currently forming the following subcommittees:</p>
            <ul>
              <li>LGBTQIA+ Subcommittee</li>
              <li>Veterans Subcommittee</li>
            </ul>
            <p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                Please contact us
              </a>{" "}
              if you would like to volunteer for a subcommittee.
            </p>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div
            className="organizational-leaders-and-contact-links p-3 px-md-5"
            name="organizational-leaders-and-contact-links"
          >
            <h2>Organizational Leaders and Contact Links</h2>
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
            Click here to contact us
          </a>
          <p>
            We love hearing from you! Using our online{" "}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
              contact form
            </a>{" "}
            you can:
          </p>
          <ul>
            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform?usp=sf_link">
                Provide feedback and/or find out how to Donate
              </a>
            </li>
            <li>
              Subscribe to{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform">
                Our Newsletters
              </a>
            </li>
            <li>
              Join the{" "}
              <a href="https://www.clackamasdems.org/#/neighborhood-leaders-program">
                Neighborhood Leader Program
              </a>
            </li>
            <li>
              Join the{" "}
              <a href="https://www.clackamasdems.org/#/pcp">
                Precinct Committee Persons
              </a>{" "}
              programs
            </li>
            <li>
              Use this direct link to{" "}
              <a href="https://secure.actblue.com/donate/yeswecan-fund">
                Donate money
              </a>
            </li>
            <li>
              <a href="https://www.clackamasdems.org/#/house-district-leaders">
                Become a House District Leader
              </a>
            </li>
            <li>
              <a href="https://www.clackamasdems.org/#/standing-committee-and-caucuses">
                Join a committee or caucus
              </a>
            </li>
          </ul>
          <p>Find the contact you'd like on one of the following pages:</p>
          <ul>
            <li>
              <a href="https://www.clackamasdems.org/#/officers">Officers</a>
            </li>
            <li>
              <a href="https://www.clackamasdems.org/#/house-district-leaders">
                House District Leaders
              </a>
            </li>
            <li>
              <a href="https://www.clackamasdems.org/#/standing-committee-and-caucuses">
                Standing Committee and Caucus Chairs
              </a>
            </li>
            <li>
              <a href="https://www.clackamasdems.org/#/delegates">Delegates</a>
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Volunteer;
