import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import PointList from "../../ui/PointList/PointList";

function ShouldYouRun() {
  const location = useLocation();

  const pageTitle = "Should you run for office | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get advice from past candidates and elected officials. Before you decide if you should run for office, learn more about the process."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Should you Run?" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
        }}
      >
        <PointList
          points={[
            {
              text: "Tell us about the first time you ran for office.",
              scrollTarget: "tell-us-about-the-first-time-you-ran-for-office",
            },
            {
              text: "What lessons did you learn?",
              scrollTarget: "what-lessons-did-you-learn",
            },
            {
              text: "Would you do anything differently today?",
              scrollTarget: "would-you-do-anything-differently-today",
            },
            {
              text: "Did you work with the Democratic Party of Clackamas County on your election?",
              scrollTarget:
                "did-you-work-with-the-democratic-party-of-clackamas-county-on-your-election",
            },
            {
              text: "What advice would you give to a first-time candidate?",
              scrollTarget:
                "what-advice-would-you-give-to-a-first-time-candidate",
            },
            {
              text: "What do you wish you knew before you ran?",
              scrollTarget: "what-do-you-wish-you-knew-before-you-ran",
            },
            {
              text: "What information specific to Clackamas County should a candidate know?",
              scrollTarget:
                "what-information-specific-to-clackamas-county-should-a-candidate-know",
            },
            {
              text: "What are the advantages of running, even if you lose? What skills do you learn?",
              scrollTarget:
                "what-are-the-advantages-of-running-even-if-you-lose-what-skills-do-you-learn",
            },
            {
              text: "How much time does your current position take?",
              scrollTarget: "how-much-time-does-your-current-position-take",
            },
            {
              text: "What are the next steps a newly elected official should be thinking about?",
              scrollTarget:
                "what-are-the-next-steps-a-newly-elected-official-should-be-thinking-about",
            },
            {
              text: "Why should someone run for office?",
              scrollTarget: "why-should-someone-run-for-office",
            },
          ]}
        />
        <section className="client-section w-100">
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-body p-3">
                    <p>
                      So what’s it like to run for office? We spoke with five
                      experts – Dems who currently hold local or state office or
                      have recently. They all have excellent advice,
                      suggestions, cautionary tales and inspirational
                      experiences to share.
                    </p>
                    <br />
                    <p>We spoke with:</p>
                    <br />
                    <p>
                      <strong>Eric Delehoy</strong>, previously candidate for
                      Oregon House District 44; lost to an appointed candidate.
                      Precinct Committee Person and assistant District Leader
                      for HD 44
                    </p>
                    <br />
                    <p>
                      <strong>Mark Gamba</strong>, currently Oregon state
                      representative, House District 41; previously Mayor of
                      Milwaukie, Milwaukie City Councilor, member of the
                      Clackamas County Coordinating Committee and Metro Mayors’
                      Consortium-Executive Committee
                    </p>
                    <br />
                    <p>
                      <strong>Jan Lee</strong>, currently member of the
                      Clackamas Soil and Water Conservation District Board of
                      Directors; previously Sandy city council member, Oregon
                      state representative, chair of Clackamas County Democrats,
                      vice president of Oregon Trail Democrats, Clackamas County
                      Planning Commission
                    </p>
                    <br />
                    <p>
                      <strong>Robert Massey</strong>, currently Milwaukie City
                      Councilor; previously member of the Milwaukie Planning
                      Commission
                    </p>
                    <br />
                    <p>
                      <strong>Rob Wagner</strong>, currently Oregon state
                      senator, Senate District 19 and President of the Oregon
                      State Senate; previously member of the Lake Oswego School
                      Board
                    </p>
                    <br />
                    <p>Below are our questions and their responses.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="tell-us-about-the-first-time-you-ran-for-office"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Tell us about the first time you ran for office.</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Massey:</strong> I was engaged with the community,
                      and I was in my fourth year on the planning commissions. I
                      wanted to take on a wider variety of issues important to
                      Milwaukie. I talked with current and past city councilors
                      – which is really the best insight you’ll get – and people
                      were very forthcoming with providing their experience and
                      what steps to take.
                    </p>
                    <br />

                    <p>
                      There is a lot of information you need to access, like
                      online forms and fees. Filing and running involve quite a
                      bit of work, more than you might think. You have to keep
                      track of campaign contributions and report on them, and
                      you have to set up a campaign committee. You can do this
                      on your own or work with a company that does this for you.
                      My expenses were $1500 or so to run my campaign.
                    </p>
                    <br />
                    <p>
                      I was running against someone with a lot of name
                      recognition (Elvis Clark). He’s well-known and a prolific
                      writer and he often testifies in public meetings. So my
                      biggest issue was getting my name out there. I really
                      focused on name recognition.{" "}
                    </p>
                    <br />
                    <p>
                      I had a website that listed my policies so people could
                      learn more about me. You have to build a website, and that
                      takes time; spend time on this but not too much.{" "}
                    </p>
                    <br />
                    <p>
                      You also have to write for the voters pamphlet and meet
                      the word count. You also have to get endorsed by your
                      party if possible.
                    </p>
                    <br />
                    <p>
                      Special interest groups will also want to interview you
                      for a possible endorsement, and they might have a lot of
                      paperwork you have to fill out too. I have mixed emotions
                      about this process. The downside is they want you to
                      commit to various agendas, and I was generally
                      uncomfortable with it. I didn’t want to commit until I
                      heard all points-of-view, and I told one group that.{" "}
                    </p>
                    <br />
                    <p>
                      One of the more valuable things I did was to provide info
                      to Clackamas Review, and they turned it into a piece about
                      me and my opponent. That turned out to be very valuable. I
                      did not go door-to-door and did not think this was a good
                      time investment. I elected to meet people on the sidewalk
                      at the farmers market, and I wore a lanyard around my neck
                      and worked to get my name out there.{" "}
                    </p>
                    <br />
                    <p>
                      I got on the slate card, and I made that decision to get
                      my name in everyone’s house.{" "}
                    </p>
                    <br />
                    <p>
                      I took out an ad in the Clackamas Review, which was my
                      biggest expense - it was a quarter-page, and it got my
                      name and photo out there.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Gamba:</strong> I ran for City Council back in
                      2012. I had never run for office before and had no idea
                      what I was doing. My wife was my treasurer, and she said
                      she would do it once and then never again. The mayor
                      recommended I knock on doors, and the most common question
                      I had was “we have a city council??”
                    </p>
                    <br />
                    <p>
                      I raised about $3600.00. I did yard signs and a mailer and
                      knocked on doors whenever I could. I was working on a
                      feature film and that limited my time. It helped that I
                      was an active and outspoken planning commissioner.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Wagner:</strong> I have been very politically
                      active for a couple of decades, and then when Trump was
                      elected, I remember my daughter was weeping. I told her I
                      will do anything I can so you can be anything you want to
                      be. My mom and sisters who are teachers and they were
                      planning their trip to the march on Washington, D.C., and
                      I started planning my run for school board.
                    </p>
                    <br />
                    <p>
                      I had a lot of endorsements, I had interviews, I had 187
                      donors and raised more than $26k in a matter of weeks. I
                      ran unopposed.
                    </p>
                    <br />
                    <br />
                    <p>
                      Lee: The first time was for state legislator. I was in a
                      swing district – Happy Valley to Estacada – and it was a
                      contentious race. Future PAC spent a lot of time with me,
                      helping with the campaign. We had TV ads, radio talk show
                      interviews and knocked on lots and lots of doors.
                    </p>
                    <br />

                    <p>
                      I had a number of training sessions, including training
                      from lobbyists, training in speaking publicly, training
                      for the TV ads, training on how to modulate the ads. It
                      cost over $400,000 the second time I ran. Currently
                      Janelle Bynum has this seat (HD39), and she spent close to
                      a million dollars. Running in a swing district is very
                      difficult in terms of funding.
                    </p>
                    <br />
                    <p>
                      I had an advantage, because I had been doing lobbying
                      work, so I was able to get lobbyists’ support of money and
                      time.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Delehoy:</strong> Sometimes you run even though it
                      doesn’t matter, because it’s already been decided. We need
                      people outside of the organizations to run and to listen
                      to people, and that’s where I come from – outside of the
                      echo chambers. The candidate I ran against knew everyone,
                      and I was blown away by the huge wall that was built in
                      front of the campaign. I wasn’t able to tell my story to
                      the constituents. I felt like people should have been
                      given a choice, even though I didn’t think I’d win. I knew
                      I wouldn’t get it, when I heard the position would be
                      filled by an appointment.
                    </p>
                    <br />
                    <p>
                      It was an eye-opening experience. There were some
                      structural hurdles put in front of voters. People often
                      say: ‘That’s just politics.’ But does it have to be?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-lessons-did-you-learn"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>What lessons did you learn?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Gamba:</strong> The old school thought of working
                      your way up is right – learn the ropes and start at the
                      beginning. You don’t start by running for president.
                      People value seeing you work on other things -- whatever
                      you’re running for -- and that makes a big difference to a
                      whole lot of voters.
                    </p>
                    <br />
                    <p>
                      The voters’ pamphlet is pretty darn important. Getting
                      good endorsements and then writing a good voters pamphlet
                      pitch - particularly in local races like school boards and
                      other district elections. For a lot of people, the voters’
                      pamphlet is the only time they will see your name. It’s
                      important to be able to speak to them in that very short
                      form, and you have less opportunity to get your message
                      across than you do at someone’s door. You have to have a
                      good message and get it across to people in a real way.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Wagner:</strong> Talk to as many targeted voters
                      as you can. Grassroots, individual and institutional
                      backing is important, along with having a good platform.
                    </p>
                    <br />
                    <p>
                      You have a lot of gut-check moments. I remember going for
                      a senate appointment and talking to Precinct Committee
                      Persons. I wanted a break, but my wife said: “Get back out
                      there. It’s the day after Thanksgiving and everyone will
                      be home – get out there.”
                    </p>
                    <br />
                    <p>
                      You need Teflon for positive emotions, and velcro for
                      negative emotions. Lean into the positive conversations.
                      You really learn how to find your voice. You have to ask
                      yourself, “what do I really think about that, and what are
                      people saying?”
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Delehoy:</strong>Understand that it is difficult,
                      but eventually you’ll break through. That’s what is
                      important: We let people know what the real process is,
                      and then maybe they’ll want to change it.
                    </p>
                    <br />
                    <p>
                      You learn so much when you run a campaign. It’s a bringing
                      together of everything you’ve done. It’s people who have
                      supported you and lifted you up. It's about your
                      accomplishments and so much more. I was overwhelmed,
                      brought to tears when people told me no one had ever
                      knocked on their door before to discuss their candidacy.
                    </p>
                    <br />
                    <p>
                      On a gray morning I said, at least it's not raining, and
                      then it started pouring and one of the neighbors gave me
                      an umbrella. I used it for canvassing all day, and when I
                      returned the umbrella later, they said I had their
                      support. I met one woman whose brother was homeless and
                      living in his car in St. Johns and had been shot by two
                      teenagers two years before, and he died. The assailants
                      were caught but were underage. The system failed all of
                      them, the victim and the assailants. That was a moment
                      when you see how complicated it all is, and there were
                      many moments like that.
                    </p>
                    <br />
                    <p>
                      I had seven young people working on my campaign, and it
                      was a privilege to work with them. When I decided to end
                      my campaign, the hardest thing was telling them it was
                      ending. I outraised my opponent and had no money from
                      organizations. I couldn’t ask people to invest in
                      something I knew wasn’t going to happen.
                    </p>
                    <br />
                    <br />
                    <p>
                      I had a pretty good idea what I was getting into. Running
                      for office is a lot like seeking a job and going through
                      the interview process. You have to build your resume and
                      your talking points, and you have to go through a lot of
                      interviews with people. You need to be prepared, to have
                      developed position statements, so you can respond quickly
                      when someone asks you about an issue. You need to have an
                      “elevator speech” ready when someone answers a door or
                      asks you a question. It’s all about being prepared.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="would-you-do-anything-differently-today"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>Would you do anything differently today?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Massey:</strong> Probably not; I won by a wide
                      margin.
                    </p>
                    <br />
                    <p>
                      <strong>Gamba:</strong> Not that much, really. I thought
                      the yard signs were worth the money; people want to
                      support you, and it’s important to them to be able to
                      support you when you’re their candidate. Fundraising and
                      house parties are not the best way to raise money, but
                      they are a great way to meet and greet many people all at
                      once who want to meet with you.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Lee:</strong> There is so much you have to do a
                      certain way to be successful. If I was running today, I’d
                      have support from the local party. Back then there was
                      limited local level support.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Delahoy:</strong> When you start a campaign, you
                      have no idea what you’re getting into. What I learned was
                      to discover my own voice and be clear with that. My
                      campaign manager told me not to label myself and to
                      instead talk about what I believe and what I stand for
                      instead. And then the campaign became so enjoyable. I
                      wouldn't listen to consultants this time around. I
                      understand people in my district more than a consultant
                      would. I understand myself more.
                    </p>
                    <br />
                    <p>
                      I would have been braver and called the appointment out
                      for what it was. I would have said that the voters need to
                      decide this.
                    </p>
                    <br />
                    <p>
                      I think it’s really detrimental for elected officials and
                      organizations to engage in primaries. For example, I
                      didn’t get the Planned Parenthood endorsement, but I
                      greatly support womens’ reproductive autonomy. When you
                      have two strong candidates it doesn’t make sense to have
                      those groups involved. That’s what I wish was different.
                      How can we get some of the machinations out of a primary?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="did-you-work-with-the-democratic-party-of-clackamas-county-on-your-election"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      Did you work with the Democratic Party of Clackamas County
                      on your election?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Wagner:</strong> In the school board race I
                      didn’t, but I have been involved with the DPCC for a long
                      time. I was secretary in the 90s, and I was a delegate
                      years ago too. And I have always been a Precinct Committee
                      Person. When I was going for the senate appointment, PCPs
                      were critical. They are the ones who select a candidate
                      and forward it to the county. I talked to every PCP. I
                      spoke to the group once, and I asked them who knew me, and
                      they all raised their hands.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> Democratic endorsements outnumber
                      Republicans 2 to 1. I got the DPCC’s endorsement. DPCC
                      District Leaders know me because I am a Precinct Committee
                      Person and a Neighborhood Leader, so some of them
                      displayed my yard signs, which is also a great way to get
                      name recognition.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Lee:</strong> It would have been nice to have the
                      DPCC’s Neighborhood Leader program back when I ran for
                      legislature 20 years ago. We didn’t have that in Clackamas
                      County back then. I think that is a super tool to have
                      now. Social media is also available now. We didn’t have
                      that and other digital tools 20 years ago.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Gamba:</strong> I won my first mayoral race with
                      92% of the vote and then was voted back in with similar
                      numbers. When I needed the party really badly was when I
                      ran for the primary for Congress. I didn’t get it, but I
                      think I did get people to start thinking about incumbents
                      vs new candidates and ask themselves, if there is an
                      incumbent who doesn’t support our platform, should we
                      still endorse the incumbent?
                    </p>
                    <br />
                    <p>
                      What I learned from Jamie McLeod-Skinner’s race is that
                      Republicans are willing to utterly lie, and we have to
                      assume that going in. We believe if we tell people the
                      facts they will vote the right way, and that doesn’t work
                      when your opponent is willing to completely lie, and
                      nothing you say can counter that lie. We have to think
                      very hard about that as a party: How will we work to
                      counter straight-up lies?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-advice-would-you-give-to-a-first-time-candidate"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      What advice would you give to a first-time candidate?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Gamba:</strong> It depends on what they are
                      running for. I have had dozens and dozens of new people
                      come to me, and I always spend a couple of hours with them
                      to give them everything I can like introductions to the
                      people you need to talk with. We don’t do that as a party.
                      When you talk to voters, they think the party is choosing
                      who runs in the primaries, but we don’t do that, and as a
                      party we don’t talk to them or help them. Maybe in general
                      we do; but by then they have been through the gauntlet. I
                      have been helping candidates at every level since I became
                      mayor.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Lee:</strong> You have to have plans, you have to
                      be ready, you have to have thought out what your position
                      is and have your talking points ready on all the issues.
                      When I ran for legislature, I was putting out published
                      ads through a firm and putting out TV ads. I had so much
                      support, which is the one positive thing about running in
                      a swing district.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Wagner:</strong> Get some training. Learn about
                      your district, and go deep on that. Know what you are
                      running for and what you stand for. Get to know who the
                      players are, and be active in your community. It takes a
                      lot of grit. You have to be willing to spend the time and
                      be active out there.
                    </p>
                    <br />
                    <p>
                      Be really diligent and, depending on the size of your
                      community, you can really talk to a lot of people. You can
                      knock on as many doors as you want, and that costs nothing
                      but time.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> There’s going to be significant
                      time demands. You owe it to your constituents and yourself
                      to put in the time to be an effective and productive
                      officeholder. And be available to people.
                    </p>
                    <br />
                    <p>
                      Someone can call me or talk to me on the sidewalk, and
                      they will get the same response: I will recommend they
                      submit comments in writing even by email to city staff or
                      come to a public meeting and testify. We need to hear all
                      views before making a commitment.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Delahoy:</strong> Surround yourself with people
                      you know and trust. You need people to give you unfettered
                      advice. Be careful whom you trust. Be true to yourself and
                      your values. Then you can serve authentically. If you can
                      win by being yourself, that is the goal, but it’s hard to
                      do that.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-do-you-wish-you-knew-before-you-ran"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>What do you wish you knew before you ran?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Lee:</strong> I wish I knew how much time it took
                      for campaigning. If I’d known, I might not have done it.
                      Three to four hours campaigning a day and then hours on
                      the phone making fundraising calls.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> Serve on a board or commission
                      before you run, and see if you like it.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Gamba:</strong> When I ran for Congress,
                      fundraising was such a critical part of a race at that
                      level, and I had never had to do that before. All of my
                      earlier races I raised between $3-5000, and I did that
                      through phone calls. The sheer amount of effort you have
                      to put in to get your message out to those numbers of
                      voters is hard to believe until you have done it. I
                      started a year before the primary, and while I had a lot
                      of contacts going into the race, it was still super hard
                      to raise the kind of money it takes, especially if you’re
                      principled about what money you will accept. That makes it
                      really hard to raise the money you need to raise.
                    </p>
                    <br />
                    <p>
                      The experience set me up well for running for State
                      representative – that was a piece of cake compared to
                      running for Congress.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-information-specific-to-clackamas-county-should-a-candidate-know"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      What information specific to Clackamas County should a
                      candidate know?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Lee:</strong> You should know the logistics of the
                      county. You can learn about the economics and the makeup
                      of the residents on the county website. Learn about the
                      people you represent and what the issues important to them
                      are. The same is true with Sandy. I read the minutes from
                      past meetings from several years and talked to people to
                      learn more about the issues there.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Wagner:</strong> Be aware of multiple
                      constituencies, depending on where you are. A county race
                      is fascinating because it is hard to define. Know your
                      constituency, know what you are running for.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Gamba:</strong> The cities in Clackamas County
                      have a dinner every month with the mayors and city
                      councilors, and this is a way to network and get to know
                      each other, to share what you’re working on, what has been
                      successful and what hasn’t been successful. You can meet
                      people and find out what they are interested in.
                    </p>
                    <br />
                    <p>
                      It’s important to follow all the legal requirements to get
                      registered. Signatures are needed to even get on the
                      ballot, as defined by the city where you run, and
                      everything goes through the election board. Your city
                      residency had to be verified.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-are-the-advantages-of-running-even-if-you-lose-what-skills-do-you-learn"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      What are the advantages of running even if you lose? What
                      skills do you learn?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Delahoy:</strong> You come out with a lot of
                      confidence. It brings you back to you. I’m still that
                      scrawny, small-town farm boy.That’s my values and how I
                      navigate the world, and that never changes. That was super
                      powerful, and it made my family relationships better. If
                      you're running for the right reasons, you are gaining a
                      lot of perspective.
                    </p>
                    <br />
                    <p>
                      I would change the circumstance but I don't regret running
                      at all. Not at all.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Lee:</strong> The skills you develop as a result
                      of campaigning you can use in your life, in your job. You
                      gain confidence, develop speaking skills, get involved in
                      your community and get to work on local issues.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Wagner:</strong> In the “Red to Blue Program,” we
                      encouraged candidates to run even if we knew the numbers
                      weren’t there, which held opponents to account for their
                      votes. It’s important to get people to run for a lot of
                      different positions.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> You learn more through failure. I
                      gained confidence too since I ran against an opponent, so
                      I’ve been battle-tested.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="how-much-time-does-your-current-position-take"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>How much time does your current position take?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Lee:</strong> In the legislature it was full-time.
                      For the conservation district it’s about a day-and-a-half
                      a month. For city council it was three to four days a
                      month. We had committees we were on in Sandy that met once
                      a month.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> 15-20 hours a week; there are
                      meetings, and there is a lot of research and readings you
                      have to keep up with. We each have a portfolio of
                      commissions or boards (3 or 4 assignments) some can be
                      internal to the city, but some are regional, like Metro
                      transportation for example. Some of these can be time
                      demanding. Others include Milwaukie’s Audits board,
                      Regional Water Suppliers Board, various social service
                      consortiums. Some meet monthly, some meet quarterly, and
                      you have to be there to be heard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="how-much-time-does-your-current-position-take"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>How much time does your current position take?</h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Lee:</strong> In the legislature it was full-time.
                      For the conservation district it’s about a day-and-a-half
                      a month. For city council it was three to four days a
                      month. We had committees we were on in Sandy that met once
                      a month.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> 15-20 hours a week; there are
                      meetings, and there is a lot of research and readings you
                      have to keep up with. We each have a portfolio of
                      commissions or boards (3 or 4 assignments) some can be
                      internal to the city, but some are regional, like Metro
                      transportation for example. Some of these can be time
                      demanding. Others include Milwaukie’s Audits board,
                      Regional Water Suppliers Board, various social service
                      consortiums. Some meet monthly, some meet quarterly, and
                      you have to be there to be heard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="what-are-the-next-steps-a-newly-elected-official-should-be-thinking-about"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      What are the next steps a newly elected official should be
                      thinking about?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Lee:</strong> Continue to learn the issues and be
                      involved locally in the community. It’s important to
                      understand the budget and financial information. I have
                      been on various city budget and financial committees, and
                      that experience really helps you learn about your
                      community. I encourage people to look for city, county and
                      state committees that are available.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Massey:</strong> Network, get familiar with your
                      assignments and attend the meetings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="client-section w-100"
          name="why-should-someone-run-for-office"
        >
          <div className="container">
            <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
              <div className="col pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      Why should someone run for office? What are the benefits?
                    </h3>
                  </div>
                  <div className="card-body p-3">
                    <p>
                      <strong>Wagner:</strong> At the core it goes back to the
                      definition of community service in a democracy -
                      regardless of the position, school board or Congress. In
                      order to have a functioning democracy, we need people who
                      are engaged. The day that I walk into the Oregon State
                      Capitol, and I look at the rotunda, and this job loses its
                      sense of awe and responsibility and feels more of a job
                      than public service – that's the day I step down. It’s
                      really about giving back to your community, about public
                      service.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Delahoy:</strong> It’s a difficult decision to run
                      for office. If someone wants to make things better, and
                      they want to serve, they should run. They should think
                      about how they can change their district, not about the
                      next position you can run for. The deep problems we have
                      are not served by someone who is only in the role briefly.
                      I want to see someone who runs and focuses on the
                      difficult problems we need to solve. Don't run for
                      prestige - do it because you want to do the work.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Lee:</strong> Because you want to help. You want
                      to be part of the team working to help resolve issues and
                      to make life better for the constituents. Because you want
                      to serve, and not for any other reason. I’ve already
                      worked in natural resources, and the conservation board is
                      very aligned with what I support and know – that is very
                      important.
                    </p>
                    <br />
                    <br />
                    <p>
                      <strong>Gamba:</strong> In the best of all possible
                      worlds, people run for office because they believe they
                      can make a positive difference for their community. They
                      are willing to put in the time and the sacrifice for their
                      community. That is why people should run.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}

export default ShouldYouRun;
