import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import IMG2 from "../../../../assets/img/banner/IMG_5220_Original.jpeg";
import IMG3 from "../../../../assets/img/banner/IMG_6160_Original.jpeg";
import UnDoIt from "../../../../assets/img/UnDoIt_ClackamasDems.gif";

const bannerpost = [
  // {
  //   img: "https://i.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
  //   tag: "WORKING TOGETHER TO DO BETTER",
  //   title: "CLACKAMAS \n DEMOCRATS ",
  //   btntext: "Donate",
  //   btntext1: "Volunteer",
  // },
  {
    img: IMG2,
    tag: "WORKING TOGETHER TO DO BETTER",
    title: "CLACKAMAS \n DEMOCRATS ",
    btntext: "Donate",
    btntext1: "Volunteer",
  },
  {
    img: IMG3,
    tag: "WORKING TOGETHER TO DO BETTER",
    title: "CLACKAMAS \n DEMOCRATS ",
    btntext: "Donate",
    btntext1: "Volunteer",
  },
];
const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const styles = css`
  .single-banner {
    &:before {
      opacity: 0.1;
      background: lightblue;
    }
  }
`;

function Banner(props) {
  return (
    <section css={styles} className="banner-section">
      <Slider className="banner-slider" id="bannerSlider" {...settings}>
        {bannerpost.map((item, i) => (
          <div key={i}>
            <div
              className="single-banner"
              style={{ backgroundImage: "url(" + item.img + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <div className="banner-content">
                      <span
                        className="promo-text"
                        data-animation="fadeInDown"
                        data-delay="0.8s"
                      >
                        {item.tag}
                      </span>
                      <h1 data-animation="fadeInUp" data-delay="1s">
                        {item.title}
                      </h1>
                      <ul className="btn-wrap">
                        <li data-animation="fadeInLeft" data-delay="1.2s">
                          <a
                            href="https://secure.actblue.com/donate/yeswecan-fund"
                            className="main-btn main-btn-1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.btntext}
                          </a>
                        </li>
                        <li data-animation="fadeInRight" data-delay="1.4s">
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                            className="main-btn main-btn-3"
                          >
                            {item.btntext1}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-shapes">
                <div className="one" />
                <div className="two" />
                <div className="three" />
                <div className="four" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/*<div className="search-wrap">*/}
      {/*  <Link to="#" className="search-icon">*/}
      {/*    <i className="far fa-search" />*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </section>
  );
}

export default Banner;
