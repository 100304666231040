import joe_buck from "./Joe_Buck.PNG";
import ellen_rosenblum from "./Ellen_Rosenblum.PNG";
import ron_wyden from "./Ron_Wyden.PNG";
import ufcw_local from "./UFCW_Local_555.PNG";
import alex_rabchuk from "./Rabchuk.PNG";
import mike_persons from "./Mike_Persons.PNG";
import diane_wagner from "./Diane_Wagner.PNG";
import rob_wagner from "./Rob_Wagner.PNG";
import mark_meek from "./Mark_Meek.PNG";
import magmer_and_litt from "./Magmer_and_Litt.PNG";
import libra_forde from "./Libra_Forde.PNG";
import jeff_merkley from "./Jeff_Merkley.PNG";
import ibew_local from "./IBEW_Local.PNG";
import earl_blumenauer from "./Earl_Blumenauer.PNG";
import aft_oregon from "./AFT_Oregon.PNG";
import tina_kotek from "./Tina_Kotek.PNG";
import sue_hein from "./Sue_Hein.PNG";
import sonya_fischer from "./Sonya_Fischer.PNG";
import oregon_trail_dems from "./Oregon_Trail_Dems.PNG";
import nwappworks from "./Northwest_Appworks.PNG";
import nest_egg_real_estate from "./Nest_Egg_Real_Estate.PNG";
import mark_gamba from "./Mark_Gamba.PNG";
import jules_walters from "./Jules_Walters.PNG";
import jeff_reardon from "./Jeff_Reardon.PNG";
import dennis_and_charlotte_duncan from "./Dennis_and_Charlotte_Duncan.PNG";
import daniel_nguyen from "./Daniel_Nguyen.PNG";
import suzanne_bonamici from "./Suzanne_Bonamici.PNG";
import aaron_woods from "./Aaron_Woods.PNG";
import ken_humberston from "./Ken_Humberston.PNG";
import martha_schrader from "./Martha_Schrader.PNG";
import willamette_women_democrats from "./Willamette_Woman_Democrats.PNG";

const mappings = {
  joe_buck,
  ellen_rosenblum,
  ron_wyden,
  ufcw_local,
  alex_rabchuk,
  mike_persons,
  diane_wagner,
  rob_wagner,
  mark_meek,
  magmer_and_litt,
  libra_forde,
  jeff_merkley,
  ibew_local,
  earl_blumenauer,
  aft_oregon,
  tina_kotek,
  sue_hein,
  sonya_fischer,
  oregon_trail_dems,
  nwappworks,
  nest_egg_real_estate,
  mark_gamba,
  jules_walters,
  jeff_reardon,
  dennis_and_charlotte_duncan,
  daniel_nguyen,
  suzanne_bonamici,
  aaron_woods,
  ken_humberston,
  martha_schrader,
  willamette_women_democrats
};

export const sponsorImagesReplacer = Object.keys(mappings).map((key) => {
  return {
    find: `{{${key}}}`,
    replace: `
            <img src="${mappings[key]}" alt="${key}" />
        `,
  };
});
