import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/EventCalendar/Content";
import { useLocation } from "react-router-dom";

function EventsCalendar() {
  const location = useLocation();

  const pageTitle = "Events and Meetings Calendar | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Attend a Democratic Party of Clackamas County meeting or an event. Get to know your local Democrats and learn more about upcoming events."
        />
      </MetaTags>
      <Header />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default EventsCalendar;
