import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import { Link, useLocation } from "react-router-dom";

function Platform() {
  const location = useLocation();

  const pageTitle = "Our Platform | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County platform. We created the platform to explain the values that will guide Clackamas County Democrats into the future."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Platform" }} />
      <div className="container">
        <div className="row p-md-5 p-sm-0">
          <div className="col pt-3">
            <div className="card">
              <div className="card-header">
                <h2>2023 Democratic Party of Oregon Platform</h2>
              </div>
              <div className="card-body">
                <p>Preamble:</p>
                <p>
                  The Democratic Party of Oregon commits itself to the
                  principles and positions contained in this platform in order
                  to secure a more just and prosperous future for all Oregonians
                  across our 36 counties. We recognize and embrace our shared
                  rights and responsibilities. We affirm that every person is
                  guaranteed rights to life, liberty, equity, and the pursuit of
                  happiness. In exercising these rights for ourselves and
                  guaranteeing them for others, we respect the interdependent
                  web of life on this planet.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  We promise to work to uphold the protections granted to us by
                  our state and national constitutions and to act on our
                  responsibilities to ensure that &ldquo;We the People&rdquo;
                  govern this nation. We steadfastly oppose any attempt to
                  dilute those rights and liberties in the name of political
                  convenience or security. We affirm that the greatness of our
                  nation is in its diversity and its ability to move forward
                  with full understanding of our historical strengths and
                  weaknesses. We invite all Oregonians, wherever they may be, to
                  join us in affirming our determination to make and keep Oregon
                  a beacon of opportunity, acceptance and freedom.
                </p>
                <h1>Article 1: Economy &amp; Labor</h1>
                <p>
                  Oregon Democrats know that workers form the bedrock of our
                  economy. The labor contributed by workers allows for the
                  entrepreneurial drive of small businesses and the stability
                  offered by larger-scale community investments. As such, our
                  approach to solution-making must be all-encompassing, tackling
                  the historical damages caused by past policies, to enable the
                  full participation of all Oregonians in the economy. This
                  entails crafting meaningful legislation which addresses
                  pressing issues, including but not limited to the rapidly
                  increasing wealth gap, insufficient investment in basic social
                  programs and public infrastructure (especially in communities
                  of color), practices surrounding corporate subsidies,
                  childcare and housing insecurity, food insecurity, and
                  inequitable access to legal aid services. These injustices
                  persist due to intentional neglect, white supremacy, and
                  policies that currently disproportionately favor the
                  wealthy&ndash;exacerbating hardships felt by the poorest and
                  most vulnerable members of our state.
                </p>
                <p>
                  <br />
                </p>
                <p>Planks:</p>
                <ol start="1">
                  <li>
                    We believe that we must act urgently to address the growing
                    wealth inequality crisis in America. We support a minimum
                    wage tied to the cost-of-living in our communities and
                    believe that all workers should be paid a living wage.&nbsp;
                  </li>
                  <li>
                    We believe that locally-based realistic cost-of-living data
                    must be used to determine income limits for government
                    welfare programs. We oppose business practices that
                    circumvent labor and benefits laws that force workers onto
                    public assistance programs, passing tax burdens onto the
                    public.
                  </li>
                  <li>
                    We believe workers are the primary source of profits and
                    must be able to organize for collective bargaining free of
                    intimidation or coercion. We oppose legislation, policies,
                    and efforts that attempt to restrict these rights.&nbsp;
                  </li>
                  <li>
                    We believe that it is the duty of businesses to reinvest in
                    the community in which it operates, as the corporate form
                    takes full advantage of the stability provided by a
                    prosperous economy.
                  </li>
                  <li>
                    We support strong labor laws that protect workers,
                    especially agricultural, natural resource, seasonal and
                    migrant workers, who comprise the backbone of our
                    agricultural system. As these workers increasingly suffer
                    economic disruption due to climate change, their protection
                    is of the utmost importance.
                  </li>
                  <li>
                    We support expanding workplace rights and protections for
                    all workers, including independent contractors and gig
                    workers, in all employment contracts and company policies,
                    including flextime, hazard pay, compensatory time, fair
                    scheduling, work-life balance, worker classification, and
                    paid parental and family leave. We support the elimination
                    of at-will employment.
                  </li>
                  <li>
                    We believe that equal pay for equal work is essential to
                    achieving economic equality and support evidence-based
                    efforts to close gender and race wage gaps.
                  </li>
                  <li>
                    We believe the government plays a key role in creating and
                    supporting a vibrant economy through investments in
                    infrastructure and social services. We believe that all
                    businesses and individuals must pay their fair share in
                    taxes to support these systems because they benefit us all.
                  </li>
                  <li>
                    We believe all workers have the right to retire with
                    security and dignity. We fully support protections for
                    public retirement systems and maintain that all retirement
                    accounts should be fully protected. We support a secure
                    Social Security System with accurate cost-of-living
                    adjustments. We oppose changes reducing the standard of
                    living.
                  </li>
                  <li>
                    We support diverse, equitable, and high-quality educational
                    pathways to living wages for all Oregonians. We further
                    support the creation and maintenance of apprenticeship
                    programs for a wide range of occupations, incorporating
                    collaboration among schools, community colleges, unions, and
                    businesses. We oppose public funding of privately-controlled
                    apprenticeships and training programs.
                  </li>
                  <li>
                    We support strong governmental regulation to prevent and
                    correct monopolies and industries considered
                    &ldquo;too-big-to-fail&rdquo; because they represent
                    unacceptable economic risks to workers and their families.
                    We support efforts to cap the rampant wealth disparity, and
                    seek to create a more equitable Oregon for all workers.
                  </li>
                  <li>
                    We support efforts that will expand economic opportunities
                    to all corners of Oregon with a focus on creating
                    sustainable clean energy jobs that provide growth
                    opportunities for employers and employees alike. We support
                    incentives for businesses to meaningfully&nbsp;transition to
                    clean energy and climate-protective operations.
                  </li>
                  <li>
                    We affirm small businesses are critical to the success of
                    our economy and believe in supporting them by improving
                    access to resources that help them thrive. We support
                    programs that facilitate ownership and operating loans,
                    including but not limited to local farmers, ranchers,
                    foresters, and fishers.&nbsp;
                  </li>
                  <li>
                    We support prioritizing grants and support for small
                    businesses, especially those owned and operated by Black,
                    Indigenous, and other communities of color, who have
                    historically had less access to capital. We further oppose
                    tax structures that protect and insulate big business at the
                    expense of the taxpayer.
                  </li>
                  <li>
                    We recognize that poverty poses a direct, ongoing threat to
                    the exercise of freedom and dignity in a democratic society.
                    A strong, accessible safety net is crucial for the
                    well-being and economic security of all. Social programs
                    must never be privatized, and unnecessary burdens, including
                    means testing, must be eliminated.&nbsp;
                  </li>
                  <li>
                    We believe companies must be held responsible for their
                    impacts on Oregon&rsquo;s people and environment. We support
                    judicial dissolution for companies that intentionally cause
                    pollution, death or destruction, whether from products,
                    practices, or advertising.
                  </li>
                  <li>
                    We support prevailing wage laws and project labor
                    agreements. We support the establishment and organization of
                    worker cooperatives and worker-managed enterprises.
                  </li>
                  <li>
                    We believe Democratic political parties, PACs, legislators,
                    and campaigns should meet or exceed the planks of this
                    platform in their employment practices.
                  </li>
                </ol>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 2: Education</h1>
                <p>
                  Oregon Democrats believe public education is the foundation of
                  democracy and essential to an informed citizenry. We support
                  accessible, high-quality public education from preschool
                  through post-secondary, including college and trade programs.
                  Fully funded public education, in accordance with the Quality
                  Education Model, provides the basis for a well-functioning
                  economy and democracy and must be prioritized. School cultures
                  must highlight dynamic learning environments of engaged
                  learners and teachers, applying critical and creative thinking
                  that connects the classroom, the school, the home, and the
                  community. It is our belief that every student, irrespective
                  of neurodiversity, sexual orientation, gender expression,
                  race, ethnicity, cultural background, or socioeconomic status,
                  deserves a comprehensive education tailored to individual
                  needs. We believe schools must provide a safe environment
                  physically, emotionally, and intellectually, and that all
                  students and staff should feel safe, secure, and supported.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We believe in fully-funded, free public education from
                    preschool through the university level, as well as
                    apprenticeship programs.
                  </li>
                  <li>
                    We recognize that poverty impacts student learning and
                    opportunity. We support free, comprehensive, year-round
                    nutrition programs (including breakfast and lunch), and
                    access to transportation, childcare, technology, internet,
                    and other resources that overcome economic disparity.
                  </li>
                  <li>
                    We oppose efforts to weaken public education, including
                    efforts to intimidate or control school boards; harass and
                    dismiss educators without cause; ban books that show the
                    diversity of the human experience; and teach inaccurate or
                    narrow curricula for political ends.&nbsp;
                  </li>
                  <li>
                    We oppose the movement to undermine higher education quality
                    by over-using and under-compensating part-time faculty;
                    eliminating tenure for full-time faculty; and
                    deprofessionalizing teaching, through such practices as the
                    inappropriate use of artificial intelligence.
                  </li>
                  <li>
                    We oppose public funding for parochial and charter schools
                    managed by for-profit corporations. We oppose school
                    vouchers and tuition tax credit programs that divert public
                    resources to private and religious schools that are
                    unaccountable to taxpayers, undermine public school funding,
                    and create a two-tier education system.&nbsp;
                  </li>
                  <li>
                    We support publicly funded, full-day, full-year, universal
                    public preschool options. All preschool programs must be
                    child-centered, culturally appropriate, fully inclusive, and
                    designed to facilitate each child&apos;s physical,
                    socio-emotional, and cognitive development.
                  </li>
                  <li>
                    We support positive, collaborative, and inclusive
                    participation by all learning community members, including
                    teachers, administrators, staff, school board members, and
                    parents, in site-based and district-wide planning processes
                    to meet the needs of all students. We encourage student
                    participation where appropriate.
                  </li>
                  <li>
                    We support policies that ensure the safety of students and
                    staff in the classroom and on school grounds, and that
                    reduce bullying, intimidation, disrupted learning, and
                    excessive discipline. We support the complete protection of
                    students&rsquo; privacy, human rights, and civil
                    liberties.&nbsp;
                  </li>
                  <li>
                    We support the right of educational professionals to
                    collectively bargain for improved student learning
                    opportunities; working conditions, including class size and
                    caseload; wages and benefits; classroom safety; and job
                    security. Oregon should safeguard public school faculty and
                    staff from arbitrary dismissals to prevent educational
                    disruptions and financial harm to schools.
                  </li>
                  <li>
                    We support broad educational curricula including music and
                    the arts, civics, science, technology and engineering, math,
                    history, economics, languages, literacy in media and
                    climate, as well as other disciplines that develop
                    well-rounded members of society.
                  </li>
                  <li>
                    We endorse historically and scientifically accurate
                    instruction that is respectful of diverse student
                    populations and in service to mastering academic skills,
                    critical thinking, and information literacy. We believe
                    smaller class sizes and caseloads better meet student needs.
                    We support diverse assessment strategies throughout the year
                    to measure student proficiency.
                  </li>
                  <li>
                    We support inclusive learning environments. We advocate for
                    diverse educational settings, in which teachers and staff
                    mirror the student body.&nbsp;
                  </li>
                  <li>
                    We oppose the school-to-prison pipeline, as it
                    disproportionately affects Black, Indigenous, and other
                    students of color, causing irreparable harm to their
                    education. We also oppose police presence in schools, which
                    leads to increased trauma, arrests, and intimidation of
                    students, starting as young as age five.
                  </li>
                  <li>
                    We believe in educational curricula that include and promote
                    understanding of diverse historical scopes and perspectives,
                    including non-Western ways of learning and histories of
                    marginalized racial, ethnic, and religious groups as well as
                    those of disabled and queer populations.
                  </li>
                  <li>
                    We believe that in order to be fully informed participants
                    in society, students need comprehensive, science-based, and
                    age-appropriate education in such topics as sex education,
                    data and research literacy, evolution, and climate
                    science.&nbsp;
                  </li>
                  <li>
                    We believe educational programs must accommodate student
                    needs including those of second language learners, students
                    who experience neurodiversity, and talented and gifted
                    students. We support providing equitable opportunities,
                    access, and available resources, as required by the
                    Individuals with Disabilities in Education Act and the
                    Americans with Disabilities Act.
                  </li>
                  <li>
                    We believe in the use of consistent, transparent, and
                    evidence-based educational standards taught by licensed,
                    professional teachers. Students deserve to receive support
                    from educational assistants and professionally licensed
                    counselors, social workers, school nurses, mental health
                    providers, school psychologists, speech pathologists,
                    occupational therapists, who should earn a living wage.
                  </li>
                  <li>
                    We oppose predatory student loan practices and support the
                    elimination of existing student loan debt.
                  </li>
                  <li>
                    We believe that profound, transformative teaching, learning,
                    and research depend on a diverse community with broad
                    backgrounds and perspectives; this demands thoughtful
                    inclusion of diverse populations in admitting and retaining
                    students to institutions of higher learning. Education must
                    support our multicultural democracy.
                  </li>
                </ol>
                <p>
                  <br />
                </p>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 3: Environment</h1>
                <p>
                  Oregon Democrats recognize that the current climate emergency
                  requires immediate action to avoid catastrophic warming,
                  crossing tipping points, and triggering irreversible impacts
                  that threaten to make our planet uninhabitable. We support an
                  aggressive and robust timeline for implementing controls on
                  greenhouse gas emissions in all sectors of the economy. We
                  support improving Oregon&rsquo;s resilience to climate
                  threats, the elimination of &ldquo;forever chemicals&rdquo;
                  and plastics, and securing an environment free of nuclear
                  radiation. We encourage legislation that takes a proactive
                  approach to all chemicals and products placed in the consumer
                  sector. We advocate for environmental justice, equity, and
                  inclusion. We call for the centering and protection of
                  historically marginalized populations, particularly Indigenous
                  nations, in discussions and policies that affect the
                  environment, and demand that legislation include Free Prior
                  and Informed Consent of tribal nations. Our efforts to protect
                  the planet and save humanity from climate disasters must be
                  focused and measurable.
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We believe that all people have the inalienable right to a
                    healthy environment and accessible natural spaces. We
                    recognize the necessity of adequate clean air, clean water,
                    and healthy soils. Thriving oceans, estuaries, freshwater
                    ecosystems, rangelands, and forests are indispensable.
                  </li>
                  <li>
                    We believe that policies related to the environment and our
                    climate must be framed within our justice and equity goals,
                    including the just transition of displaced workers,
                    low-income communities and other historically marginalized
                    populations. These policies must address the intentional
                    placement of the dirtiest industrial practices near
                    marginalized communities.
                  </li>
                  <li>
                    We believe that rapidly reducing greenhouse gasses across
                    all sectors and systems is paramount; including aggressive
                    enforcement and monitoring for greenhouse gasses and all
                    other environmental hazards. The reduction of greenhouse
                    gasses will also demand an immediate divestment from fossil
                    fuels and a rapid transition away from fossil fuel
                    infrastructure.
                  </li>
                  <li>
                    We advocate for policies that prioritize the carbon storage
                    of Oregon&rsquo;s natural and working lands, while balancing
                    the needs of those who live on and work the land. We oppose
                    using Oregon&rsquo;s natural carbon storage as justification
                    for carbon emissions through schemes such as carbon offsets.
                  </li>
                  <li>
                    We believe we must act to protect native, keystone,
                    endangered, and threatened species through evidence and
                    equity-based efforts. All measures taken to protect and
                    manage Oregon&rsquo;s wildlife must include the free prior
                    and informed consent and sustained collaboration of
                    Oregon&rsquo;s tribal nations.
                  </li>
                  <li>
                    We believe public land, resources, and waterways are
                    sacrosanct and should be held and managed for the common
                    good. We encourage collaborative partnerships committed to
                    acquiring land as a preserve for the common good. Land use
                    regulations are essential for preserving farm, range, and
                    forests and managing transportation.
                  </li>
                  <li>
                    We believe that Oregon&rsquo;s ecosystems should be valued,
                    not by the amount of raw goods they offer, but by the
                    ecosystem services they provide to our world, including but
                    not limited to: habitat and biodiversity, oxygen production,
                    air and water purification.
                  </li>
                  <li>
                    We support policies that instill the sustainable management
                    of Oregon&rsquo;s resources through regenerative practices.
                    Further, we advocate for swift management of Oregon&rsquo;s
                    water crisis by: expanding and protecting watersheds and
                    aquifers; enacting water conservation, reclamation, and
                    retention policies; and ensuring policies are enacted to
                    prepare for periods of water scarcity.
                  </li>
                  <li>
                    We support statewide waste reduction and the expansion of
                    recycling and reuse programs, including rights to repair. We
                    strongly support the elimination of fossil-fuel based
                    plastics or other non-degradable products, beginning with
                    non-recyclable and single-use plastics and stringent
                    policies to regulate pollution.
                  </li>
                  <li>
                    We oppose massive rail transport of fossil fuels through our
                    state for export from it. We believe our state&rsquo;s
                    railways, rivers, and communities should not be threatened
                    by the possibility of explosive derailment of frequent oil
                    trains. We believe our leaders and regulatory bodies should
                    do more to reduce and withdraw from such oil transport.
                  </li>
                </ol>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 4: Foreign Policy and National Security</h1>
                <p>
                  Oregon Democrats recognize that our responsibilities extend
                  beyond state and national boundaries and that our security and
                  welfare require global cooperation. We believe that national
                  security also includes conditions within our own country
                  regarding dangers to democracy, domestic terrorism, and denial
                  of rights and opportunities. We believe in strong military and
                  cyber security defenses, while being supportive and observant
                  of the Universal Declaration of Human Rights, the Geneva
                  Conventions, and other legitimate international treaties. We
                  support the primacy of diplomacy and technological and
                  humanitarian assistance to combat global crises, with the use
                  of military power as a last resort. Our military should be
                  strong enough to protect us from foreign state and non-state
                  threats. Our ultimate foreign policy goal is a world free from
                  war and oppression.
                </p>
                <p>
                  <br />
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We pledge and affirm our commitments to honor international
                    treaty obligations including the nine core international
                    human rights instruments as published by the United Nations
                    Office of the High Commissioner of Human Rights.&nbsp;
                  </li>
                  <li>
                    We pledge and affirm our support for the Geneva and Hague
                    Conventions, the Rome Statute, the Nuremberg Principles, the
                    Uniform Code of Military Justice, the 1997 Anti-Personnel
                    Mine Ban and other conventions that seek to reduce the
                    impacts of warfare on civilian populations.&nbsp;
                  </li>
                  <li>
                    We pledge our support for upholding and strengthening
                    actions regarding the UN Framework Convention on Climate
                    Change and all subsequent agreements entered into by the
                    Contracting Parties, including the Paris Climate accords and
                    its successors. We believe climate change is a global
                    emergency with catastrophic national security implications.
                  </li>
                  <li>
                    We pledge to support and honor our commitments to
                    international treaties for self-defense and cooperation,
                    including our membership in the United Nations and the North
                    Atlantic Treaty Organization, close cooperation with Canada
                    and Mexico, and unapologetic support for vulnerable
                    democracies around the world.&nbsp;
                  </li>
                  <li>
                    We believe our military, foreign affairs, and intelligence
                    agencies ought to be fully representative of American
                    diversity including race, ethnicity, religion, age,
                    socio-economic status, sexual orientation, and gender
                    identity and expression.
                  </li>
                  <li>
                    We support the G7 Leaders&rsquo; Hiroshima Vision on Nuclear
                    Disarmament and share their &ldquo;commitment to achieving a
                    world without nuclear weapons with undiminished security for
                    all.&rdquo; We believe we must work globally toward nuclear
                    disarmament and abolition of all weapons of mass
                    destruction.
                  </li>
                  <li>
                    We support global trade given consumer, labor,
                    anti-corruption, and environmental protections that
                    guarantee the health and well-being of affected people and
                    places.
                  </li>
                  <li>
                    All federal service personnel, including National Guard,
                    Peace Corps, Foreign Service, etc. should be awarded their
                    earned benefits. We should provide combat veterans with
                    additional assistance in housing, healthcare, job training,
                    education, and reintegration into civilian life.
                  </li>
                  <li>
                    We oppose and condemn terrorism, genocide, torture, human
                    trafficking, sexual exploitation, discrimination, religious
                    persecution, political persecution, and unlawful and
                    wrongful dentention/imprisonment.
                  </li>
                  <li>
                    We support universal human rights; liberal, secular
                    democracy; and free, fair, democratic elections worldwide
                    that are free from corruption, violence, censorship,
                    intimidation, misinformation, and foreign
                    interference.&nbsp;
                  </li>
                  <li>
                    We support treaties maintaining the moon and solar system as
                    universal commons, including the 1967 Outer Space Treaty
                    governing peaceful exploration and use of space, opposing
                    weaponization and privatization of space and space
                    exploration, including near-earth orbits. We oppose
                    stationing weapons in space and military activities on
                    celestial bodies.
                  </li>
                  <li>
                    We support the use of foreign aid for purposes such as
                    global health, nutrition, education, cultural expression,
                    technological innovation, and anti-corruption and
                    anti-terrorism efforts. Aid should support local, resilient,
                    sustainable development.
                  </li>
                  <li>
                    We believe that all state actors (including those who
                    command and use armed forces and intelligence agencies) must
                    be held accountable to international humanitarian laws and
                    the accepted laws of war. We support active US participation
                    in the International Court of Justice.&nbsp;
                  </li>
                  <li>
                    We oppose the use of artificial intelligence and
                    preprogrammed autonomous weapons in warfare or other
                    conflict situations. The decision to fire or release a
                    weapon must always be made by a human being, because
                    computers cannot be held accountable for their actions.
                  </li>
                  <li>
                    We support humane immigration policies that provide clear
                    pathways to legal residence and citizenship for those who
                    seek it. We oppose the separation of children from parents
                    and other relatives during immigration processes.&nbsp;
                  </li>
                  <li>
                    &nbsp;We support asylum for those fleeing oppression,
                    violence, and climate change. We oppose unreasonable burdens
                    for asylum-seekers, including asylum policies that
                    presuppose ineligibility and forcing asylum-seekers to wait
                    or settle outside the countries whose refuge they seek.
                  </li>
                  <li>
                    We recognize the importance of our membership in
                    international organizations such as the United Nations, the
                    World Health Organization, the World Bank, and the
                    Organization for Economic Cooperation and Development. We
                    value the diplomatic importance of these organizations.
                  </li>
                  <li>
                    We support Congress asserting its constitutional role in
                    declarations of war consistent with the War Powers
                    Resolution of 1973. We believe that Authorizations for Use
                    of Military Force must have a narrow focus and should be
                    repealed once no longer necessary.
                  </li>
                  <li>
                    We support competitive defense procurement processes to
                    promote fair pricing, save taxpayer money, and provide our
                    armed forces with the best quality equipment and services.
                    We support the responsible disposition of military
                    equipment. We oppose the transfer of military equipment to
                    law enforcement agencies.
                  </li>
                  <li>
                    We support downsized national military spending, including
                    cutting billions from the defense budget and reallocating
                    these funds to domestic spending. Military spending must
                    never be a reason for austerity or reduced social spending.
                    America&rsquo;s commitment to &ldquo;promote the general
                    welfare&rdquo; is part of what makes America worth
                    defending.
                  </li>
                  <li>
                    We oppose aggression and unilateral use of force by
                    nation-states. We believe that sanctions must be carefully
                    targeted to minimize harm against civilian populations and
                    that military force should only be used as a last resort.
                  </li>
                  <li>
                    We support efforts to build durable peace worldwide. We
                    support international negotiations that include direct,
                    good-faith participation by the parties involved,
                    guaranteeing equality, health, security, and democracy for
                    all people.
                  </li>
                  <li>
                    We condemn Russia&rsquo;s invasion of Ukraine and continual
                    aggression against its neighbors.&nbsp;
                  </li>
                  <li>
                    We support domestic and foreign policies guided by human
                    rights and democracy and believe that the United States
                    should earnestly support all who work to uphold those
                    principles.
                  </li>
                  <li>
                    We support the principle of universal jurisdiction for
                    prosecuting human rights violations.
                  </li>
                  <li>
                    We oppose using all weapons and munitions that pose risks to
                    civilians and the environment during and after armed
                    conflict because they extend war trauma and environmental
                    damage. We believe these munitions should be eliminated as
                    part of a global effort to reach and maintain durable peace.
                  </li>
                  <li>
                    We believe the Department of Defense should reduce its
                    climate and environmental impacts.
                  </li>
                </ol>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 5: Healthcare and Public Health&nbsp;</h1>
                <p>
                  Oregon Democrats believe that society thrives when everyone
                  has access to a comprehensive universal public health and
                  services infrastructure, including universally accessible
                  medical, dental, hearing, vision and behavioral health. This
                  system must center on patient dignity and respect and should
                  include a strong social safety net that provides the basic
                  necessities of life. The absence of a robust, comprehensive
                  mental and behavioral healthcare system is leading to
                  preventable death and disability among
                  Oregonians&mdash;destroying lives, causing trauma, and
                  undermining the health and economy of the State. The absence
                  of equitable, evidence-based measures that counter serious
                  threats to public safety and public health is an avoidable and
                  immoral policy failure.
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We believe it is a human right to have access to
                    high-quality, evidence-based healthcare. We support the
                    adoption of a seamless, equitable, comprehensive
                    single-payer healthcare system that includes general,
                    preventative, emergency, gender-affirming, abortion,
                    fertility, and full-spectrum reproductive care, plus
                    medications, vision, dental, hearing, and behavioral and
                    mental health services.
                  </li>
                  <li>
                    We believe that anyone who is pregnant has the absolute
                    right to make informed decisions regarding their bodies and
                    deserve access to affordable, high-quality,
                    culturally-responsive care, including full-spectrum
                    reproductive services, such as abortion, contraceptive,
                    fertility, gender-affirming, and pregnancy care, without
                    requiring parental, judicial, guardian, or spousal consent
                    or notification.
                  </li>
                  <li>
                    We believe that adequate and safe food and nutrition through
                    local, state, and national level programs are key elements
                    of public health. Poverty, houselessness, and hunger are
                    unnecessary moral failings by our society.&nbsp;
                  </li>
                  <li>
                    We support funding scientific research into gun violence as
                    a public health issue. We respect the practices of hunting,
                    sport shooting, and legal carry for self defense, including
                    by marginalized people. We believe these practices can
                    co-exist with equitable, evidence-based policies to reduce
                    suicides, mass shootings, and street gun violence.
                  </li>
                  <li>
                    We support equitable, evidence-based measures to address
                    root causes of serious threats to public safety and public
                    health, including racism; gun violence and suicide; domestic
                    violence; houselessness and housing insecurity; poverty;
                    food insecurity; substance-use disorder; behavioral
                    addiction; epidemics; pandemics and endemics; and
                    disparities due to race, gender, and sexual identity.&nbsp;
                  </li>
                  <li>
                    We support the regulation and licensing of alternative
                    healing practices and medicines, including acupuncture,
                    medical cannabis, and psilocybin, particularly as they are
                    used to relieve intractable pain and lessen the impacts of
                    trauma.
                  </li>
                  <li>
                    We believe all healthcare providers must provide language
                    accessible and culturally sensitive services to their
                    patients and their patients&rsquo; families and caretakers.
                  </li>
                  <li>
                    We believe affordable access to needed prescription
                    medication should be a right for all Oregonians and support
                    authorizing state and federal governments to negotiate
                    prices with transparency.
                  </li>
                  <li>
                    We strongly promote the expansion of comprehensive, stable,
                    and easily accessible mental health services including
                    inpatient, outpatient, telemedicine, and residential care,
                    when needed, regardless of patient circumstances.
                  </li>
                  <li>
                    We believe approved and licensed vaccines are a critical
                    component necessary to protect public health, as well as
                    public education regarding the efficacy and safety of
                    vaccination programs.
                  </li>
                  <li>
                    We support robust collaboration between countries on
                    research efforts and treatment methods for communicable
                    diseases, and we believe&nbsp;that&nbsp;effective
                    cooperation between national health services to control the
                    spread of pandemic infections and improve health and
                    wellness for all.
                  </li>
                  <li>
                    We support having plentiful, fully-funded community health
                    centers to provide high-quality care across the state,
                    especially in rural areas and communities with more
                    vulnerable populations. Racial equity and justice in
                    healthcare must be followed by strategic planning,
                    allocation of resources, and dismantling white supremacy in
                    medical education.&nbsp;
                  </li>
                  <li>
                    We believe that racism is a public health crisis.&nbsp;
                  </li>
                  <li>
                    We believe that the scientific and healthcare communities
                    need to address systemic racial, gender, and cultural biases
                    in both care and research, in order to improve patient
                    outcomes, prevent human suffering, and ensure that all
                    people achieve self-determined lives.
                  </li>
                  <li>
                    We believe in prioritizing the prevention of childhood
                    traumas such as neglect, mental, physical and sexual
                    abuse,&nbsp;and family violence.
                  </li>
                  <li>
                    We believe that the right to informed consent and bodily
                    autonomy, including for end of life and palliative care, is
                    absolute and must not be broken, infringed or dishonored.
                  </li>
                  <li>
                    We believe that private insurance must not profit from
                    publicly-funded healthcare programs. Because increased
                    premiums burden all vulnerable populations, we believe that
                    any increase in Medicaid and Medicare premiums should be
                    fully offset by Social Security benefit increases over and
                    above the yearly cost-of-living increase.
                  </li>
                  <li>
                    We recognize that health misinformation is a serious threat
                    to the public and limiting the spread of misinformation and
                    disinformation is a moral and civic imperative.
                  </li>
                  <li>
                    We oppose harmful changes to publicly-funded health
                    insurance that increase patient costs, harm patient
                    outcomes, or reduce access to care.
                  </li>
                  <li>
                    We support mandating that providers maintain standardized
                    electronic health records with personal privacy,
                    securely-protected and real-time access for all providers
                    caring for a patient. We believe a seamless secure
                    electronic health record is a foundation for a
                    cost-effective, efficient, patient-centered system.
                  </li>
                  <li>We believe transphobia is a public health crisis.</li>
                  <li>
                    We believe that the full spectrum of gender-affirming care,
                    including but not limited to mental health care, puberty
                    blockers, hormone replacement therapy, electrolysis, laser
                    hair removal, voice training, and gender confirmation
                    surgeries, is a human right for all who so desire it and is
                    a critical element of bodily autonomy.
                  </li>
                  <li>
                    We believe that gun companies should be held liable for the
                    reckless use or negligent promotion and sales of their
                    products, just like any other business.
                  </li>
                  <li>
                    We believe that the federal government should ensure
                    adequate and timely care for service members exposed to
                    Agent Orange, depleted uranium, and other hazardous toxins
                    or poisons.
                  </li>
                  <li>
                    We believe that rights to healthcare enumerated in this
                    Platform apply to incarcerated people, detained immigrants,
                    and undocumented residents of our country. Moreover, the
                    health of everyone in our communities affects the health of
                    all.&nbsp;
                  </li>
                  <li>
                    We believe substance use disorder is a medical condition,
                    not criminal behavior. We support ongoing treatment
                    including harm reduction, withdrawal support, and recovery
                    services across Oregon.
                  </li>
                </ol>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 6: Human Rights</h1>
                <p>
                  Oregon Democrats champion inviolable Constitutional rights,
                  including the Bill of Rights and the Universal Declaration of
                  Human Rights, while resolutely demanding impartial treatment
                  for all. We believe in a society where everyone&rsquo;s rights
                  are protected&mdash;where we all have the freedom to pursue
                  happiness&mdash;and where equity and justice are the rule
                  instead of the exception. Therefore, we are committed to
                  eliminating oppression wherever and whenever it is found.
                </p>
                <p>Planks:</p>
                <ol start="1">
                  <li>
                    We believe Black Lives Matter. We understand that white
                    supremacy is deeply rooted in our society and justice
                    system. We are committed to supporting policies and laws
                    that break down racist structures and institutions because
                    they stand in the way of the pursuit of freedom and justice
                    for all.
                  </li>
                  <li>
                    We believe in the right of all people to live dignified
                    lives free from abuse, neglect, poverty, violence,
                    oppression, coercion, preventable death, police harassment,
                    and state-sanctioned killing. We support proactive,
                    evidence-based measures to prevent suffering before it
                    occurs and provide resources and relief afterward.
                  </li>
                  <li>
                    We believe that historically oppressed groups such as Black,
                    Indigenous and other communities of color, women, children,
                    elderly, the medically fragile, LGBTQIA+ people, and people
                    with disabilities deserve full protections under the law,
                    irrespective of legal or immigration status.
                  </li>
                  <li>
                    We believe bodily autonomy and personal agency are critical
                    to democracy. All people have procedural and substantive due
                    process rights that allow them to: vote, protest, protect
                    their privacy, defend against government detention and
                    coercion, marry, use contraception, engage in consensual
                    sexual relations, and easily access abortion services.
                  </li>
                  <li>
                    We believe people with disabilities, including incarcerated
                    individuals, retain their bodily autonomy and must have
                    access to the full spectrum of care they require, including
                    medical support services, home care services, employment
                    advocacy and accommodation, and the unflinching enforcement
                    of the Americans with Disabilities Act. We oppose ableism
                    and eugenics.
                  </li>
                  <li>
                    We reject and condemn all ideologies that perpetuate
                    discrimination, inequality and hate. We oppose racism,
                    misogyny, ableism, ageism, homophobia, transphobia,
                    xenophobia, antisemitism, Islamophobia, white supremacy,
                    fascism, and other ideologies that undermine social
                    wellbeing, freedom and safety.
                  </li>
                  <li>
                    We support transgender, nonbinary, gender-fluid, Two Spirit
                    and other genderqueer people. Gender identity must be
                    respected everywhere, including in prisons and schools. We
                    support suicide prevention programs and legal protections
                    for trans people. We condemn anti-trans oppression and
                    promote Oregon being a refuge for people persecuted by
                    anti-LGBTQ+&nbsp;legislation.
                  </li>
                  <li>
                    We believe everyone has the right to live free from the
                    influence of the religious or spiritual beliefs of elected
                    and public officials. People of any or no religion must have
                    full protection under the law, regardless of legal or
                    immigration status. The separation of church and state is
                    absolute.
                  </li>
                  <li>
                    We acknowledge that all incarcerated people retain their
                    human dignity and rights and demand that our justice system
                    shift its model toward proven systems that prioritize
                    restorative justice and rehabilitation. We oppose mandatory
                    sentencing, because it neither acknowledges mitigating
                    factors nor rewards positive behavior.&nbsp;
                  </li>
                  <li>
                    We believe that people have a right to be on public property
                    without harassment or exception. We oppose the
                    criminalization of&nbsp;houselessness, including so-called
                    &ldquo;camping bans.&rdquo; Loitering and vagrancy laws have
                    been used for centuries to deliberately criminalize poverty
                    and funnel people into forced labor.
                  </li>
                  <li>
                    We believe in the presumption of innocence until proven
                    guilty. Law enforcement professionals should afford due
                    process in all situations and should be held accountable
                    given the violation of these rights. We expect transparency
                    in operation and reporting from criminal justice and
                    carceral agencies.&nbsp;
                  </li>
                  <li>
                    We believe in the presumption of innocence, regardless of
                    ability to pay, which requires effective legal
                    representation for those accused of crimes.&nbsp;Public
                    defenders must have manageable caseloads, access to
                    resources, and receive the same pay as prosecutors.&nbsp;
                  </li>
                  <li>
                    We believe slavery is reprehensible, including in the
                    carceral system. Prisons and detention centers should never
                    be profit-based or privatized. The practice of using
                    involuntary, unpaid, and sub-minimum wage prison labor
                    upholds white supremacy and must be prohibited. We support
                    reparations for formerly enslaved African people and their
                    descendants.
                  </li>
                  <li>
                    We believe that all people have a basic right to the
                    necessities of life including a liveable planet; clean air
                    and water; adequate and safe food, housing, health care, and
                    open spaces; safe environments, bodily autonomy, and rest.
                  </li>
                  <li>
                    We affirm that rural and urban communities across Oregon are
                    interdependent on our state&rsquo;s human and natural
                    resources. Lack of available health care, child care,
                    emergency and mental health services are a persistent
                    struggle for rural areas. These inequities must be
                    recognized and addressed for the benefit of all Oregonians.
                  </li>
                  <li>
                    We acknowledge the unique cultural needs of Indigenous
                    children and other identity groups, especially within the
                    foster care system, and believe each child&rsquo;s
                    connections to their cultural communities and identity
                    should be supported and strengthened.&nbsp;
                  </li>
                  <li>
                    We believe the foster care system must prioritize
                    reunification with parents and connection with family and
                    culture, while elevating each child&apos;s best interests.
                    This includes proximity to their community and
                    state-supported fulfillment of their educational, emotional,
                    medical, and mental health needs.&nbsp;
                  </li>
                  <li>
                    We believe governments must honor tribal sovereignty and
                    jurisdiction as specified in both ratified and agreed-upon
                    treaties. We believe in honoring their relationship to the
                    land, including through collaborative measures to protect
                    and restore native habitats.
                  </li>
                  <li>
                    &nbsp;We believe that the prosecution of hate crimes should
                    always acknowledge hate as an aggravating factor and that
                    justice requires restoration that centers the victim while
                    not causing additional harm. We believe hatred is learned
                    and can be unlearned.
                  </li>
                </ol>
                <hr />
                <p>
                  <br />
                </p>
                <h1>Article 7: Infrastructure</h1>
                <p>
                  Oregon Democrats support maintaining and updating our
                  nation&rsquo;s infrastructure including: water, wastewater,
                  transportation, energy, and communication systems; public and
                  educational facilities; housing, food supply, health and
                  social support networks; parks and open spaces, environmental
                  monitoring, and the United States Postal Service. We support
                  new infrastructure projects that prepare for a future that
                  includes the realities of climate change, rapid technological
                  change, and increased worldwide interdependency. We support
                  state, national, and international pollution reduction
                  programs aimed at accelerating the transition to clean-energy
                  infrastructure. We believe in using the best peer-reviewed
                  science and technology to develop and maintain essential
                  infrastructure and to prevent and prepare to combat natural,
                  technological, public health, terrorist, or other
                  infrastructure disruptions. We believe that critical elements
                  of infrastructure, particularly relating to water supply,
                  transportation access, the internet, and hazard response must
                  remain in public ownership.&nbsp;
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We support the construction of modern, safe,
                    disaster-resilient, and public water and wastewater
                    infrastructure, with particular attention to rural
                    communities and those with aging or outdated infrastructure.
                    These projects must have stable funding to cover continuing
                    costs. We believe the nation&rsquo;s electrical
                    infrastructure can be both resilient against and designed to
                    not cause wildfires.
                  </li>
                  <li>
                    We support expanding safe, accessible, affordable, and
                    low-emission public transit systems. We support fareless
                    public transportation when practical. Because we believe
                    people of all ages, abilities, and socio-economic statuses
                    should be able to safely get where they need to go, we
                    support the comprehensive implementation of safe active
                    transportation routes.
                  </li>
                  <li>
                    We support strong energy efficiency and solar access
                    standards in residential, commercial, and industrial
                    construction; we support incentives to reduce energy usage
                    in new and existing structures. We support rapid transition
                    to clean, renewable energy infrastructure, including
                    electric vehicle charging stations. We oppose any expansion
                    of fossil-fuel infrastructure, including pipelines.
                  </li>
                  <li>
                    We support access to free, universally available, and secure
                    high-speed broadband internet under public control in all of
                    Oregon&rsquo;s 36 counties. This acknowledges the modern
                    necessity of access to the internet and allows rural
                    businesses to compete equitably with other parts of the
                    state and nation.
                  </li>
                  <li>
                    We support housing infrastructure that provides shelter for
                    everyone. We believe that housing is a human right, and
                    support evidence-based housing policies, such as Housing
                    First, to reduce human suffering, create and maintain
                    personal and family stability, and provide space for
                    additional resources.&nbsp;
                  </li>
                  <li>
                    We support caregivers as essential to our nation&rsquo;s
                    infrastructure and honor their labor as crucial to the
                    resilience and functioning of society. We believe all
                    caregivers should earn a living wage.&nbsp;
                  </li>
                  <li>
                    We support the transparent allocation of public funds to pay
                    for public infrastructure development, maintenance, and
                    upgrades. We oppose the privatization of public
                    infrastructure. &nbsp;
                  </li>
                  <li>
                    We believe all infrastructure projects must be undertaken
                    with full attention to economic impact, equity,
                    cost-benefit, and environmental impact and justice. We
                    believe in righting historical injustices impacting Black,
                    Indigenous, and other people of color communities, including
                    environmental racism and exposure to carcinogens.
                  </li>
                  <li>
                    We believe all infrastructure projects should be sustainable
                    and resilient to natural disasters, equipment failures,
                    hacking, public health crises, non-state interference, and
                    other disruptions. Recovery from such disasters should be
                    supported by federal, state, and local agencies. We believe
                    the government should support community-focused recovery
                    efforts and encourage individual preparedness.
                  </li>
                  <li>
                    We support efforts to start and maintain a state-owned
                    public bank designed to support community infrastructure
                    needs, raise revenue without raising taxes for middle and
                    low-income families, and eliminate the use of public dollars
                    to generate profits for Wall Street banks and their wealthy
                    out-of-state stockholders.
                  </li>
                  <li>
                    We support residential high density zoning, urban infill,
                    and transit-oriented development. We support urban growth
                    boundaries and oppose unmitigated urban sprawl, which is the
                    result of poor planning and threatens Oregon&rsquo;s
                    agricultural industries.
                  </li>
                  <li>
                    We recognize the importance of reestablishing tree canopy in
                    urban areas and support efforts to bolster urban forestry in
                    communities across Oregon.
                  </li>
                  <li>
                    &nbsp;We believe our cities and towns should be designed for
                    all people. Infrastructure shouldn&rsquo;t be used to deter
                    or hinder marginalized communities or unhoused people.&nbsp;
                  </li>
                  <li>
                    We believe society and government must work to end
                    environmental exploitation, including climate change. We
                    must engage in harm reduction that fully compensates for
                    past and continuing damage to their own residents as well as
                    to other communities.
                  </li>
                  <li>
                    We oppose tolling on Oregon&apos;s roads and highways.
                    Tolling is an inequitable solution that places a burden on
                    working people&apos;s daily lives instead of restricting the
                    conduct of the multinational corporations responsible for
                    the bulk of carbon pollution.
                  </li>
                </ol>
                <hr />
                <h1>
                  <br />
                </h1>
                <h1>Article 8: Protecting Democracy</h1>
                <p>
                  Oregon Democrats believe that our government derives its
                  authority and power from the people it governs as outlined in
                  the United States Constitution. We hold that all voting-age
                  citizens must have the right and responsibility to vote
                  without encumbrance in local and national elections. We
                  believe that neither socioeconomic status nor demographics
                  should be allowed to limit participation in and influence on
                  the political process. We are committed to open, responsive,
                  transparent and honest government. We believe that
                  corporations are not people and money is not speech;
                  corporations and other non-human legal entities have rights
                  that are defined and must be limited, in law, by the people.
                  We believe the government has a primary responsibility to
                  foster an economy and society that allows all people to
                  thrive. We recognize that sustaining our democracy in the face
                  of foreign and domestic challenges will always require
                  vigilance, common effort, and diligence.
                </p>
                <p>
                  <br />
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We believe fair access to voting, especially in underserved,
                    marginalized, and rural communities, is essential to
                    democracy. Consequently, we believe incarcerated citizens
                    should retain their right to vote before, during, and after
                    their sentences.
                  </li>
                  <li>
                    We support accurate, secure elections, increasing voter
                    participation, and eliminating voter suppression. We support
                    universal vote-by-mail elections and methods of voting that
                    diminish vote splitting and produce results that best
                    reflect the will of the voters.
                  </li>
                  <li>
                    We believe money is not speech and support campaign finance
                    reform, including small-donor and public financing, accurate
                    disclosures, and total dollar-raised limits. People, not
                    money, should determine election outcomes.&nbsp;
                  </li>
                  <li>
                    We commit to protecting constitutional rights, including the
                    right to assemble peaceably. We recognize the necessity of a
                    free press for the health of democracy. We affirm personal
                    religious freedom coupled with the separation of church and
                    state.
                  </li>
                  <li>
                    We believe some services are essential and must be offered,
                    managed, and regulated by the public for the general good.
                  </li>
                  <li>
                    We strenuously condemn harassment, threats, and violence
                    against elected and appointed public officials at all
                    levels. We believe intimidation undermines and destabilizes
                    the democratic process. We call for constructive engagement
                    with public officials, most of whom are volunteers who
                    generously donate their time for the betterment of our
                    communities.
                  </li>
                  <li>
                    We believe elected officials hold positions integral to a
                    functioning democracy, and it is their duty to execute and
                    honor the trust of their constituents by doing their jobs.
                    We&nbsp;rebuke&nbsp;elected officials who denigrate the
                    public or encourage corruption, voter nullification,
                    suppression, and terroristic threats to our democracy.
                  </li>
                  <li>
                    We believe democracy comes with responsibilities and all
                    political parties, including the Democratic Party, must meet
                    the same ethical standards we require of government.
                  </li>
                  <li>
                    We condemn behavior by any public official, regardless of
                    party, who uses their office for private gain, who overlooks
                    others&rsquo; misconduct, or who uses the power of their
                    position to coerce or intimidate.&nbsp;
                  </li>
                  <li>
                    We believe voting requires an informed electorate;
                    therefore, all contested elections, including local races,
                    must require that the county produce a voter&apos;s pamphlet
                    and, at minimum, provide it to the public via the
                    county&apos;s website.
                  </li>
                  <li>
                    We believe in paying state legislators and statewide office
                    holders a salary sufficient to maintain their ability to
                    continue in government service, without economic stress, in
                    order to reduce conflicts of interest and promote a more
                    economically diverse and democratic government.
                  </li>
                </ol>
                <hr />
                <h1>
                  <br />
                </h1>
                <h1>Article 9: Science and Technology</h1>
                <p>
                  Oregon Democrats believe evidence-based science should form
                  the basis for many of the decisions the government makes,
                  particularly regarding health and the environment. Basic
                  research in science and technology are cornerstones for the
                  future. We welcome innovation in all scientific arenas;
                  however, the scientific community has the responsibility to
                  protect the public from harm caused by incomplete
                  understanding or misapplication of new technologies. We
                  embrace the new frontiers of discovery and development in
                  science, medicine, information, artificial intelligence,
                  robotics, energy, transportation, space sciences, ocean
                  sciences, and new fields as they emerge. We recognize that our
                  global integration requires attention to the broad societal
                  impacts of these developments.&nbsp;
                </p>
                <h3>Planks:</h3>
                <ol start="1">
                  <li>
                    We believe an open and democratic internet is critical to
                    technological progress. We support net neutrality and strong
                    privacy protections, particularly for medical and financial
                    information, for all data sharing applications. We support
                    robust data encryption protection uninhibited by back doors
                    or key escrow.&nbsp;
                  </li>
                  <li>
                    We support medical research for the purpose of eliminating
                    disease, preventing pandemics, aiding recovery, and
                    improving quality of life. Researchers must take care to
                    address racial, gender and cultural bias in research, in
                    order to improve patient outcomes.
                  </li>
                  <li>
                    We support state and federal funding of basic and applied
                    research at academic institutions and public-private
                    partnerships, with provisions for transparency,
                    accountability, and equitable protection of public
                    interests. We believe all publicly funded research should be
                    openly shared.&nbsp;
                  </li>
                  <li>
                    We believe the United States should actively participate in
                    space exploration. Our space program should cooperate with
                    all humanity, for the betterment of humankind. NASA should
                    be adequately funded for this purpose. We oppose
                    privatization and outsourcing of space exploration. We
                    believe that space belongs to humanity, not
                    corporations.&nbsp;
                  </li>
                  <li>
                    We oppose whole human cloning until ethical and legal
                    questions are resolved. We support curing diseases via in
                    vivo genetic editing and the cloning of individual organs.
                  </li>
                  <li>
                    We believe in advancing transportation and energy
                    technologies that do not produce greenhouse gasses.&nbsp;
                  </li>
                  <li>
                    We support more deployment of advanced sensing technologies
                    to predict weather, earthquakes, and tsunamis, and help
                    protect lives and property from their destructive effects.
                    We support continued improvement in climate modeling.&nbsp;
                  </li>
                  <li>
                    We support right-to-repair policies, especially relating to
                    electronics and farming equipment. The lack of these
                    policies disproportionately impacts rural communities,
                    low-income people, and Black, Indigenous, and other people
                    of color.
                  </li>
                  <li>
                    We believe Oregon needs to create additional,
                    state-sponsored research facilities outside the university
                    system so that scientists trained in Oregon can work in
                    Oregon and to promote the development of capacity in the
                    Oregon economy.
                  </li>
                  <li>
                    We believe that free and open source software (FOSS), as
                    defined by the Open Source Initiative, is critical,
                    cost-effective public infrastructure. We support reliable
                    public and private funding of FOSS to deliver services and
                    create opportunities for individuals and for organizations
                    of all sizes.
                  </li>
                  <li>
                    We believe artificial intelligence research and development
                    must be conducted within strict ethical and moral
                    regulations. We believe developers must respect intellectual
                    property rights, enhance, not replace the value of human
                    labor, avoid invasions of privacy, improve the environment
                    by minimizing carbon impact, and prevent racial, gender or
                    cultural bias.
                  </li>
                  <li>
                    We resolve to ensure that the exploration of our
                    planet&rsquo;s oceans have value to society, mankind, and
                    the sustainability of our planet. We believe ocean
                    exploration must not harm wildlife or human life, and the
                    safety of explorers must be of the utmost importance in all
                    stages of exploration.
                  </li>
                  <li>
                    We believe society and culture has a vested interest in
                    governments regulating the market of cryptocurrencies and
                    non-fungible tokens (NFTs), to address environmental impact,
                    societal harm, intellectual property rights, criminal use,
                    and privacy concerns. Regulations, similar to other
                    investment instruments and equities should include algorithm
                    transparency.
                  </li>
                  <li>
                    We believe cybersecurity laws should include technical
                    standards to safeguard all reproductive healthcare data,
                    including through end to end encryption in transit and at
                    rest.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Platform;
