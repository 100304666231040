import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/blogstandard/Content";
import { useLocation } from "react-router-dom";

function Blogstandard() {
  const location = useLocation();

  const pageTitle = "Blog Posts and News | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="The Democratic Party of Clackamas County blog posts and news. Learn more about how we’re working hard for change in your neighborhood."
        />
      </MetaTags>
      <Header />
      {/* <Breadcrumbs breadcrumb={{ pagename: 'Blog Standard' }} /> */}
      <Content />
      {/* <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap">
                            <Client />
                        </div>
                    </div>
                </section> */}
      <Footer />
    </Fragment>
  );
}

export default Blogstandard;
