import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import demBanner from "../../../../assets/img/banner/DPCCBanner.webp";

const bannerpost = [
  {
    img: demBanner,
    tag: "WORKING TOGETHER TO DO BETTER",
    title: "CLACKAMAS \n DEMOCRATS ",
    btntext: "Donate",
    btntext1: "Contact Us",
  },
  // { img: img2, tag: 'WORKING TOGETHER TO DO BETTER', title: "CLACKAMAS \n DEMOCRATS ", btntext: 'Get Started Now', btntext1: 'Our Services' },
  // { img: img3, tag: 'WORKING TOGETHER TO DO BETTER', title: "CLACKAMAS \n DEMOCRATS ", btntext: 'Get Started Now', btntext1: 'Our Services' },
];
const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

function Banner(props) {
  return (
    <section className="breadcrumb-section">
      <div className="container">
        <div className="breadcrumb-text">
          <h1 className="page-title">Event Calendar</h1>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Event Calendar</li>
          </ul>
        </div>
      </div>
      <div className="breadcrumb-shapes">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
    </section>
  );
}

export default Banner;
