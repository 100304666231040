import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";

const ContactsAndOrganization = () => {
  const location = useLocation();

  const pageTitle = "Contact Us | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Thanks for your interest! Get Democratic Party of Clackamas County contact information and then get in touch to learn more about us."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Contacts and Organization" }} />
      <section className="client-section">
        <div className="container">
          <div className="client-slider mt-4 mb-4">
            <ContactInfo />
          </div>
          <div className="client-slider mb-4">
            <ContactLinks />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ContactsAndOrganization;

function ContactLinks() {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-building" /> Organizational Leaders & Contact
            links
          </a>
        </h5>
        <p className="card-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
          >
            CLICK HERE TO CONTACT US!
          </a>
          <br />
          We love hearing from you! Using our online{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact form
          </a>{" "}
          you can:
        </p>
        <ul className="p-5">
          <li>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform?usp=sf_link">
              Provide feedback and/or find out how to Donate
            </a>
          </li>
          <li>
            Subscribe to{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our Newsletters
            </a>
          </li>
          <li>
            Join the{" "}
            <Link to="/neighborhood-leaders-program">
              Neighborhood Leader Program
            </Link>{" "}
          </li>
          <li>
            Join the <Link to="/pcp">Precinct Committee Persons</Link> programs
          </li>
          <li>
            Use this direct link to{" "}
            <a
              href="https://secure.actblue.com/donate/yeswecan-fund"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate money
            </a>
            ,{" "}
          </li>
          <li>
            <a href="https://www.clackamasdems.org/#/house-district-leaders">
              Become a House District Leader
            </a>
          </li>
          <li>
            <a href="https://www.clackamasdems.org/#/standing-committee-and-caucuses">
              Join a committee or caucus
            </a>
          </li>
        </ul>
        <p>Find the contact you'd like on one of the following pages:</p>
        <ul className="p-5">
          <li>
            <Link to="/officers">Officers</Link>
          </li>
          <li>
            <Link to="/house-district-leaders">House District Leaders</Link>
          </li>
          <li>
            <Link to="/standing-committee-and-caucuses">
              Standing Committee and Caucus Chairs
            </Link>
          </li>
          <li>
            <Link to="/delegates">Delegates</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export const ContactInfo = () => (
  <div className="card">
    <div className="card-body">
      <h5 className="card-title">Contact Information</h5>
      <ul className="list-unstyled">
        <li>
          <i className="fa fa-phone" /> (503) 656-1931
        </li>
        <li>
          <i className="fa fa-envelope" /> outreach@clackamasdems.org
        </li>
        <li>
          <i className="fa fa-map-marker" /> 609 Main Street Oregon City, OR
          97045
        </li>
        <li>
          <i className="fa fa-map-marker" /> Mailing address: P.O. Box 680,
          Gladstone, OR 97027
        </li>
      </ul>
    </div>
  </div>
);
